import React, { useState, useEffect, useContext } from 'react';
import SheetContext from './SheetContext';
import { valutaAPIget } from '../FetchApi';
import Loading from './Loading';
import { useParams } from "react-router-dom";
import Sheet from './Sheet';
import { toCamelCase } from '../Tools';

const EvaluationEdit = (props) => {

    const { id } = useParams();
    const [sheetState, setSheetState] = useContext(SheetContext);
    //const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carico la valutazione...")

    useEffect(() => {

        //setIsLoading(true);
        (async function doAsync() {
            await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_EVALUATION, { evaluationId: id }).then(res => {
                if (res !== null && res !== undefined && res.IdEvaluation !== null && res.IdSheet !== undefined && !isNaN(res.IdSheet)) {
                    var newState = { ...sheetState };
                    newState.sheetPlayerRangeColor = { backgroundColor: null };
                    //console.log(res); //evaluation
                    //setIsLoading(false);
                    //Ciclo sulle proprietà dell'oggetto
                    for (var prop in res) {
                        if (res.hasOwnProperty(prop)) {
                            // do stuff
                            var fieldName = prop;
                            var fieldValue = res[prop];
                            for (var prop2 in fieldValue) {
                                if (fieldValue.hasOwnProperty(prop2)) {
                                    if (prop2 === "Value")
                                        fieldValue = { value: fieldValue.Value, label: fieldValue.Label }
                                }
                            }
                            if (prop === "SheetTotalRatePercent" || prop === "SheetTotalRate" || prop === "SheetMaxRate" || prop === "SheetPlayerRange" || prop === "SheetPlayerRangeDescription" || prop === "SheetSubjectsAndQuestions") {
                                //console.log("IMPOSTO " + toCamelCase(fieldName))
                                newState[toCamelCase(fieldName)] = fieldValue;
                            }
                            else {
                                newState[fieldName] = fieldValue;
                            }
                        }
                    }
                    //console.log("STATE IMPOSTATO DA EDIT")
                    //console.log(newState)
                    setSheetState(newState);
                }
                else {
                    var exception = (res.Error !== undefined) ? "\n\r" + res.Error : "";
                    if (exception !== "")
                        setLoadingMessage(exception);
                    else
                        setLoadingMessage("Si sono verificati errori nel caricamento, prova a ricaricare la pagina o contatta il supporto.");
                }
            })
        })()
    }, []);

    return (sheetState.IdSheet === undefined || sheetState.IdSheet === null || isNaN(sheetState.IdSheet)) ?
        <Loading message={loadingMessage} />
        :
        <React.Fragment>
            <Sheet id={sheetState.IdSheet} readOnly={props.readOnly} isEditing={true} />
        </React.Fragment>

}
export default EvaluationEdit;