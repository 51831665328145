import React from 'react';
import ReactDOM from 'react-dom';
import { getUserImage } from '../Tools'
import DEFAULTPIC from '../img/pic.png';

const AsyncImage = (props) => {
    const [url, setUrl] = React.useState(DEFAULTPIC);
    React.useEffect(async () => {
        var lUrl = await getUserImage(props.data);
        if(!lUrl) {
            lUrl = DEFAULTPIC;
        }
        setUrl(lUrl);
  }, [props.fn, props.data]);
      return (
          <img src={url} className={props.className} alt={props.alt} />
      );
};

export default AsyncImage;