import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { valutaAPIget, valutaAPIpost } from '../../../FetchApi';
import { useDispatch } from 'react-redux';
import { updateFlashMessage } from '../../../store/store';
import { compareSubsectionsOrder, compareRangeOrderByLabel, compareFieldsOrderByFieldName } from '../../../helpers/helpers'


const CreateSheetContext = React.createContext();

const CreateSheetProvider = ({children, sheetToClone}) => {

    const dispatch = useDispatch();

    const [sheetName, setSheetName] = useState('')
    const [sheetType, setSheetType] = useState('classic')
    const [sheetWeightType, setSheetWeightType] = useState('pesoSezione')
    const [sheetService, setSheetService] = useState(JSON.stringify({}))
    const [sheetMaxValue, setSheetMaxValue] = useState(5)
    const [subjects, setSubjects] = useState([])
    const [header, setHeader] = useState([])
    const [footer, setFooter] = useState([])
    const [headerFields, setHeaderFields] = useState([])
    const [services, setServices] = useState({})
    const [updateSheet, setUpdateSheet] = useState('no_update')
    const [isLoading, setIsLoading] = useState(false)
    const [footerFields, setFooterFields] = useState([])
    const [premadeSubjects, setPremadeSubjects] = useState([])
    const [totalWeight, setTotalWeigth] = useState(0)
    const [availableWeight, setAvailableWeight] = useState(100)
    const [playerEvaluationLabels, setPlayerEvaluationLabels] = useState(["C", "B", "A"])
    const [playerEvaluationValues, setPlayerEvaluationValues] = useState([33, 66])
    const [playerEvaluationColors, setPlayerEvaluationColors] = useState(['#dc3545', '#ffc107', '#28a745', '#007bff', '#FF6633', '#FFB399', '#FF33FF', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3'])
    const [playerRangeDescriptions, setPlayerRangeDescriptions] = useState({ "A": "Adeguato", "B": "Parzialmente adeguato", "C": "Non adeguato"})
    const [PlayerRangeColors, setPlayerRangeColors] = useState({ "A": "61d875",  "B": "f8c846", "C": "e85454" })
    const [retry, setRetry] = useState(null)
    const [retryCount, setRetryCount] = useState(1)
    const [idParent, setIdParent] = useState(0)
    const [subsectionCounter, setSubsectionCounter] = useState(0)
    const [sheetStati, setSheetStati] = useState(false)
    /**
   * Flash Messages Helpers
   */

  const clearFlashMessage = () => {
    updateFlashMessage(dispatch,{})
  }

  const displayFlashMessageNetworkError = (duration, retryDelay) => {
    updateFlashMessage(dispatch,{
      title : "Errore di rete!",
      content: <p style={{color:'white'}}>Impossibile comunicare con il BackEnd. Nuovo tentativo di connessione tra {retryDelay/1000} sec<br></br>Una segnalazione è stata inviata al reparto IT Support</p>,
      flashType: 'danger',
      flashDuration: 10000
    })
    if(duration > 0){
      setTimeout(() => {
        clearFlashMessage()
      }, duration);
    }
  }

  const displayFlashMessageNetworkRestored = (duration) => {
    updateFlashMessage(dispatch,{
      title : "Connessione Ripristinata!",
      content: <p style={{color:'white'}}>Grazie per l'attesa!</p>,
      flashType: 'success',
      flashDuration: 10000
    })
    if(duration > 0){
      setTimeout(() => {
        clearFlashMessage()
      }, duration);
    }
  }

  const retryCall = (retryDelay) => {
    // console.log(`Retry in ${retryDelay/1000} seconds`);
    setTimeout(() => {
      setRetry(Date.now())
    }, retryDelay);
  }

/*
  const defaultPlayerRangeDescriptions = {
      "A" : "Adeguato",
      "B" : "Parzialmente adeguato",
      "C" : "Non adeguato"
  }

  const defaultPlayerRangeColors = {
    "A" : "61d875",
    "B" : "f8c846",
    "C" : "e85454"
  } */

    /**
     * 
     * Fetch DropDowns 
     */

    useEffect(() => {
        
        setIsLoading(true);
        (async function doAsync() {
            
            let availableDropDowns = await valutaAPIget(process.env.REACT_APP_VALUTA_API_GETDROPDOWN_LIST, { active: true });
            if(availableDropDowns.length === 0) {
              const retryDelay = 1000*(retryCount**3)
              displayFlashMessageNetworkError(0, retryDelay)
            //   console.log(retryCount);
              retryCall(retryDelay)
              setRetryCount(retryCount+1)
              return
            }else{
              if(retryCount > 1){
                setRetry(1)
                displayFlashMessageNetworkRestored(3000)
              }
            }
            let availableSubjects = await valutaAPIget(process.env.REACT_APP_VALUTA_API_SUBJECT_LIST, {});
            const availableServices = await valutaAPIget(process.env.REACT_APP_VALUTA_API_SERVICE_LIST, { active: true });
            availableDropDowns = availableDropDowns.map(_singleDropDown => {
                _singleDropDown.isMandatory = _singleDropDown.onAllSheets
                return _singleDropDown
            })
            /**
             * Ordine forzato nei dropdown disponibili con 1-Data Consulenza e 2-Consulente
             */
            const tmpDropDowns = availableDropDowns.filter(_singleDropdown=>_singleDropdown.fieldName === "DataConsulenza" || _singleDropdown.fieldName === "Consulente");

            tmpDropDowns.push(...availableDropDowns.filter(_singleDropdown=>_singleDropdown.fieldName !== "DataConsulenza" && _singleDropdown.fieldName !== "Consulente"))

            availableDropDowns = tmpDropDowns
            


            availableSubjects = availableSubjects.map(_singleSubject => {
                return {
                    value: _singleSubject.idSubject,
                    label: _singleSubject.subjectName
                }
            })
            setPremadeSubjects(availableSubjects)
            setHeader(availableDropDowns.filter(_singleAvailableDropDown => _singleAvailableDropDown.onAllSheets === true))
            setHeaderFields(availableDropDowns.sort(compareFieldsOrderByFieldName))
            setFooterFields(availableDropDowns.sort(compareFieldsOrderByFieldName))
            setServices(availableServices)

            /**
             * After Preload get cloner data
             */

            // console.log(availableServices);
            // console.log(sheetToClone);
            const clonedSheet = await sheetToClone
            if (clonedSheet.idSheet) {
                // console.log("Cloning freeze mode");

                
                if (clonedSheet) {
                    console.log("Asked to clone sheet "+clonedSheet.idSheet);
                    console.log(clonedSheet);
                    
                    /**
                     * General Info
                     */
                    changeSheetName(clonedSheet.sheetName)
                    changeSheetMaxValue(clonedSheet.maxValue)
                    changeSheetType(clonedSheet.sheetType)
                    changeSheetWeightType(clonedSheet.sheetWeightType)
                    availableServices.forEach(_singleAvailableService => {
                        if(_singleAvailableService.idService === clonedSheet.idService) {
                            changeSheetService(_singleAvailableService)
                        }
                    })
                    /**
                     * Player Ranges
                     */
                    const playerRangesLabels = []
                    const playerRangesValues = []
                    const playerRangesColors = []
                    clonedSheet.playerRanges.sort(compareRangeOrderByLabel).reverse().forEach(_singlePlayerRange=>{
                        playerRangesLabels.push(_singlePlayerRange.rangeLabel)
                        /**
                        * ?FIX valore range incrementato nella scheda clone: 
                        * Per visualizzare correttamente lo slider, il valore mostrato del rangeMin è l'upper bound del range precedente.
                        * Quindi al packing e conseguentemente al salvataggio a db verrà incrementato di 1 per riflettere il lowerbound del range corrente e non più l'upper bound del precedente.
                        * In caso di rangeMin === 0 viene riportato correttamente poichè è il primo range possibile e non esiste un upper bound precedente.
                        * Nella clonazione si deve quindi sottrarre 1 per riflettere nuovamente l'upper bound del range precedente (solo se rangeMin !== 0). 
                        */
                        playerRangesColors.push("#" + _singlePlayerRange.rangeColor)
                        playerRangesValues.push(_singlePlayerRange.rangeMinValue===0?_singlePlayerRange.rangeMinValue:_singlePlayerRange.rangeMinValue-1)
                    })
                    setPlayerEvaluationLabels(playerRangesLabels)
                    setPlayerEvaluationColors(playerRangesColors)
                    setPlayerEvaluationValues(playerRangesValues.slice(1, playerRangesValues.length))
                    if (playerRangesColors.length > 3) {
                        setSheetStati(true)
                    }

                    /**
                     * Fields
                     */
                    setHeader([])
                    setFooter([])
                    const clonedHeader = []
                    const clonedFooter = []
                    // console.log(clonedSheet.fields);
                    //?FIX Custom header e footer values
                    clonedSheet.fields.forEach(_singleField => {
                        const tmpField = {
                            idField : _singleField.idField,
                            fieldName : _singleField.name,
                            fieldLabel : _singleField.label,
                            fieldType : _singleField.fieldType,
                            isMandatory : _singleField.isMandatory,
                            onAllSheets : _singleField.onAllSheets,
                            dropDownValue : _singleField.values.map(_singleDropDown=>{
                                return {
                                    idField : _singleField.idField,
                                    dropDownVal: _singleDropDown.value,
                                    dropDownLabel: _singleDropDown.label
                                }
                            }),
                            fieldCustomValues: _singleField.values,
                            edited: _singleField.values.length > 0
                            
                        }
                        switch (_singleField.position) {
                            case 'header':
                                clonedHeader.push(tmpField)
                                break;
                            case 'footer':
                                clonedFooter.push(tmpField)
                                break
                            default:
                                console.log("Position non riconosciuta");
                                break;
                        }
                    })
                    // console.log(clonedFooter);
                    setHeader(clonedHeader)
                    setFooter(clonedFooter)
                    console.log("Headers Cloned:");
                    console.log(clonedHeader);
                }
                
                /**
                 * Subjects
                 */
                let subjectTotalWeight = 0;
                const clonedSubjects = []

                clonedSheet.subjects.forEach(_singleSubject => {

                    if(_singleSubject.idParentSubject){
                        // console.log("Is Subsection!");
                        const tmpSubsection = {
                            id : _singleSubject.idSheetSubject,
                            name : {label:_singleSubject.subject, value:_singleSubject.subject},
                            weight : 0,
                            subjectId : _singleSubject.idParentSubject,
                            questions : _singleSubject.questions.map(_singleQuestion=>{
                                return {
                                    id : _singleQuestion.idSubjectQuestion,
                                    isMandatory : _singleQuestion.isMandatory,
                                    subjectId : _singleSubject.idSheetSubject,
                                    name : _singleQuestion.question
                                }
                            }),
                            premadeId : _singleSubject.idSubject,

                        }

                        clonedSubjects.map(_singleSub => {
                            if(_singleSub.id === _singleSubject.idParentSubject) {
                                _singleSub.subsections.push(tmpSubsection)
                            }
                            return _singleSub
                        })

                    }else {
                        if (clonedSheet.sheetWeightType === 'pesoSezione') {
                            const tmpSubject = {
                                id : _singleSubject.idSheetSubject,
                                name : _singleSubject.subject,
                                weight : _singleSubject.weight,
                                questions : _singleSubject.questions.map(_singleQuestion=>{
                                    return {
                                        id : _singleQuestion.idSubjectQuestion,
                                        isMandatory : _singleQuestion.isMandatory,
                                        subjectId : _singleSubject.idSheetSubject,
                                        name : _singleQuestion.question
                                    }
                                }),
                                premadeId : _singleSubject.idSubject,
                                subsections : []
                            }
                            clonedSubjects.push(tmpSubject)

                        }else if (clonedSheet.sheetWeightType === 'pesoItem') {
                            const tmpSubject = {
                                id : _singleSubject.idSheetSubject,
                                name : _singleSubject.subject,
                                weight : _singleSubject.weight,
                                questions : [],
                                premadeId : _singleSubject.idSubject,
                                subsections : []
                            }
                            clonedSubjects.push(tmpSubject)

                        }
                        
    
                        // console.log("Weight to add: "+_singleSubject.weight);
                        subjectTotalWeight += _singleSubject.weight
                    }
                    
                })
                // console.log(clonedSubjects);
                setSubjects(clonedSubjects)
                setTotalWeigth(subjectTotalWeight)
                // console.log(subjectTotalWeight);

                /**
                 * IdParent (sorgente clonazione scheda)
                 */

                 setIdParent(clonedSheet.idSheet)
            }

            setIsLoading(false);
        })()

    }, [retry]);

    const addQuestion = (newQuestion) => {
        // console.log('Asked to Add Question');
        const newSubjects = subjects.map((_singleSubject) => {
            if (_singleSubject.id === newQuestion.subjectId) {
                _singleSubject.questions = [..._singleSubject.questions, newQuestion]
            }
            return _singleSubject
        })
        setSubjects(newSubjects)
    }

    const addQuestionToSubsection = (newSubsectionQuestion,subsectionId, subjectId) => {
        // console.log('Asked to Add Question to Subsection');
        const newSubjects = subjects.map((_singleSubject) => {
            if (_singleSubject.id === subjectId) {
                _singleSubject.subsections.map(_singleSubsection=>{
                    if(_singleSubsection.id === subsectionId) {
                        _singleSubsection.questions = [..._singleSubsection.questions, newSubsectionQuestion]
                    }
                    return _singleSubsection
                })
            }
            return _singleSubject
        })
        setSubjects(newSubjects)

    }

    const addSubsection = (newSubsection) => {
        // console.log('Asked to Add Subsection');
        const newSubjects = subjects.map((_singleSubject) => {
            if (_singleSubject.id === newSubsection.subjectId) {
                _singleSubject.subsections = [..._singleSubject.subsections, newSubsection]
                setSubsectionCounter(subsectionCounter+1)
            }
            return _singleSubject
        })
        
        setSubjects(newSubjects)
    }

    const editSubsection = (subjectId, subsectionId, newSubsectionName) => {
        setSubjects(subjects.map(_singleSubject=>{
            if(_singleSubject.id === subjectId){
                _singleSubject.subsections.map(_singleSubsection=>{
                    if(_singleSubsection.id === subsectionId){
                        _singleSubsection.name = newSubsectionName
                    }
                    return _singleSubsection
                })
            }
            return _singleSubject
        }))
    }

    const deleteSubsection = (subsectionId,subjectId) => {
        // console.log('Asked to Delete Subsection');
        // console.log(subsectionId);
        // console.log(subjectId);
        setSubjects(subjects.map(_singleSubject=>{
            if(_singleSubject.id === subjectId){
                // console.log("Found subject "+subjectId);
                _singleSubject.subsections = _singleSubject.subsections.filter(_singleSubsection=>_singleSubsection.id!==subsectionId)
                setSubsectionCounter(subsectionCounter-1)
            }
            return _singleSubject
        }))
    }

    const editSubsectionQuestion = (subjectId, subsectionId, questionId, newQuestionName, isMandatory) => {
        // console.log('Asked to Edit Subsection Question');
        // console.log(subsectionId);
        // console.log(subjectId);
        // console.log(questionId);
        // console.log(newQuestionName);
        // console.log(isMandatory);

        setSubjects(subjects.map(_singleSubject=>{
            if(_singleSubject.id === subjectId){
                _singleSubject.subsections.map(_singleSubsection=>{
                    if(_singleSubsection.id === subsectionId){
                        _singleSubsection.questions.map(_singleQuestion=>{
                            if(_singleQuestion.id===questionId){
                                _singleQuestion.name = newQuestionName
                                _singleQuestion.isMandatory = isMandatory
                            }
                            return _singleQuestion
                        })
                    }
                    return _singleSubsection
                })
            }
            return _singleSubject
        }))
    }

    const deleteSubsectionQuestion = (subsectionId, subjectId, questionId) => {
        // console.log('Asked to Delete Subsection Question');
        // console.log(subsectionId);
        // console.log(subjectId);
        // console.log(questionId);
        setSubjects(subjects.map(_singleSubject=>{
            if(_singleSubject.id === subjectId){
                // console.log("Found subject "+subjectId);
                _singleSubject.subsections.map(_singleSubsection=>{
                    if(_singleSubsection.id === subsectionId){
                        _singleSubsection.questions = _singleSubsection.questions.filter(_singleQuestion=>_singleQuestion.id!==questionId)
                    }
                    return _singleSubsection
                })
            }
            return _singleSubject
        }))
    }

    const deleteQuestion = (questionId, subjectId) => {
        // console.log('Asked to Delete Question');
        const newSubjects = subjects.map((_singleSubject) => {
            // console.log(_singleSubject);
            // console.log(subjectId);
            if (_singleSubject.id === subjectId) {
                // console.log("Question found");
                _singleSubject.questions = _singleSubject.questions.filter((_singleQuestion) => _singleQuestion.id !== questionId)
            }
            return _singleSubject
        })
        setSubjects(newSubjects)
    }
    const editQuestion = (questionId, subjectId, newName, isMandatory) => {
        // console.log('Asked to Edit Question');
        // console.log(`New values\nName: ${newName}\n${isMandatory}`)
        const newSubjects = subjects.map((_singleSubject) => {
            if (_singleSubject.id === subjectId) {
                _singleSubject.questions = _singleSubject.questions.map((_singleQuestion) => {
                    if (_singleQuestion.id === questionId) {
                        _singleQuestion.name = newName;
                        _singleQuestion.isMandatory = isMandatory;
                    }
                    // console.log(_singleQuestion);
                    return _singleQuestion
                })
            }
            return _singleSubject
        })
        setSubjects(newSubjects)
    }
    const addSubject = (newSubject) => {
        // console.log('Asked to Add Subject');
        setTotalWeigth(totalWeight + newSubject.weight)
        setAvailableWeight(availableWeight - newSubject.weight)
        setSubjects([...subjects, newSubject])
    }
    const deleteSubject = (subjectIdToDelete) => {
        // console.log('Asked to Delete Subject');
        setTotalWeigth(totalWeight - subjects.filter(_singleSubject => _singleSubject.id === subjectIdToDelete)[0].weight)
        setAvailableWeight(availableWeight + subjects.filter(_singleSubject => _singleSubject.id === subjectIdToDelete)[0].weight)
        setSubjects(subjects.filter(_singleSubject => _singleSubject.id !== subjectIdToDelete))
    }
    const editSubject = (subjectId, editedSubject) => {
        console.log(editedSubject);
        // console.log('Asked to Edit Subject');
        // console.log(`SubjectId: ${subjectId}`);
        // console.log(editedSubject);
        const newSubjects = subjects.map((_singleSubject) => {
            if (_singleSubject.id === subjectId) {
                console.log("Subject/Item Found");
                setTotalWeigth(totalWeight - _singleSubject.weight + editedSubject.weight)
                setAvailableWeight(availableWeight + _singleSubject.weight - editedSubject.weight)
                _singleSubject.name = editedSubject.label || editedSubject.name
                _singleSubject.premadeId = !editedSubject.__isNew__ ? editedSubject.value : null
                _singleSubject.weight = editedSubject.weight
                _singleSubject.isMandatory = editedSubject.isMandatory
                console.log("Subject/Item Replaced");
                
            }
            return _singleSubject
        })
        setSubjects(newSubjects)
    }
    const changeSheetName = (newName) => { setSheetName(newName) }
    const changeSheetType = (newType) => { setSheetType(newType) }
    const changeSheetWeightType = (newWeightType) => { setSheetWeightType(newWeightType) }
    const changeSheetService = (newService) => { setSheetService(newService) }
    const changeSheetMaxValue = (newMaxValue) => { setSheetMaxValue(newMaxValue) }

    const saveHeader = (_newHeaderFields) => {
        setHeader([..._newHeaderFields])
    }
    const saveFooter = (_newFooterFields) => {
        setFooter([..._newFooterFields])
    }

    const abortSheet = () => {
        setSubjects([])
        setSheetName('')
        setSheetService(JSON.stringify({}))
        setFooter(footer.filter(_singleFooter => _singleFooter.onAllSheets === true))
        setHeader(header.filter(_singleHeader => _singleHeader.onAllSheets === true))
        setUpdateSheet(`reset_${new Date().getTime()}`)
        setPlayerEvaluationLabels(["C", "B", "A"])
        setPlayerEvaluationValues([33, 66])
        setPlayerEvaluationColors(["#dc3545", "#ffc107", "#28a745"])
        setPlayerRangeDescriptions({ "A": "Adeguato", "B": "Parzialmente adeguato", "C": "Non adeguato" })
        setSheetStati(false)
    }
    const postNewSheet = async (packedSheet) => {
        const postedSheet = await valutaAPIpost(process.env.REACT_APP_VALUTA_API_POST_SHEET, packedSheet);
        return postedSheet
    }

    const doCloneSheet = async (sheetId) => {
        // const loadedSheet = await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEET,{sheetId});
        // if (loadedSheet.Error) {
        //     console.log("Errore caricamento scheda da clonare");
        // }else{
        //     console.log("Scheda da clonare caricata");
        //     setSheetName(loadedSheet.sheetName)
        //     setSheetMaxValue(loadedSheet.maxValue)

            
        // }
        // return loadedSheet
    }

    const saveSheet = async () => {
        if (totalWeight !== 100) {
            updateFlashMessage(dispatch, {
                title: "Attenzione!",
                content: `Il peso totale delle sezioni deve essere 100%, peso attuale: ${totalWeight}%`,
                flashType: 'danger',
                flashDuration: 10000
            })
            return
        }

        const packedSheet = packSheet();

        console.log("Packed Sheet:");
        console.log(packedSheet);
       
        if (packedSheet.Errors.length > 0 && packedSheet.Errors.filter(_singleError => _singleError.severity === 'critico').length > 0) {
            // console.log("At least 1 critical");
            updateFlashMessage(dispatch, {
                title: "Attenzione!",
                content: <ul>{packedSheet.Errors.map(_singleError => <li key={_singleError.id}>Errore {_singleError.severity} - {_singleError.message}</li>)}</ul>,
                flashType: 'danger',
                flashDuration: 10000
            })
            setTimeout(() => {
                clearFlashMessage()
            }, 5000);
            return
        } else {

            updateFlashMessage(dispatch, {
                title: "Attendere.",
                content: "caricamento scheda in corso...",
                flashType: 'info',
                flashDuration: 20000
            })
            const postResult = await postNewSheet(packedSheet);
            

            if(postResult.idSheet){
                updateFlashMessage(dispatch, {
                    title: "Scheda caricata!",
                    content: "Scheda caricata con successo. Reindirizzamento alla pagina delle schede...",
                    flashType: 'success',
                    flashDuration: 20000
                })
                window.sessionStorage.removeItem("valuta.allPlayerRanges");
                setTimeout(() => {
                    clearFlashMessage()
                }, 5000);
                return postResult;
            }else{

                updateFlashMessage(dispatch, {
                    title: "Errore caricamento scheda!",
                    content: "Non è stato possibile caricare la scheda. Per favore contatta il Supporto IT",
                    flashType: 'danger',
                    flashDuration: 20000
                })
                setTimeout(() => {
                    clearFlashMessage()
                }, 5000);
                return null
            }
            
        }
    }

    const packSheet = () => {
        var packetErrors = []

        /**
         * Validations
         */
        if (sheetName.trim() === '') {
            // console.log("Error: empty sheetName");
            packetErrors.push({ message: "Il nome della scheda non può essere vuoto", severity: "critico", id: uuidv4() })
        }
        if (!sheetService || !sheetService.idService) {
            // console.log("Error: empty idService");

            packetErrors.push({ message: "Devi scegliere un servizio per la scheda", severity: "critico", id: uuidv4() })
        }
        if (sheetMaxValue < 1) {
            // console.log("Error: illegal maxValue");

            packetErrors.push({ message: "Il punteggio massimo delle domande non può essere inferiore a 1", severity: "critico", id: uuidv4() })
        }
        if (subjects.length < 1) {
            // console.log("Error: no Subjects");

            packetErrors.push({ message: "Non hai inserito alcuna sezione", severity: "medio", id: uuidv4() })
        }
        if (subjects.length > 0 && subjects.filter(_singleSubject => _singleSubject.questions.length > 0).length < subjects.length) {
            // console.log("Error: no Questions");

            packetErrors.push({ message: "Hai lasciato almeno una sezione senza alcuna domanda", severity: "medio", id: uuidv4() })
        }
        // console.log(packetErrors);
        var fieldsOrdering = 0
        const sheetFields = []

        header.map(_singleHeaderField => {
            sheetFields.push({
                IdField: _singleHeaderField.idField,
                Name: _singleHeaderField.fieldName,
                FieldType: _singleHeaderField.fieldType,
                Label: _singleHeaderField.fieldCustomLabel,
                Values: _singleHeaderField.edited ? _singleHeaderField.fieldCustomValues : [],
                IsMandatory: _singleHeaderField.isMandatory,
                Ordering: fieldsOrdering,
                Position: 'header'
            })
            fieldsOrdering += 10;
            return null

        })

        footer.map(_singleFooterField => {
            // console.log(_singleFooterField);
            sheetFields.push({
                IdField: _singleFooterField.idField,
                Name: _singleFooterField.fieldName,
                FieldType: _singleFooterField.fieldType,
                Label: _singleFooterField.fieldCustomLabel,
                Values: _singleFooterField.edited ? _singleFooterField.fieldCustomValues : [],
                IsMandatory: _singleFooterField.isMandatory,
                Ordering: fieldsOrdering,
                Position: 'footer'
            });
            fieldsOrdering += 10;
            return null

        })

        const packedSheetSubjects = []
        let subjectOrdering = 0;
        subjects.forEach(_singleSubject => {
            var questionOrdering = 0;
            // console.log(_singleSubject);
            if (sheetType !== 'yes-no') {
                if(sheetWeightType === "pesoItem"){
                    packedSheetSubjects.push({
                        IdSubject: _singleSubject.premadeId ? _singleSubject.premadeId : 0,
                        Weight: _singleSubject.weight,
                        Ordering: subjectOrdering,
                        Subject: _singleSubject.name,
                        Questions: [
                            {
                                Question: _singleSubject.name,
                                IsMandatory: _singleSubject.isMandatory,
                                Ordering: 0
                            }
                        ]
                    })
                }else{
                    packedSheetSubjects.push({
                        IdSubject: _singleSubject.premadeId ? _singleSubject.premadeId : 0,
                        IdLocal : _singleSubject.id,
                        Weight: _singleSubject.weight,
                        Ordering: subjectOrdering,
                        Subject: _singleSubject.name,
                        Questions: _singleSubject.questions.map(_singleQuestion => {
                            const questionToPack = {
                                Question: _singleQuestion.name,
                                IsMandatory: _singleQuestion.isMandatory,
                                Ordering: questionOrdering
                            }
                            questionOrdering += 10;
                            return questionToPack
                        })
                    })
                }
                
                subjectOrdering += 10;
                _singleSubject.subsections.sort(compareSubsectionsOrder).forEach(_singleSubsection => {
                    packedSheetSubjects.push({
                        IdSubject: _singleSubsection.premadeId ? _singleSubsection.premadeId : 0,
                        Weight: _singleSubsection.weight,
                        Ordering: subjectOrdering,
                        IdParentSubject: _singleSubsection.subjectId, 
                        Subject: _singleSubsection.name.label,
                        Questions: _singleSubsection.questions.map(_singleQuestion => {
                            const questionToPack = {
                                Question: _singleQuestion.name,
                                IsMandatory: _singleQuestion.isMandatory,
                                Ordering: questionOrdering
                            }
                            questionOrdering += 10;
                            return questionToPack
                        })
                    })
                    subjectOrdering += 10;
                })
            }else {

                packedSheetSubjects.push({
                    IdSubject: _singleSubject.premadeId ? _singleSubject.premadeId : 0,
                    Weight: _singleSubject.weight,
                    Ordering: subjectOrdering,
                    Subject: _singleSubject.name,
                    Questions: [
                        {
                            Question: _singleSubject.name,
                            IsMandatory: true,
                            Ordering: 0
                        }
                    ]
                })
            }
        })

        // console.log(sheetService);
        const packedPlayerRanges = []

        const augmentedRangeValues = playerEvaluationValues.reverse().concat(0).reverse()
        playerEvaluationLabels.forEach((_singleLabel, index) => {
            packedPlayerRanges.push({
                RangeLabel: _singleLabel,
                RangeDescription : playerRangeDescriptions[_singleLabel],
                RangeColor : PlayerRangeColors[_singleLabel],
                RangeMinValue: index !== 0 ? augmentedRangeValues[index] + 1 : augmentedRangeValues[index]
            })
        })
        const packetSheet = {
            SheetName: sheetName,
            IdService: sheetService.idService,
            SheetType: sheetType,
            MaxValue: sheetMaxValue,
            SheetWeightType: sheetWeightType,
            Fields: sheetFields,
            Subjects: packedSheetSubjects,
            PlayerRanges: packedPlayerRanges,
            IdParent : idParent !== 0 ? idParent:null,
            Errors: packetErrors
        }
        // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        // console.dir(packetSheet);
        // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        // return false
        return packetSheet
    }


    const value = {
        sheetName,
        sheetService,
        sheetMaxValue,
        subjects,
        services,
        premadeSubjects,
        headerFields,
        footerFields,
        footer,
        header,
        updateSheet,
        isLoading,
        totalWeight,
        availableWeight,
        playerEvaluationColors,
        playerEvaluationLabels,
        playerEvaluationValues,
        sheetWeightType,
        sheetType,
        setPlayerRangeDescriptions,
        playerRangeDescriptions,
        subsectionCounter,
        changeSheetName,
        changeSheetType,
        changeSheetWeightType,
        changeSheetService,
        changeSheetMaxValue,
        saveHeader,
        saveFooter,
        addQuestion,
        deleteQuestion,
        editQuestion,
        addSubject,
        deleteSubject,
        editSubject,
        addSubsection,
        editSubsection,
        deleteSubsection,
        addQuestionToSubsection,
        editSubsectionQuestion,
        deleteSubsectionQuestion,
        setPlayerEvaluationColors,
        setPlayerEvaluationLabels,
        setPlayerEvaluationValues,
        setPlayerRangeColors,
        saveSheet,
        abortSheet,
        sheetStati,
        setSheetStati,
        doCloneSheet,
        clearFlashMessage
        

    }

    return (
        <CreateSheetContext.Provider value={value}>{children}</CreateSheetContext.Provider>
    );
}


export {
    CreateSheetContext,
    CreateSheetProvider
};