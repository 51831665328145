import React, { useEffect, useState, useContext } from 'react';
import SheetContext from './SheetContext';
import { Link, useHistory } from "react-router-dom";
import { valutaAPIget, valutaAPIpost, valutaAPIputBody, valutaAPIdelete } from '../FetchApi';
import moment from 'moment';
import Loading from './Loading';
import SheetSubjects from './SheetSubjects';
import SheetFiledsHeader from './SheetFiledsHeader';
import SheetFiledsFooter from './SheetFiledsFooter';
import { Modal, Button } from 'react-bootstrap';
import { ExclamationCircleFill, CheckCircle, FileEarmarkArrowDown, Pencil, FileEarmarkX, ArrowLeftCircle } from 'react-bootstrap-icons';
import { checkIfPrivacyIsMandatory, getSheetPlayerRangeColor } from '../Tools';
import '../css/Sheet.css';
import { Fragment } from 'react';
import DEFAULTPIC from '../img/pic.png';
import html2PDF from 'jspdf-html2canvas';
import AsyncImage from './AsyncImage';
const Sheet = (props) => {

    const [sheetState, setSheetState] = useContext(SheetContext);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carico la scheda...")
    const [isSendingEvaluation, setIsSendingEvaluation] = useState(false);
    const [sheet, setSheet] = useState(null);
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalText, setModalText] = useState(null);
    const [errors, setErrors] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isPrivacyMandatory, setIsPrivacyMandatory] = useState(false);
    const history = useHistory();
    const deleteSheet = async (idEvaluation) => {

        const deleteEvaluationResult = await valutaAPIdelete(process.env.REACT_APP_VALUTA_API_DELETE_EVALUATION, idEvaluation)
        if (deleteEvaluationResult.status) {
            //console.log("Valutazione rimossa correttamente!");
            history.push('/')
        } else {
            //console.log("Errore nella rimozione della valutazione!");
        }
    }
    const deleteConfirmation = async () => {
        setModalTitle("Vuoi rimuovere questa valutazione?");
        // console.log(sheetState)
        setModalText(
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h6>#{sheetState.IdEvaluation}</h6>
                <div>
                    <AsyncImage data={sheetState.Consulente.userName} className='previewProfilePic' alt='' />
                </div>
                <h6>{sheetState.Consulente.label}</h6>
                <div>{moment(sheetState.DataConsulenza).format("DD/MM/YYYY")}</div>
                <button className="btn btn-small btn-danger" onClick={() => deleteSheet(sheetState.IdEvaluation)}>Conferma eliminazione</button>
            </div>
        );
        setModalIsOpened(true);
        //if (window.confirm(`Sei sicuro di volere eliminare la valutazione #${idEvaluation} ?`)) deleteSheet(idEvaluation)
    }
    const checkSheet = async () => {
        setIsSendingEvaluation(true);
        setErrors([]);
        setMessages([]);
        setModalTitle(null);
        setModalText(null);

        var checkPassed = true;
        var errs = [];

        // 1 - Verifica compilazione campi obbligatori
        sheet.fields.filter(f => f.isMandatory === true).forEach((field) => {
            if (sheetState[field.name] === undefined || sheetState[field.name] === null || sheetState[field.name] === "") {
                errs.push(`${field.label} obbligatorio.`);
                checkPassed = false;
            }
        });

        // 2 - Verifica che siano compilate tutte le risposte (quelle da escludere vanno compilate con n.d.)
        // 3 - Verifica che le risposte obbligatorie siano compilate con un valore diverso da n.d.
        if (sheet.subjects !== null && sheet.subjects.length > 0) {
            sheet.subjects.forEach((subject) => {
                if (subject.questions !== null && subject.questions.length > 0) {
                    subject.questions.map((question) => {
                        var QuestionState = sheetState.sheetSubjectsAndQuestions.filter(sub => sub.idSheetSubject === subject.idSheetSubject)[0].Questions.filter(qs => (qs?.idSubjectQuestion || qs?.IdSubjectQuestion) === question.idSubjectQuestion)[0];
                        var QuestionRate = QuestionState.QuestionRate;

                        //PesoItem -> SI/NO sempre obbligatorie
                        //PesoItem -> 1/N anche opzionali, con selezione ND
                        //PesoSezione -> SI/NO sempre obbligatorie
                        //PesoSezione -> 1/N anche opzionali, con selezione ND
                        //per le domande di tipo 1/N  "ND" corrisponde a ZERO e significa "non calcolare" mentre nelle SI-NO "ZERO" corrisponde al "NO" ed � un valore valido

                        if (sheet.sheetType === "yes-no") {
                            if (QuestionRate !== 0 && QuestionRate !== 5) {
                                errs.push(`Rispondere alla seguente domanda: ${QuestionState.Question}`);
                                checkPassed = false;
                            }
                        }
                        else {
                            if (question.isMandatory) {
                                if (QuestionRate === null || QuestionRate === "" || QuestionRate === undefined || QuestionRate === 0) {
                                    errs.push(`Rispondere alla seguente domanda: ${QuestionState.Question}`);
                                    checkPassed = false;
                                }
                            }
                            else {
                                if (QuestionRate === null || QuestionRate === "" || QuestionRate === undefined) {
                                    errs.push(`Rispondere alla seguente domanda: ${QuestionState.Question}`);
                                    checkPassed = false;
                                }
                            }
                        }
                    })
                }
            })
        }

        // 4 - Verifica se il campo privacy se obbligatorio e in tal caso ne applica il valore al calcolo del punteggio finale

        var sheetTotalRatePercent = sheetState.sheetTotalRatePercent;
        var privacyField = sheet.fields.filter(f => f.name === "Privacy");
        if (privacyField !== null && privacyField.length == 1) {
            if (privacyField[0].isMandatory) {
                sheetTotalRatePercent = (sheetState.Privacy === null || sheetState.Privacy === undefined || sheetState.Privacy === "") ? 0 : sheetTotalRatePercent * sheetState.Privacy;
            }
            setSheetState({ ...sheetState, sheetTotalRatePercent: sheetTotalRatePercent });
        }


        // 5 - Verifica lunghezza campo feedback e intervento
        if (document.getElementById("Feedback") && document.getElementById("Feedback").value.length > 4000) {
                errs.push(`Il campo feedback ha un limite di 4000 caratteri.`);
                checkPassed = false;
        }
        if (document.getElementById("Intervento") && document.getElementById("Intervento").value.length > 4000) {
            errs.push(`Il campo intervento ha un limite di 4000 caratteri.`);
            checkPassed = false;
        }

        setErrors(errs);

        if (errs.length > 0) {
            setModalTitle("Si sono verificati degli errori.");
            setModalIsOpened(true);
        }

        //Se ha passato i controlli invia la scheda
        if (checkPassed) {
            setModalTitle("Conferma salvataggio.");
            var dataToSave = { ...sheetState };
            //console.log(dataToSave);
            delete dataToSave.consulentiPresenti;
            delete dataToSave.isLoadingConsulentiPresentiList;
            await saveSheet(dataToSave);
        }

        setIsSendingEvaluation(false);
    }
    const saveSheet = async (dataToSave) => {

        setIsSendingEvaluation(true);
        // console.log(dataToSave);

        if (props.isEditing === true) {
            //Esegue PUT
            var res = await valutaAPIputBody(process.env.REACT_APP_VALUTA_API_PUT_EVALUATION, dataToSave)
                .then((res) => {
                    if (res === null || res === undefined || res === "" || res.length <= 0) {
                        setErrors(["Errore: Risposta del servizio non corretta, valutazione non aggiornata."]);
                        setModalIsOpened(true);
                        setIsSendingEvaluation(false);
                        return false;
                    }
                    else {
                        if (res.status === null || res.status === undefined || res.status === "" || res.status === false) {
                            setErrors(["Errore: Si sono verificati errori durante aggiornamento, valutazione non aggiornata."]);
                            setModalIsOpened(true);
                            setIsSendingEvaluation(false);
                            return false;
                        }
                        else {
                            window.location.href = `/evaluation/confirmation/${res.idEvaluation}`;
                        }
                    }
                })
        }
        else {
            //Esegue POST
            await valutaAPIpost(process.env.REACT_APP_VALUTA_API_POST_EVALUATION, dataToSave).then(res => {
                if (res === null || res === undefined || res === "" || res.length <= 0) {
                    setErrors(["Errore: Risposta del servizio non corretta, valutazione non salvata."]);
                    setModalIsOpened(true);
                    setIsSendingEvaluation(false);
                    return false;
                }
                else {
                    if (res.status === null || res.status === undefined || res.status === "" || res.status === false) {
                        setErrors(["Errore: Si sono verificati errori durante il salvataggio, valutazione non salvata."]);
                        setModalIsOpened(true);
                        setIsSendingEvaluation(false);
                        return false;
                    }
                    else {
                        window.location.href = `/evaluation/confirmation/${res.idEvaluation}`;
                    }
                }
            })
        }





    }
    const addDefaultSrc = (ev) => {
        ev.target.src = DEFAULTPIC;
    }
    const downloadEvaluation = () => {
        var options = {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            imageQuality: 100,
            html2canvas: { scale: 4 },
            margin: { right: 15, top: 30, bottom: 0, left: 15 },
            output: 'Valutazione_' + sheetState.IdEvaluation + '.pdf'
        };
        html2PDF(document.getElementById("pdf"), options);
    }
    useEffect(() => {
        setIsLoading(true);
        (async function doAsync() {
            await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEET, { sheetId: props.id, isEditing: props.isEditing }).then(res => {
                if (res !== null && res !== undefined) {

                    if (props.isEditing) {
                        if (res.idSheet === null || res.idSheet === undefined || isNaN(res.idSheet)) {
                            var exception = (res.Error !== undefined) ? "\n\r" + res.Error : "";
                            if (exception !== "") {
                                //console.log("A")
                                setLoadingMessage(exception);
                                setIsLoading(false)
                            }
                            else {
                                //console.log("B")
                                setLoadingMessage("Si sono verificati errori nel caricamento della scheda, prova a ricaricare la pagina o contatta il supporto.");
                            }
                            return false;
                        }
                        //IN FASE EDITING LA API GET SHEET RISPONDE DANDO TUTTE LE DOMANDE E LE SEZIONI 
                        //ANCHE QUELLE DELETED, IN QUANTO NELLE VECCHIE SCHEDE MODIFICATE NEL TEMPO ERANO STATE RIMOSSE
                        //O MODIFICATE ALCUNE DOMANDE O SEZIONI, MA NELLE VALUTAZIONI SONO PRESENTI
                        //QUINDI DOBBIAMO FILTRARE SHEET ELIMINANDO SEZIONI E DOMANDE CHE NON SONO PRESENTI NELLA VALUTAZIONE
                        res.subjects.forEach((SheetSubjet) => {
                            //esiste questo subject nella valutazione? Se si lo mantengo altrimento lo rimuovo
                            //console.log("idSheetSubject nello sheet = " + SheetSubjet.idSheetSubject);
                            //console.log(sheetState.sheetSubjectsAndQuestions);
                            var subFound = sheetState.sheetSubjectsAndQuestions.filter(EvaluationSubject => EvaluationSubject.idSheetSubject === SheetSubjet.idSheetSubject);
                            if (subFound !== null && subFound !== undefined && subFound.length == 1) {
                                //Verifico le domande
                                /*console.log("Subject trovato nella valutazione")
                                console.log(subFound)
                                console.log("Subject trovato nello sheet")
                                console.log(SheetSubjet.questions)
                                console.log("-----------------------------------------------")*/
                                SheetSubjet.questions.forEach((SheetSubjectQuestion) => {
                                    var qstFound = subFound[0].Questions.filter(eq => eq.IdSubjectQuestion === SheetSubjectQuestion.idSubjectQuestion);
                                    if (qstFound !== null && qstFound !== undefined && qstFound.length == 1) {
                                        //console.log("QUESTION " + SheetSubjectQuestion.idSubjectQuestion + " TROVATA.")
                                    }
                                    else {
                                        SheetSubjet.questions = SheetSubjet.questions.filter(q => q.idSubjectQuestion !== SheetSubjectQuestion.idSubjectQuestion);
                                        //console.log("QUESTION (" + SheetSubjectQuestion.idSubjectQuestion+")" + SheetIndex + " SUBJECT " + SheetSubjectQuestionIndex +" NON TROVATA, RIMOSSA.")
                                    }
                                });
                            }
                            else {
                                //console.log("SUBJECT " + SheetSubjet.idSheetSubject + " NON TROVATO, RIMOSSO.");
                                res.subjects = res.subjects.filter(ss => ss.idSheetSubject !== SheetSubjet.idSheetSubject);
                            }
                        });
                        //console.log(sheetPlayerRangeColor);
                        //console.log("res.sheetPlayerRange=" + sheetState.sheetPlayerRange)
                        //console.log("sheetState.sheetPlayerRange=" + sheetState.sheetPlayerRange)
                        //console.log("sheetPlayerRangeColor=" + sheetPlayerRangeColor.backgroundColor)
                    }

                    //console.log(res)
                    var idSheet = res.idSheet;
                    setSheet(res);
                    setIsPrivacyMandatory(checkIfPrivacyIsMandatory(res));
                    setIsLoading(false);
                    //Imposto i subjects nello sheetState se non c'e' una modifica di una evaluation
                    if (props.isEditing === false) {
                        var sheetSubjectsAndQuestions = [];
                        if (res !== null && res !== undefined && res.subjects !== null && res.subjects !== undefined && res.subjects.length > 0) {
                            res.subjects.forEach((item) => {
                                var subjectQuestions = [];
                                if (item.questions !== null && item.questions.length > 0) {
                                    item.questions.forEach((q) => {
                                        subjectQuestions.push({
                                            "Question": q.question,
                                            "QuestionRate": null,
                                            "idSubjectQuestion": q.idSubjectQuestion,
                                            "QuestionNote": ""
                                        });
                                    });
                                }
                                sheetSubjectsAndQuestions.push({
                                    "idParentSubject": item.idParentSubject,
                                    "idSheetSubject": item.idSheetSubject,
                                    "EvaluationSubjectWeight": item.weight,
                                    "EvaluationSubjectMaxRate": null,
                                    "EvaluationSubjectRate": null,
                                    "EvaluationSubjectRatePercent": null,
                                    "EvaluationSubjectAvg": null,
                                    "PlayerRange": null,
                                    "Questions": subjectQuestions
                                })
                            });
                        }
                        setSheetState({ ...sheetState, consulentiPresenti: [], sheetSubjectsAndQuestions: sheetSubjectsAndQuestions, idSheet: idSheet })
                    }
                    else {
                        setSheetState({ ...sheetState, consulentiPresenti: [], idSheet: idSheet, sheetPlayerRangeColor: { backgroundColor: getSheetPlayerRangeColor(res, sheetState.sheetPlayerRange) } })
                    }
                }
                else {
                    var exception = (res.Error !== undefined) ? "\n\r" + res.Error : "";
                    if (exception !== "") {
                        setLoadingMessage(exception);
                    }
                    else {
                        setLoadingMessage("Si sono verificati errori nel caricamento della scheda, prova a ricaricare la pagina o contatta il supporto.");
                    }
                }


            })
        })()
        // console.log(sheetState);
        return () => {
            setSheetState({})
        }
    }, []);
    return (!isLoading && sheet !== null) ?
        <React.Fragment>
            <div className="row mt-4 mb-3">
                <div className="col-12 col-sm-9 text-center text-md-left">
                    <h5>{sheet.sheetName}</h5>
                </div>
                <div className="col-12 col-sm-3 d-flex flex-row justify-content-center justify-content-md-end mt-3 mt-md-0">
                    <Link className="sheet-action-buttons d-flex flex-row align-items-center" to="/evaluation"><ArrowLeftCircle className="mr-1 sheet-action-icons" /> Schede</Link>
                    {(props.readOnly) ?
                        <React.Fragment>
                            <a className="sheet-action-buttons d-flex flex-row align-items-center ml-2" onClick={() => downloadEvaluation()}><FileEarmarkArrowDown className="mr-1 sheet-action-icons" /> Download</a>
                            <a className="sheet-action-buttons d-flex flex-row align-items-center ml-2" onClick={() => { history.push(`/evaluation/edit/${sheetState.IdEvaluation}`) }}><Pencil className="mr-1 sheet-action-icons" /> Modifica</a>
                            <a className="sheet-action-buttons d-flex flex-row align-items-center ml-2" onClick={() => deleteConfirmation()}><FileEarmarkX className="mr-1 sheet-action-icons text-danger" />Elimina</a>
                        </React.Fragment>
                        :
                        null
                    }
                </div>


            </div>
            <hr />
            <div id="pdf">
                <SheetFiledsHeader readOnly={props.readOnly} sheet={sheet} isEditing={props.isEditing} />
                <SheetSubjects readOnly={props.readOnly} sheet={sheet} isEditing={props.isEditing} />
                <SheetFiledsFooter readOnly={props.readOnly} sheet={sheet} isEditing={props.isEditing} />


                {(isPrivacyMandatory && sheetState.Privacy !== 1) ?
                    <div className="row d-flex flex-row justify-content-center sheet-final-result-container">
                        <div className="alert alert-warning text-dark">
                            <b>Ricordati che il campo <b>Privacy</b> in questa valutazione &egrave; obbligatorio.</b><br />
                            Il punteggio della valutazione &egrave; pari a ZERO, indipendentemente dalle risposte inserite,
                            quando il campo Privacy viene valorizzato su "NO".
                    </div>
                    </div>
                    : null}
                {(sheetState.sheetTotalRatePercent !== undefined && sheetState.sheetTotalRatePercent !== null && !isNaN(sheetState.sheetTotalRatePercent) && sheetState.sheetTotalRatePercent > 0) ?
                    <div id="SheetFinalVote" className="row d-flex flex-row justify-content-between sheet-final-result-container">
                        <div className="sheet-total-rate-percent d-flex flex-row align-items-center justify-content-center">
                            <div className="progress sheet-total-rate-percent-bar">
                                <div className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={sheetState.sheetTotalRatePercent}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    label={sheetState.sheetTotalRatePercent + "%"}
                                    style={{ width: sheetState.sheetTotalRatePercent + '%', backgroundColor: getSheetPlayerRangeColor(sheet, sheetState.sheetPlayerRange) }}
                                >{sheetState.sheetTotalRatePercent + "%"}</div>
                            </div>
                            <span className="ml-1"><span className="badge bagde-pti-big mr-1">{sheetState.sheetTotalRate.toFixed(2)}/<span className="text-warning">{sheetState.sheetMaxRate.toFixed(2)}</span></span></span>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <div className="sheet-player-range-description" style={(sheetState.sheetPlayerRangeColor.backgroundColor === null) ? { backgroundColor: getSheetPlayerRangeColor(sheet, sheetState.sheetPlayerRange) } : sheetState.sheetPlayerRangeColor}>{sheetState.sheetPlayerRangeDescription}</div>

                            <div className={(sheet.idSheet === 7) ? "PlayerRectangleCircleLittle" : "PlayerRangeCircleLittle"} style={(sheetState.sheetPlayerRangeColor.backgroundColor === null) ? { backgroundColor: getSheetPlayerRangeColor(sheet, sheetState.sheetPlayerRange) } : sheetState.sheetPlayerRangeColor}>

                                {sheetState.sheetPlayerRange}
                            </div>
                        </div>



                    </div>
                    :
                    null}
            </div>
            {
                !props.readOnly && sheet.fields !== null && sheet.fields !== undefined ?
                    <Fragment>
                        <div className="row mt-3 mb-4">
                            <div className="col-12 d-flex flex-row justify-content-end">
                                <button id="btnSaveEvaluation" className="btn btn-primary btn-valuta" onClick={() => checkSheet()} disabled={(isSendingEvaluation || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "" || sheetState.DataConsulenza === undefined) ? "disabled" : null}>SALVA</button>
                            </div>
                        </div>
                    </Fragment> :
                    <Fragment>
                        <div className="row mt-3 mb-4">
                            <div className="col-12 d-flex flex-row justify-content-end">
                                <button id="btnSaveEvaluation" className="btn btn-primary btn-valuta" onClick={() => window.close()} >CHIUDI</button>
                            </div>
                        </div>
                    </Fragment>
            }


            <Modal show={modalIsOpened} onHide={() => setModalIsOpened(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className={"mt-3 text-center " + ((errors.length > 0) ? "text-danger" : "text-dark")}>
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText}
                    {(errors.length > 0) ? errors.map((e, index) => {
                        return <div key={index} className="text-danger"><ExclamationCircleFill className="mr-2" />{e}</div>
                    }) : null}
                    {(messages.length > 0) ? messages.map((m, index) => {
                        return <div key={index} className="text-success"><CheckCircle className="mr-2" />{m}</div>
                    }) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalIsOpened(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment >
        :
        <Fragment>
            {
                !isLoading && sheet === null ?
                    <Loading icon={'warning'} message={loadingMessage} /> :
                    <Loading message={loadingMessage} />
            }
        </Fragment>

}
export default Sheet;