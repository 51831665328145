import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { valutaAPIget, valutaAPIput, valutaAPIdelete } from '../../../FetchApi';
import Loading from '../../Loading';
import '../../../css/Index.css';
import { FileEarmarkPlus, FileEarmarkCheck, FileEarmarkX, Files, FileEarmarkText, TrashFill} from 'react-bootstrap-icons';
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirm } from "react-confirm-box";

export default function SheetManager() {

    const [isLoading, setIsLoading] = useState(false);
    const [availableActiveSheets, setAvailableActiveSheets] = useState([])
    const [availableDeletedSheets, setAvailableDeletedSheets] = useState([])
    const [update, setUpdate] = useState(null)
    const history = useHistory();

    useEffect(() => {
        setIsLoading(false);
        (async function doAsync() {
            await clearCache()
            const activeSheets = await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEETS_LIST, { active: true });
            const deletedSheets = await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEETS_LIST, { active: false });
            setAvailableActiveSheets(activeSheets)
            setAvailableDeletedSheets(deletedSheets)
            setIsLoading(false);
        })()

    }, [update]);

    const goToSheet = (idSheet) => {
        history.push(`/evaluation/${idSheet}`)
    }
    const clearCache = async () => {
        await valutaAPIget(process.env.REACT_APP_VALUTA_API_CLEAR_CACHE_SHEET, null)
        await valutaAPIget(process.env.REACT_APP_VALUTA_API_CLEAR_CACHE_DROPDOWN, null)
    }
    const disableSheet = async (idSheet) => {
        await valutaAPIdelete(process.env.REACT_APP_VALUTA_API_DISABLE_SHEET, idSheet).then(res => {
            // console.log("update")
            setUpdate(Date.now())
        })

    }
    
    const enableSheet = async (idSheet) => {
        await valutaAPIput(process.env.REACT_APP_VALUTA_API_ENABLE_SHEET, idSheet).then(res => {
            // console.log("update")
            setUpdate(Date.now())
        })
    }
    const options = {
        labels: {
            confirmable: "Si",
            cancellable: "No"
        }
    }

    const deleteSheet = async (idSheet) => {
        const result = await confirm("Sei sicuro di voler eliminare in modo permanente questa scheda?", options);
        if (result) {
            await valutaAPIdelete(process.env.REACT_APP_VALUTA_API_DELETE_SHEET, idSheet).then(res => {
                setUpdate(Date.now())
            });
            return;
        }
        //console.log("Hai cliccato no");
    };
    


    return (

        <Fragment>
            {
                !isLoading ?
                    <Fragment>
                        <div className="container-fluid mb-5">
                            <div className="row d-flex flex-row justify-content-between align-items-center mb-3 mt-5">
                                <div className="col-12 col-md-6">
                                    <span className="sheetListTitle sheetListHeaderText">Schede attive</span>
                                </div>
                                <div className="col-12 col-md-6  d-flex flex-row justify-content-end">
                                    <a className="sheet-action-buttons d-flex flex-row align-items-center"
                                        onClick={() => { history.push(`/manager/createSheet`) }}>
                                        <FileEarmarkPlus
                                            className="mr-1 sheet-action-icons" /> Crea una nuova scheda
                                        </a>
                                </div>
                            </div>
                            <hr />
                            {
                                availableActiveSheets.map(_singleActiveSheet => {
                                    return <div key={_singleActiveSheet.idSheet} className="row sheetListElementRow pl-3">
                                        <div className="col-8 d-flex flex-row justify-content-start align-items-center sheetListSheetButtonText">
                                            <b className="mr-2">
                                                [{_singleActiveSheet.idSheet}#]
                                            </b> {_singleActiveSheet.sheetName}
                                        </div>
                                        <div className="col-4 d-flex flex-row justify-content-end align-items-center">

                                            <a className="sheet-action-buttons d-flex flex-row align-items-center mr-3"
                                                onClick={() => { goToSheet(_singleActiveSheet.idSheet) }}>
                                                <FileEarmarkText
                                                    className="mr-1 sheet-action-icons text-info" /> Compila</a>
                                            <Link to={{ pathname: '/manager/cloneSheet', state: { sheetToClone: _singleActiveSheet.idSheet } }}
                                                className="sheet-action-buttons d-flex flex-row align-items-center mr-3"
                                                onClick={() => { history.push(`/manager/createSheet`) }}>
                                                <Files
                                                    className="mr-1 sheet-action-icons text-warning" /> Clona</Link>
                                            <a className="sheet-action-buttons d-flex flex-row align-items-center mr-3"
                                                onClick={() => { disableSheet(_singleActiveSheet.idSheet) }}>
                                                <FileEarmarkX
                                                    className="mr-1 sheet-action-icons text-danger mr-3" /> Disattiva</a>
                                            <a className="sheet-action-buttons d-flex flex-row align-items-center"
                                                onClick={() => { deleteSheet(_singleActiveSheet.idSheet) }}>
                                                <TrashFill
                                                    className="mr-1 sheet-action-icons text-dark" /> Elimina</a>
                                        </div>
                                    </div>
                                })
                            }
                            <div className="row mt-5 mb-3">
                                <div className="col-12">
                                    <span className="sheetListTitle sheetListHeaderText">Schede non attive</span>
                                </div>
                            </div>
                            <hr />
                            {
                                availableDeletedSheets.map(_singleActiveSheet => {
                                    return <div key={_singleActiveSheet.idSheet} className="row sheetListElementRow pl-3">
                                        <div className="col-8 d-flex flex-row justify-content-start align-items-center sheetListSheetButtonText"><b className="mr-2">[{_singleActiveSheet.idSheet}#]</b> {_singleActiveSheet.sheetName}</div>
                                        <div className="col-4 d-flex flex-row justify-content-end align-items-center">

                                            {/*
                                            <a className="sheet-action-buttons d-flex flex-row align-items-center mr-3"
                                                onClick={() => { goToSheet(_singleActiveSheet.idSheet) }}>
                                                <FileEarmarkCheck
                                                    className="mr-1 sheet-action-icons text-info" /> Vedi</a>
                                            */}
                                            {/*
                                            <Link to={{ pathname: '/manager/cloneSheet', state: { sheetToClone: _singleActiveSheet.idSheet } }}
                                                className="sheet-action-buttons d-flex flex-row align-items-center mr-3"
                                                onClick={() => { history.push(`/manager/createSheet`) }}>
                                                <Files
                                                    className="mr-1 sheet-action-icons text-warning" /> Clona</Link>
                                            */}
                                            <a className="sheet-action-buttons d-flex flex-row align-items-center"
                                                onClick={() => { enableSheet(_singleActiveSheet.idSheet) }}>
                                                <FileEarmarkCheck
                                                    className="mr-1 sheet-action-icons text-success" /> Riattiva</a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </Fragment> :
                    <Loading />
            }
        </Fragment>

    )
}
