import React, { useState } from 'react';
import SheetContext from './components/SheetContext';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home';
import Manager from './components/Manager';
import Evaluation from './components/Evaluation';
import CreateSheet from './components/Manager/SheetBuilder/CreateSheet';
import FlashMessageContainer from './components/FlashMessageContainer';
import SheetManager from './components/Manager/Sheets/SheetManager';
import EvaluationConfirmation from './components/EvaluationConfirmation';
import EvaluationEdit from './components/EvaluationEdit';
import ChooseRole from './components/ChooseRole';
import { getCookieRole } from './AdalConfig';


const Layout = () => {

    const [sheetState, setSheetState] = useState([]);
    const cookieUserRole = getCookieRole();

    return (
        <div>
            <Router>
                <Header />
                <div className="container-fluid mt-3">
                    <Switch>
                        <Route exact path='/' render={(routeProps) => <Home />} />
                        <Route exact path='/chooserole' render={(props) => <ChooseRole {...props} />} />
                        <Route exact path='/evaluation' render={(props) => <Evaluation />} />
                        <Route exact path="/evaluation/:id" render={(props) => <Evaluation />} />
                        <Route exact path='/evaluation/confirmation/:id' render={(props) => <EvaluationConfirmation />} />
                        <Route exact path='/evaluation/edit/:id' render={(props) => <SheetContext.Provider value={[sheetState, setSheetState]}><EvaluationEdit /></SheetContext.Provider>} />
                        <Route exact path='/evaluation/view/:id' render={(props) => <SheetContext.Provider value={[sheetState, setSheetState]}><EvaluationEdit readOnly={true} /></SheetContext.Provider>} />
                        {(cookieUserRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_ADMIN ||
                            cookieUserRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_MANAGER)
                            ?
                            <React.Fragment>
                                <Route exact path='/manager' render={(props) => <Manager />} />
                                <Route exact path='/manager/createSheet' render={() => <CreateSheet/>} />
                                <Route exact path='/manager/cloneSheet' render={(props) => <CreateSheet cloner={props} />} />
                                <Route exact path='/manager/sheets' render={(props) => <SheetManager />} />
                            </React.Fragment>
                            :
                            null
                        }
                    </Switch>
                    <FlashMessageContainer />
                </div>
            </Router>
        </div>
    );
}
export default Layout;