import 'core-js';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import AccessDenied from './components/AccessDenied';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import './css/bootstrap/themes/minty/bootstrap.min.css'; /* https://bootswatch.com/minty */
import './css/Index.css';

ReactDOM.render(<AccessDenied />, document.getElementById('root'));