import React, { Fragment, useState } from 'react'
import 'pretty-checkbox'
import { Switch } from 'pretty-checkbox-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CommonSheetBuilder.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function SheetFieldBuilder(props) {
  const [, setSelectedDate] = useState(props.startDate);
  return (
    <div className='col-lg-3 col-sm-6 col-xs-12 search-form-item'>
          <h6 className={((props.field.fieldType === 'bool') ? 'switchFix' : '') +" ManagerFieldLabels"}>{props.field.fieldCustomLabel || props.field.fieldLabel} {props.field.isMandatory?<span className="text-danger">*</span>:''}  {!props.field.onAllSheets&&props.addingNewField?<FontAwesomeIcon className="deleteFieldIcon" onClick={()=>props.deleteField(props.field.fieldName)} color="red" icon={faTimesCircle} />:''}</h6>
      {
        props.field.fieldType === 'dateinput' &&
        <DatePicker className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside" {...props.disabled} selected={props.startDate} maxDate={props.maxDate} onChange={date => setSelectedDate(date)} />  
      }
      {
        props.field.fieldType === 'ext_dropdown' &&
        <select className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside" {...props.disabled}>
            <option defaultValue>{props.field.fieldLabel}</option>
            {
              props.field.fieldCustomValues && props.field.fieldCustomValues.map(_singleDropDownValue=>{
                return <option key={_singleDropDownValue.value} value={_singleDropDownValue.value}>{_singleDropDownValue.label}</option>
              })
            }
            {
              !props.field.fieldCustomValues &&
              <Fragment>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Fragment>
            }
        </select>
      }
      {
        props.field.fieldType === 'dropdown' &&
        <select className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside" {...props.disabled} >
            {
              props.field.fieldCustomValues && props.field.fieldCustomValues.map(_singleDropDownValue=>{
                return <option key={_singleDropDownValue.value} value={_singleDropDownValue.value}>{_singleDropDownValue.label}</option>
              })
            }
            {
              !props.field.fieldCustomValues &&
              <Fragment>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Fragment>
            }
        </select>
      }
      {
        props.field.fieldType === 'textinput' &&
        <input className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside" type='text' placeholder={props.field.fieldLabel} {...props.disabled}></input>
      }
      {
        props.field.fieldType === 'textarea' &&
        <textarea className='textAreaInput' placeholder={props.field.fieldName}></textarea>
      }
      {
        props.field.fieldType === 'bool' &&
        <Switch className="switchInput" {...props.disabled} color='success' />
      }
    
    </div>
    
  )
}
