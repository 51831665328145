import React, { useContext, useEffect } from 'react'
import { CreateSheetContext } from '../CreateSheetContext';

import { Range, getTrackBackground } from 'react-range';

export default function MultiSlider() {
  const  { playerEvaluationLabels, playerEvaluationValues, setPlayerEvaluationValues, playerEvaluationColors } = useContext(CreateSheetContext);

  useEffect(() => {
    // console.log("Slider updated!");
  
    return () => {
    }
  }, [playerEvaluationLabels])

  
  const STEP = 1
  const COLORS = playerEvaluationColors.slice(0,playerEvaluationLabels.length);
  return (
    <div className="row col-12">
      <div className="col-1 multiSlider-limit" style={{backgroundColor:COLORS[0],paddingRight:0}}>{playerEvaluationLabels[0]}</div>
      <div className="col-10" style={{padding:0}}>
      <Range
      allowOverlap={false}
      values={playerEvaluationValues}
      step={STEP}
      min={0}
      max={99}
      onChange={values => {setPlayerEvaluationValues(values)}}
      renderTrack={({ props, children }) => (
        <div
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              background: getTrackBackground({
                values: playerEvaluationValues,
                colors: COLORS,
                min: 0,
                max: 100
              }),
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, index, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '42px',
            width: '42px',
            zIndex: 100,
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-28px',
              width: '55px',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '14px',
              fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
              padding: '4px',
              borderRadius: '4px',
              textAlign:"center",
              backgroundColor: playerEvaluationColors[index]
            }}
          >
            {`${playerEvaluationValues[index]}`}
          </div>
          <div
          >{playerEvaluationLabels[index]}</div>
        </div>
      )}
    />
      </div>
      <div className="col-1 multiSlider-limit" style={{backgroundColor:COLORS[playerEvaluationLabels.length-1],paddingLeft:0}}>{playerEvaluationLabels[playerEvaluationLabels.length-1]}</div>
    </div>
    
  )
}
