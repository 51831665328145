import React from 'react';
import { useParams, Link } from "react-router-dom";
import '../css/Evaluation.css';

const EvaluationConfirmation = (props) => {
    const { id } = useParams();
    return (
        <div className="text-center">
            <div><svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><circle className="confirmation-circle" cx="30" cy="30" r="30" fill="none" /><path className="confirmation-check" fill="none" d="m12.5 28l10.0 13 24-22.2" /></svg></div>
            <div><h5>Valutazione salvata correttamente!</h5></div>
            <div><h5><Link className="valuta-evaluation-link" to={"/evaluation/view/" + id}>#{id}</Link></h5></div>
            <div><Link className="valuta-evaluation-link" to="/">Vai</Link> all'elenco valutazioni.</div>
            <div><Link className="valuta-evaluation-link" to="/evaluation">Compila</Link> una nuova valutazione.</div>
            <div><Link className="valuta-evaluation-link" to={"/evaluation/view/" + id}>Vedi o modifica</Link> la valutazione appena inserita.</div>
        </div>
    )
}
export default EvaluationConfirmation;