import React, { useState } from 'react';
import '../../../css/Sheet.css';
import SheetHeaderBuilder from './SheetHeaderBuilder';
import SheetBodyBuilder from './SheetBodyBuilder';
import SheetFooterBuilder from './SheetFooterBuilder';
import SheetGeneralInfoBuilder from './SheetGeneralInfoBuilder';
import { CreateSheetProvider} from './CreateSheetContext';
import SheetActionButtons from './SheetActionButtons';
import { valutaAPIget } from '../../../FetchApi';
export default function CreateSheet(props) {

  const cloneSheet = async (sheetId) => {
    // console.log("Caricamento scheda: "+sheetId);
    const loadedSheet = await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEET,{sheetId});
    // console.log("Risultato:");
    // console.log(loadedSheet);
    if (loadedSheet.Error) {
      /**
      * TODO: Gestisci errore con flash message
      */
      console.log("Errore caricamento scheda da clonare");
    }else{
        // console.log("Scheda da clonare "+sheetId+" caricata");        
    }
    return loadedSheet
}
const [clonedSheet, setClonedSheet] = useState(props && props.cloner && props.cloner.location.state && props.cloner.location.state.sheetToClone?cloneSheet(props.cloner.location.state.sheetToClone):{})

  return(
    <CreateSheetProvider sheetToClone={clonedSheet}>
      <SheetActionButtons/>
      <hr></hr>
      <SheetGeneralInfoBuilder/>
      <SheetHeaderBuilder isCloning={clonedSheet?true:false}/>
      <SheetBodyBuilder isCloning={clonedSheet?true:false}/>
      <SheetFooterBuilder isCloning={clonedSheet?true:false}/>
    </CreateSheetProvider>
  ) 
  
}
