import base64 from 'base-64';
import axios from 'axios';
import uuid from 'react-uuid'
import { adalApiFetch, requestOptions } from './AdalConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
    }
});
appInsights.loadAppInsights();
export const valutaAPIget = async (apiAction, params) => {
    const paramsStr = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'get';
        optionsPost.body = null;
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        adalApiFetch(fetch, process.env.REACT_APP_VALUTA_API_ENDPOINT + apiAction + '?' + paramsStr, optionsPost)
            .then((res) => 
            {
                if(res.status === 204 && apiAction === process.env.REACT_APP_VALUTA_API_GETDROPDOWN) {
                    return "204"
                }
                else
                {
                    return res.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const valutaAPIpost = async (apiAction, body) => {
    
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'post';
        optionsPost.body = JSON.stringify(body);
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        adalApiFetch(fetch, process.env.REACT_APP_VALUTA_API_ENDPOINT + apiAction, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const valutaAPIputBody = async (apiAction, body) => {
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'put';
        optionsPost.body = JSON.stringify(body);
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        adalApiFetch(fetch, process.env.REACT_APP_VALUTA_API_ENDPOINT + apiAction, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const valutaAPIdelete = async (apiAction, param) => {
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'delete';
        optionsPost.body = null;
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        adalApiFetch(fetch, process.env.REACT_APP_VALUTA_API_ENDPOINT + apiAction + '/' + param, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const valutaAPIput = async (apiAction, param) => {
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'put';
        optionsPost.body = null;
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        adalApiFetch(fetch, process.env.REACT_APP_VALUTA_API_ENDPOINT + apiAction + '/' + param, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const siaAPIget = async (apiAction, params) => {
    const paramsStr = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'get';
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        optionsPost.body = null;
        adalApiFetch(fetch, process.env.REACT_APP_SIAREST_API_ENDPOINT + apiAction + '?' + paramsStr, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response.users);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}
export const siaAPIpicture = async (user, size) => {
    return new Promise((resolve, reject) => {
        var optionsPost = { ...requestOptions };
        optionsPost.method = 'get';
        optionsPost.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        optionsPost.body = null;
        adalApiFetch(fetch, process.env.REACT_APP_SIAREST_API_ENDPOINT + process.env.REACT_APP_SIAREST_API_GETPICTURE + '?user=' + user + '&size=' + size, optionsPost)
            .then((res) => res.json())
            .then((response) => {
                resolve(response.users);
            })
            .catch((err) => {
                reject("[KO] Risposta del servizio errata.");
                appInsights.trackException({ error: new Error('[KO] ' + JSON.stringify(err)), severityLevel: SeverityLevel.Error });
            })
    });
}