import React, { useContext, useState, useEffect } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import SingleSubjectBuilder from './SingleSubjectBuilder';
import CreatableSelect from 'react-select/creatable';
import { Check2Circle } from 'react-bootstrap-icons';
import { capitalize } from '../../../helpers/helpers'

export default function SheetBodyBuilder() {
    const { subjects, addSubject, premadeSubjects, availableWeight, sheetWeightType, sheetType } = useContext(CreateSheetContext)
    const [subjectName, setSubjectName] = useState({})
    const [subjectItemName, setSubjectItemName] = useState('')
    const [subjectWeight, _setSubjectWeight] = useState(availableWeight)
    const [questionIsMandatory, setQuestionIsMandatory] = useState(true)

    useEffect(() => {
        setSubjectWeight(availableWeight)
        return () => {
        }
    }, [availableWeight])
    const parseBool = (boolInput) => {
        return boolInput === 'false' ? false : true
    }
    async function askToAddSubject() {
        if (!subjectName.label || subjectName.label.length === 0) {
            /**
             * TODO: Mostrare un flash message per evidenziare il problema
             */
            return
        }

        const newSubject = {
            id: subjects.length + 1,
            name: subjectName.label,
            premadeId: !subjectName.__isNew__ ? subjectName.value : null,
            weight: subjectWeight,
            questions: [],
            subsections: []
        }
        addSubject(newSubject)
        setSubjectName({})
        setSubjectWeight(availableWeight - subjectWeight)
    }
    async function askToAddItemSubject() {
        if (!subjectItemName) {
            /**
             * TODO: Mostrare un flash message per evidenziare il problema
             */
            return
        }
        // console.log(questionIsMandatory);
        const newSubject = {
            id: subjects.length + 1,
            name: subjectItemName,
            premadeId: null,
            weight: subjectWeight,
            isMandatory: questionIsMandatory,
            questions: [],
            subsections: []
        }
        addSubject(newSubject)
        setSubjectItemName('')
        setSubjectWeight(availableWeight - subjectWeight)
    }
    function setSubjectWeight(_subjectWeight) {
        if (_subjectWeight > 100) {
            _setSubjectWeight(100)
        } else if (_subjectWeight < 0) {
            _setSubjectWeight(0)
        } else {
            _setSubjectWeight(_subjectWeight)
        }
    }

    return (
        sheetWeightType === 'pesoSezione' ?
            <div className="mb-5">
                <h5 className="ml-1 mb-3 mt-4">Sezione 3 - Sezioni e domande scheda</h5>
                <div className="p-4 ManagerSectionContainer">
                    <div className="row">
                        {
                            subjects.map(_singleSubject => {
                                return <SingleSubjectBuilder sheetType={sheetType} weightType={sheetWeightType} key={_singleSubject.id} subject={_singleSubject} />
                            })
                        }
                        <div className="col-12 ManagerSubSectionContainer newQuestionBox">
                            <h6>Crea nuova sezione</h6>
                            <div className="row">
                                <div className="col-12 col-md-9 mt-2">
                                    <h6 className="ManagerFieldLabels">Nome nuova sezione</h6>
                                    <CreatableSelect
                                        menuPlacement={'top'}
                                        options={premadeSubjects}
                                        closeMenuOnSelect={true}
                                        value={subjectName}
                                        onKeyUp={(e) => { if (e.key === 'Enter') { askToAddSubject() } }}
                                        onChange={setSubjectName}
                                    />
                                </div>
                                <div className="col-12 col-md-3 mt-2 d-flex flex-column align-items-center align-items-md-start">
                                    <h6 className="ManagerFieldLabels">Peso sezione</h6>
                                    <div className="input-group inputWeight">
                                        <input className='textInput form-control' value={subjectWeight} type='number' min={0} max={availableWeight} name='subjectWeight' onKeyUp={(e) => { if (e.key === 'Enter') { askToAddSubject() } }} onChange={(e) => setSubjectWeight(parseInt(e.target.value))} />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3 d-flex flex-row align-items-end justify-content-center justify-content-sm-end">
                                    <a className="ManagerBtns stopEditing"
                                        onClick={() => askToAddSubject()}>
                                        <Check2Circle className="ManagerBtnIcons" /> Conferma aggiunta sezione</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="mb-5">
                <h5 className="ml-1 mb-3 mt-4">Sezione 3 - Domande Scheda</h5>
                <div className="p-4 ManagerSectionContainer">
                    <div className="row">
                        {
                            subjects.map(_singleSubject => {
                                return <SingleSubjectBuilder sheetType={sheetType} weightType={sheetWeightType} key={_singleSubject.id} subject={_singleSubject} />
                            })
                        }
                        <div className="col-12 ManagerSubSectionContainer newQuestionBox">
                            <h6>Crea nuova domanda</h6>
                            <div className="row">

                                <div className="col-12 col-md-9 mt-2">
                                    <h6 className="ManagerFieldLabels">Domanda</h6>
                                    <input
                                        className='textInput form-control'
                                        value={subjectItemName}
                                        type='text'
                                        name='subjectItemName'
                                        onKeyUp={(e) => { if (e.key === 'Enter') { askToAddItemSubject() } }}
                                        onChange={(e) => setSubjectItemName(capitalize(e.target.value))}
                                    />
                                </div>

                                <div className="col-12 col-md-3 mt-2 d-flex flex-column align-items-center align-items-md-start">
                                    <h6 className="ManagerFieldLabels">Peso domanda</h6>
                                    <div className="input-group inputWeight">
                                        <input className='textInput form-control' value={subjectWeight} type='number' min={0} max={availableWeight} name='subjectWeight' onKeyUp={(e) => { if (e.key === 'Enter') { askToAddItemSubject() } }} onChange={(e) => setSubjectWeight(parseInt(e.target.value))} />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2">%</span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    sheetType !== 'yes-no' &&
                                    <div className="col-12 col-md-3 mt-2 d-flex flex-column align-items-center align-items-md-start">
                                    <div className="mt-2">
                                        <label htmlFor='questionIsMandatory' className="questionLabel">Risp. obbligatoria</label>
                                        <select value={questionIsMandatory} name='questionIsMandatory' onChange={(e) => { setQuestionIsMandatory(parseBool(e.target.value)) }}>
                                            <option value={true} defaultValue>SI</option>
                                            <option value={false}>NO</option>
                                        </select>
                                    </div>
                                    </div>
                                }
                                

                                <div className="col-12 mt-3 d-flex flex-row align-items-end justify-content-center justify-content-sm-end">
                                    <a className="ManagerBtns stopEditing"
                                        onClick={() => askToAddItemSubject()}>
                                        <Check2Circle className="ManagerBtnIcons" /> Conferma aggiunta domanda</a>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
    )
}
