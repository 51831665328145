import { AuthenticationContext, adalFetch } from 'react-adal';
import { trackEvent, trackException } from './AppInsights';
export const AdalConfig = {
    tenant: process.env.REACT_APP_AD_TENANT,
    clientId: process.env.REACT_APP_AD_CLIENTID,
    redirectUri: process.env.REACT_APP_AD_REDIRECTURI,
    endpoints: {
        api: process.env.REACT_APP_AD_CLIENTID
    },
    cacheLocation: 'localStorage' //sessionStorage
};
export const authContext = new AuthenticationContext(AdalConfig);
export const getToken = () => authContext.getCachedToken(AdalConfig.clientId);
export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, AdalConfig.endpoints.api, fetch, url, options);
export const requestOptions = {
    crossDomain: true,
    mode: "cors",
    redirect: "follow",
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    }
};
//Dato il token, verifica se questo contiene ruoli tra quelli abilitati per l'applicazione
//Ritorna un oggetto di risposta che contiene "allowedUserRoles" con i ruoli AD dell'utente
export const verifyTokenRoles = async (decodedToken) => {
    //Verify if the token contains user's infos and user's roles
    if (decodedToken !== null && decodedToken !== undefined && decodedToken !== "" &&
        decodedToken.roles !== null && decodedToken.roles !== undefined && decodedToken.roles !== "") {
        //Verifico che il token sia staccato per questa applicazione
        if (decodedToken.aud !== process.env.REACT_APP_AD_CLIENTID) {
            trackException(`${decodedToken.upn} used a wrong token aud: ${decodedToken.aud} for this application.`, { name: decodedToken.name, upn: decodedToken.upn, tokenAud: decodedToken.aud, applicationAud: process.env.REACT_APP_AD_CLIENTID });
            return {
                success: false, message: `${decodedToken.upn} used a wrong token aud for this application.`, allowedUserRoles: []
            };
        }
        var adminRoles = process.env.REACT_APP_AD_VALUTA_ADMIN_ROLES.split(",");
        var managerRoles = process.env.REACT_APP_AD_VALUTA_MANAGER_ROLES.split(",");
        var evaluatorRoles = process.env.REACT_APP_AD_VALUTA_EVALUATOR_ROLES.split(",");
        var allowedRoles = adminRoles.concat(managerRoles).concat(evaluatorRoles);
        //Verify if the user's roles are allowed and remove not allowed roles from the array
        await decodedToken.roles.forEach((roleName, index) => {
            if (!allowedRoles.includes(roleName)) {
                decodedToken.roles.splice(index, 1);
            }
        })
        //Let the user choose a profile
        if (decodedToken.roles.length <= 0) {
            //User has no allowed roles
            trackException(`User ${decodedToken.upn} has roles inside the token but no one is allowed.`, { name: decodedToken.name, upn: decodedToken.upn, roles: JSON.stringify(decodedToken.roles) });
            return {
                success: false, message: `User ${decodedToken.upn} has roles inside the token but no one is allowed.`, allowedUserRoles: []
            };
        }
        trackEvent(`User ${decodedToken.upn} has allowed roles inside the token, user logged in.`, { name: decodedToken.name, upn: decodedToken.upn, roles: JSON.stringify(decodedToken.roles) });
        return {
            success: true, message: `User ${decodedToken.upn} has allowed roles inside the token, user logged in.`, allowedUserRoles: decodedToken.roles
        };
    }
    else {
        trackException(`User ${decodedToken.upn} hasn't any roles inside the token.`, { name: decodedToken.name, upn: decodedToken.upn });
        //User has no roles inside the token
        return {
            success: false, message: `User ${decodedToken.upn} hasn't any roles inside the token.`, allowedUserRoles: []
        };
    }
    return {
        success: false, message: `Unknown error verifying user roles from token.`, allowedUserRoles: []
    };
}
//Dato il ruolo passato (contenuto nel token), ritorna il ruolo utente nell'applicazione
export const getUserApplicationRole = (tokenRole) => {
    if (tokenRole !== null && tokenRole !== undefined && tokenRole !== "") {
        var adminRoles = process.env.REACT_APP_AD_VALUTA_ADMIN_ROLES.split(",");
        if (adminRoles.includes(tokenRole)) {
            return process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_ADMIN;
        }
        var managerRoles = process.env.REACT_APP_AD_VALUTA_MANAGER_ROLES.split(",");
        if (managerRoles.includes(tokenRole)) {
            return process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_MANAGER;
        }
        var evaluatorRoles = process.env.REACT_APP_AD_VALUTA_EVALUATOR_ROLES.split(",");
        if (evaluatorRoles.includes(tokenRole)) {
            return process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_EVALUATOR;
        }
    }
    return null;
}
//Verifica se il ruolo nel cookie è un ruolo valido per l'applicazione
export const verifyCookieRole = (cookieRole) => {
    if (cookieRole !== null && cookieRole !== "" && cookieRole !== undefined && cookieRole.length > 0) {
        if (cookieRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_ADMIN) { return true; }
        if (cookieRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_MANAGER) { return true; }
        if (cookieRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_EVALUATOR) { return true; }
    }
    return false;
}
export const getCookieRole = () => {
    if (window.sessionStorage.getItem("valuta.role") && window.sessionStorage.getItem("valuta.role") !== "" && verifyCookieRole(window.sessionStorage.getItem("valuta.role"))) {
        return window.sessionStorage.getItem("valuta.role");
    }
    return false;
}
export const setCookieRole = (role) => {
    if (verifyCookieRole(role)) {
        window.sessionStorage.setItem("valuta.role", role);
        return true;
    }
    return false;
}
export const getCookieUserFullName = () => {
    if (window.sessionStorage.getItem("valuta.userFullName") && window.sessionStorage.getItem("valuta.userFullName") !== "") {
        return window.sessionStorage.getItem("valuta.userFullName");
    }
    return false;
}
export const setCookieUserFullName = (name) => {
    window.sessionStorage.setItem("valuta.userFullName", name);
    return true;
}
//Ottiene la countri parsando il nome del ruolo utente nel token
export const getCountryFromTokenRole = (cookieRole) => {
    var sites = process.env.REACT_APP_VALUTA_USER_APPLICATION_SITES.split(",");
    var ret = null;
    sites.forEach((site) => {
        var city = site.split(":")[0];
        var country = site.split(":")[1];
        if (cookieRole.slice(-7) === city) { //BALERNA => 7 caratteri
            //console.log(country)
            ret = country;
        }
        else if (cookieRole.slice(-4) === city) { //ROMA => 4 caratteri
            //console.log(country)
            ret =  country;
        }
        else if (cookieRole.slice(-6) === city) { //TIRANA => 6 caratteri
            //console.log(country)
            ret = country;
        }
    })
    return ret;
}
export const setCookieEnabledCountries = (enabledCountriesList) => {
    window.sessionStorage.setItem("valuta.enabledCountries", JSON.stringify(enabledCountriesList));
}
export const getCookieEnabledCountries = () => {
    if (window.sessionStorage.getItem("valuta.enabledCountries") && window.sessionStorage.getItem("valuta.enabledCountries") !== "") {
        return JSON.parse(window.sessionStorage.getItem("valuta.enabledCountries"));
    }
    return null;
}
