import { compareRangeOrderByLabel, compareRangeOrderByLabelReverse } from './helpers/helpers'
import { getCookieEnabledCountries } from './AdalConfig';
import { siaAPIpicture } from './FetchApi';
import moment from 'moment';

export const filterEvaluationsUserList = (usersListToFilter, dropDownName) => {
    var filteredUsersList = usersListToFilter;
    var optionsValues = [];
    if (usersListToFilter && usersListToFilter !== undefined && usersListToFilter !== null && usersListToFilter.length > 0) {
        filteredUsersList = filteredUsersList.filter(u => u.roleName === dropDownName);
        filteredUsersList.forEach((user) => {
            optionsValues.push({ value: user.idUserSF, label: user.fullName })
        })
    }
    return optionsValues;
}
export const filterUserList = (usersListToFilter, dropDownName, date) => {
    var filteredUsersList = usersListToFilter;
    var enabledCountries = getCookieEnabledCountries();
    var optionsValues = [];
    switch (dropDownName) {
        case 'consulentiPresenti':
            filteredUsersList = filteredUsersList.filter(u => {
                var feature = u.working === true || u.worked === true;
                var migration = process.env.REACT_APP_SIA_MIGRATION_DATE
                if(date && moment(date) <= moment(migration)) {
                    feature = true;
                }

                return feature &&
                (process.env.REACT_APP_SIA_ROLES_FILTER_CONSULENTE.split(",").includes(u.roleName)) &&
                (process.env.REACT_APP_SIA_AREA_FILTER_CONSULENTE.split(",").includes(u.departmentName)) &&
                enabledCountries.includes(u.country)
            });
            break;
        case 'consulente':
            filteredUsersList = filteredUsersList.filter(u => {
                return (process.env.REACT_APP_SIA_ROLES_FILTER_CONSULENTE.split(",").includes(u.roleName)) &&
                (process.env.REACT_APP_SIA_AREA_FILTER_CONSULENTE.split(",").includes(u.departmentName)) &&
                enabledCountries.includes(u.country)
            });
            break;
        case 'quality':
            filteredUsersList = filteredUsersList.filter(u => {
                return (process.env.REACT_APP_SIA_ROLES_FILTER_QUALITY.split(",").includes(u.roleName)) &&
                (process.env.REACT_APP_SIA_AREA_FILTER_QUALITY.split(",").includes(u.departmentName)) &&
                enabledCountries.includes(u.country)
            });
            break;
        case 'teamManager':
            filteredUsersList = filteredUsersList.filter(u => {
                return (process.env.REACT_APP_SIA_ROLES_FILTER_TEAMMANAGER.split(",").includes(u.roleName)) &&
                (process.env.REACT_APP_SIA_AREA_FILTER_TEAMMANAGER.split(",").includes(u.departmentName)) &&
                enabledCountries.includes(u.country)
            });
            break;
        case 'valutatore':
            filteredUsersList = filteredUsersList.filter(u => {
                return (process.env.REACT_APP_SIA_ROLES_FILTER_VALUTATORE.split(",").includes(u.roleName)) &&
                (process.env.REACT_APP_SIA_AREA_FILTER_VALUTATORE.split(",").includes(u.departmentName)) &&
                enabledCountries.includes(u.country)
            });
             //remove CallCenter & Frontline con Ruolo Operatore
            var tempList = [];
            for (var i in filteredUsersList) {
               // console.log(filteredUsersList[i].fullName + " " + filteredUsersList[i].country + " " + filteredUsersList[i].departmentName + " " + filteredUsersList[i].roleName);
                if ((filteredUsersList[i].departmentName === "Call Center" || filteredUsersList[i].departmentName === "FrontLine") && filteredUsersList[i].roleName === "OPERATORE") {
                }
                else {
                    tempList.push(filteredUsersList[i]);
                }
            }
            filteredUsersList = [...tempList];
            //remove Commerciali se Tirana
            var tempList = [];
            for (var i in filteredUsersList) {
                if (filteredUsersList[i].country === "ALB" && filteredUsersList[i].departmentName === "Commerciali" && filteredUsersList[i].roleName === "OPERATORE") {
                }
                else {
                    tempList.push(filteredUsersList[i]);
                }
            }
            filteredUsersList = [...tempList];
            break;
        default:
            filteredUsersList = []
            break;

    }
    filteredUsersList.forEach((user) => {
        if (dropDownName === 'consulentiPresenti') {
            optionsValues.push({ value: user.idUserSF, label: `${user.lastName} ${user.firstName}`, accountName: user.managerFullName, idAccount: user.idManager })
        }
        else {
            optionsValues.push({ value: user.idUserSF, label: `${user.lastName} ${user.firstName}` })
        }
    })
    return optionsValues;
}
export const calculateSheetPlayerRange = (sheet, sheetTotalRatePercent) => {
    var sheetPlayerRange = "";
    var sheetRanges = [];
    if (sheet.idSheet === 7) { //Scheda linguistica
        sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue < sheetTotalRatePercent).sort(compareRangeOrderByLabelReverse);
    }
    else {
        if (sheetTotalRatePercent === 100) {
            sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue <= sheetTotalRatePercent).sort(compareRangeOrderByLabel);
        }
        else {
            sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue < sheetTotalRatePercent).sort(compareRangeOrderByLabel);
        }
    }
    /*console.log("Evaluation");
    console.log(sheetTotalRatePercent);
    console.log("Ranges");
    console.log(sheetRanges);*/
    sheetPlayerRange = (sheetRanges && sheetRanges !== undefined && sheetRanges.length > 0) ? sheetRanges[0].rangeLabel : sheet.playerRanges[sheet.playerRanges.length - 1].rangeLabel;
    /*console.log("Computed Player Range");
    console.log(sheetPlayerRange);*/
    return sheetPlayerRange;
}
export const calculateSheetPlayerRangeDescription = (sheet, sheetTotalRatePercent) => {
    var sheetPlayerRangeDescription = "";
    var sheetRanges = [];
    if (sheet.idSheet === 7) { //Scheda linguistica
        sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue < sheetTotalRatePercent).sort(compareRangeOrderByLabelReverse);
    }
    else {
        if (sheetTotalRatePercent === 100) {
            sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue <= sheetTotalRatePercent).sort(compareRangeOrderByLabel);
        }
        else {
            sheetRanges = sheet.playerRanges.filter(pr => pr.rangeMinValue < sheetTotalRatePercent).sort(compareRangeOrderByLabel);
        }
    }
    /*console.log("Ranges");
    console.log(sheetRanges);*/
    sheetPlayerRangeDescription = (sheetRanges && sheetRanges !== undefined && sheetRanges.length > 0) ? sheetRanges[0].rangeDescription : sheet.playerRanges[sheet.playerRanges.length - 1].rangeDescription;
    return sheetPlayerRangeDescription;
}
export const calculateSheetTotalRatePercent = (sheet, sheetSubjectsAndQuestions, privacy) => {
    var sheetTotalRatePercent = 0;
    //console.log(sheetSubjectsAndQuestions)
    sheetSubjectsAndQuestions.forEach(sItem => {

        if (sItem.EvaluationSubjectRatePercent !== null && sItem.EvaluationSubjectRatePercent !== "" && sItem.EvaluationSubjectRatePercent > -1) {
            let percentualeSuScheda = (sItem.EvaluationSubjectRatePercent * sItem.EvaluationSubjectWeight) / 100;
            //console.log("percentualeSuScheda------------------>" + percentualeSuScheda)
            sheetTotalRatePercent += percentualeSuScheda;
        }
    })
    sheetTotalRatePercent = Math.round((sheetTotalRatePercent + Number.EPSILON) * 10) / 10 //rounded 1 decimals
    //console.log("sheetTotalRatePercent-->" + sheetTotalRatePercent)
    //Privacy Check
    var privacyField = sheet.fields.filter(f => f.name === "Privacy");
    if (privacyField !== null && privacyField.length === 1) {
        //� presente il campo privacy
        if (privacyField[0].isMandatory) //ed � obbligatorio
        {
            //console.log("Azzero sheetTotalRatePercent perchè la privacy è obbligatoria")
            sheetTotalRatePercent = (privacy === null || privacy === undefined || privacy === "") ? 0 : sheetTotalRatePercent * privacy;
        }
    }
    return sheetTotalRatePercent;
}
export const calculateSheetMaxRate = (sheet, sheetSubjectsAndQuestions) => {
    var SheetMaxRate = 0;
    //console.log(sheetSubjectsAndQuestions)
    sheetSubjectsAndQuestions.forEach(sItem => {
        var maxOttenibileSezione = 0;
        if (sItem.Questions.length <= 0) {

            var sottosezioni = sheetSubjectsAndQuestions.filter(s => s.idParentSubject === sItem.idSheetSubject);
            sottosezioni.forEach(sottosezione => {
                sottosezione.Questions.forEach(qItem => {
                    if (qItem.IsMandatory || sheet.sheetType === "yes-no") {
                        maxOttenibileSezione += sheet.maxValue;
                    }
                    else {
                        if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                            maxOttenibileSezione += sheet.maxValue;
                        }
                    }

                })
                sheetSubjectsAndQuestions = sheetSubjectsAndQuestions.filter(s => s.idSheetSubject !== sottosezione.idSheetSubject);
            });
            SheetMaxRate += (maxOttenibileSezione * sItem.EvaluationSubjectWeight) / 100;
        }
        else {
            sItem.Questions.forEach(qItem => {

                if (qItem.IsMandatory || sheet.sheetType === "yes-no") {
                    maxOttenibileSezione += sheet.maxValue;
                }
                else {
                    if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                        maxOttenibileSezione += sheet.maxValue;
                    }
                }

            })
            SheetMaxRate += (maxOttenibileSezione * sItem.EvaluationSubjectWeight) / 100;
        }
    });
    return SheetMaxRate;
}
export const calculateSheetTotalRate = (sheet, sheetSubjectsAndQuestions, privacy) => {
    var sheetTotalRate = 0;
    //console.log(sheetSubjectsAndQuestions);
    sheetSubjectsAndQuestions.forEach(sItem => {
        var ottenutoSezione = 0;
        if (sItem.Questions.length <= 0) {
            //console.log("HA SOTTOSEZIONI");
            var sottosezioni = sheetSubjectsAndQuestions.filter(s => s.idParentSubject === sItem.idSheetSubject);
            sottosezioni.forEach(sottosezione => {
                sottosezione.Questions.forEach(qItem => {
                    if (qItem.IsMandatory || sheet.sheetType === "yes-no") {
                        if (qItem.QuestionRate !== null && qItem.QuestionRate !== "") {
                            ottenutoSezione += qItem.QuestionRate;
                        }
                    }
                    else {
                        if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                            ottenutoSezione += qItem.QuestionRate;
                        }
                    }
                })
                sheetSubjectsAndQuestions = sheetSubjectsAndQuestions.filter(s => s.idSheetSubject !== sottosezione.idSheetSubject);
            });
            sheetTotalRate += (ottenutoSezione * sItem.EvaluationSubjectWeight) / 100;
        }
        else {
            //console.log("NON HA SOTTOSEZIONI");
            sItem.Questions.forEach(qItem => {
                if (qItem.IsMandatory || sheet.sheetType === "yes-no") {
                    if (qItem.QuestionRate !== null && qItem.QuestionRate !== "") {
                        ottenutoSezione += qItem.QuestionRate;
                    }
                }
                else {
                    if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                        ottenutoSezione += qItem.QuestionRate;
                    }
                }
            })
            sheetTotalRate += (ottenutoSezione * sItem.EvaluationSubjectWeight) / 100;
        }
    })

    //Privacy Check
    var privacyField = sheet.fields.filter(f => f.name === "Privacy");
    if (privacyField !== null && privacyField.length === 1) {
        //� presente il campo privacy
        if (privacyField[0].isMandatory) //ed � obbligatorio
        {
            sheetTotalRate = (privacy === null || privacy === undefined || privacy === "") ? 0 : sheetTotalRate * privacy;
        }
    }
    return sheetTotalRate;
}
export const calculateSubjectPlayerRange = (sheet, EvaluationSubjectRatePercent) => {
    sheet.playerRanges.sort((a, b) => (a.rangeMinValue < b.rangeMinValue) ? 1 : ((b.rangeMinValue < a.rangeMinValue) ? -1 : 0));
    var ranges = [];
    if (EvaluationSubjectRatePercent === 100) {
        ranges = sheet.playerRanges.filter(pr => pr.rangeMinValue <= EvaluationSubjectRatePercent).sort(compareRangeOrderByLabel);
    }
    else {
        ranges = sheet.playerRanges.filter(pr => pr.rangeMinValue < EvaluationSubjectRatePercent).sort(compareRangeOrderByLabel);
    }
        //console.log(ranges);
    return (ranges && ranges !== undefined && ranges.length > 0) ? ranges[0].rangeLabel : sheet.playerRanges[sheet.playerRanges.length - 1].rangeLabel;
}
export const calculateEvaluationSubjectMaxRate = (sheet, subjectQuestions) => {
    // console.log(subjectQuestions)
    //console.log(sheet)
    var EvaluationSubjectMaxRate = 0;
    var idSubjectWhereGetWeight = null;
    subjectQuestions.forEach(qItem => {
        var idSubjectQuestion = (qItem?.IdSubjectQuestion || qItem?.idSubjectQuestion);
        //console.log("cerco la domanda " + idSubjectQuestion);
        (sheet?.subjects || sheet?.Subjects).forEach(sub => {
            //console.log(sub)
            var qstn = (sub?.questions || sub?.Questions).filter(qst => (qst?.idSubjectQuestion || qst?.IdSubjectQuestion) === idSubjectQuestion);
            if (qstn.length > 0) {
                //console.log("Trovata domanda " + idSubjectQuestion + " del subject " + (sub?.idSheetSubject || sub?.IdSheetSubject) + " con parent " + (sub?.idParentSubject || sub?.IdParentSubject))
                idSubjectWhereGetWeight = ((sub?.idParentSubject || sub?.IdParentSubject) !== null && (sub?.idParentSubject || sub?.IdParentSubject) !== undefined && (sub?.idParentSubject || sub?.IdParentSubject) !== "") ? (sub?.idParentSubject || sub?.IdParentSubject) : (sub?.idSheetSubject || sub?.IdSheetSubject);
            }
        });
        //console.log("idSubjectWhereGetWeight = " + idSubjectWhereGetWeight)
        if (qItem.IsMandatory || sheet.sheetType === "yes-no") {
            EvaluationSubjectMaxRate += sheet.maxValue;
        }
        else {
            if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                EvaluationSubjectMaxRate += sheet.maxValue;
            }
        }

    })
    //console.log(sheet)
    var pesoSezione = (sheet?.subjects || sheet?.Subjects).filter(s => (s.idSheetSubject || s.IdSheetSubject) === idSubjectWhereGetWeight)[0].weight;
    EvaluationSubjectMaxRate = (EvaluationSubjectMaxRate * pesoSezione) / 100;
    return Math.round((EvaluationSubjectMaxRate + Number.EPSILON) * 10) / 10;
}
export const calculateSheetSubjectsVotesAvg = (sheet, sheetSubjectsAndQuestions) => {
    var mediaRisposteSezioni = [];
    sheetSubjectsAndQuestions.forEach(sItem => {
        var ottenutoSezione = 0;
        var totRisposteSezione = 0;
        sItem.Questions.forEach(qItem => {
            if (sheet.sheetType === "yes-no") {
                if (qItem.QuestionRate !== null && qItem.QuestionRate !== "") {
                    ottenutoSezione += qItem.QuestionRate;
                    totRisposteSezione++;
                }
            }
            else {
                if (qItem.QuestionRate !== null && qItem.QuestionRate !== "" && qItem.QuestionRate > 0) {
                    ottenutoSezione += qItem.QuestionRate;
                    totRisposteSezione++;
                }
            }
        })
        var media = ottenutoSezione / totRisposteSezione;
        mediaRisposteSezioni.push(!isNaN(media) ? media : 0);
    });
    var mediaReale = mediaRisposteSezioni.reduce((a, b) => a + b, 0) / mediaRisposteSezioni.length;
    return Math.round((mediaReale + Number.EPSILON) * 10) / 10
}
export const getVoteOptions = (sheet) => {
    var maxValue = sheet.maxValue;
    var sheetType = sheet.sheetType;
    var voteOptions = [];
    if (sheetType === "yes-no") {
        voteOptions.push({ value: 0, label: 'NO' })
        voteOptions.push({ value: 5, label: 'SI' })
    }
    else {
        for (let i = 0; i <= maxValue; i++) {
            voteOptions.push({ value: i, label: (i === 0) ? 'n.d.' : i })
        }
    }
    return voteOptions;
}
export const getRangeColor = (idSheet, range) => {
    var allRanges = JSON.parse(window.sessionStorage.getItem("valuta.allPlayerRanges"));
    var rangeColor = allRanges.filter(r => r.idSheet === idSheet && r.rangeLabel === range)[0]?.rangeColor;
    return (rangeColor === undefined || rangeColor === null || rangeColor === "") ? "#CCCCCC" : "#" + rangeColor;
}
export const toCamelCase = (string) => {
    return string.replace(/^./, function (_string) { return _string.toLowerCase(); })
}
export const checkIfPrivacyIsMandatory = (sheet) => {
    if (sheet.fields === undefined || sheet.fields === null) {
        return false;
    }
    var privacyField = sheet.fields.filter(f => f.name === "Privacy");
    if (privacyField !== null && privacyField.length === 1) {
        return privacyField[0].isMandatory;
    }
    return false;
}
export const getFirstDayOfMonth = () => {
    var d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), 1);
}
export const customDropDownStyles = {
    container: provided => ({
        ...provided,
        minWidth: 180
    })
};
export const getCriticitaColor = (evaluationSubjectCriticality) => {
    if (evaluationSubjectCriticality <= 50)
        return '#e85454';
    if (evaluationSubjectCriticality > 50 && evaluationSubjectCriticality <= 70)
        return '#f8c846';
    if (evaluationSubjectCriticality > 70)
        return '#61D875';
}
export const getSheetPlayerRangeColor = (sheet, sheetPlayerRangeLabel) => {
    //console.log(sheet.playerRanges)
    //console.log(sheetPlayerRangeLabel)
    return '#' + sheet.playerRanges.filter(r => r.rangeLabel === sheetPlayerRangeLabel)[0]?.rangeColor;
}
export const upperFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export const setDivToggleStatus = (divName, status) => {
    var cookieName = `valuta.div.${divName}`;
    window.localStorage.setItem(cookieName, status);
}
export const getDivToggleStatus = (divName) => {
    var cookieName = `valuta.div.${divName}`;
    return window.localStorage.getItem(cookieName);
}
export const toggleDiv = (divName) => {
    var divToggleStatus = getDivToggleStatus(divName);
    if (divToggleStatus !== null) {
        if (divToggleStatus === "d-none") {
            setDivToggleStatus(divName, "d-block");
            return "d-block";
        }
        else if (divToggleStatus === "d-block") {
            setDivToggleStatus(divName, "d-none");
            return "d-none";
        }
    }
    else {
        setDivToggleStatus(divName, "d-none");
        return "d-none";
    }
}
export const getRandomFakeImage = () => {
    var rnd = Math.floor(Math.random() * 20);
    var folder = Math.floor(Math.random() * 2) % 2 === 1 ? "women" : "men";
    return "https://randomuser.me/api/portraits/" + folder + "/" + rnd + ".jpg";
}
export const getUserImage = async (user) => {
    var result = await siaAPIpicture(user, 0);
    if(result?.length > 0 && result[0].pic && result[0].pic !== '') {
        return 'data:image/png;base64,' + result[0].pic;
    }
    
    return "";
    // return `${process.env.REACT_APP_VALUTA_API_ENDPOINT}pics/${siaId}.jpg`;
}