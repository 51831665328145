import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SheetContext from './SheetContext';
import DatePicker from "react-datepicker";
import AsyncImage from "./AsyncImage";
import Select from 'react-select';
import moment from 'moment';
import Loading from './Loading';
import { siaAPIget } from '../FetchApi';
import '../css/SearchForm.css';
import "react-datepicker/dist/react-datepicker.css";
import { updateSIAUsersLists } from '../store/store';
import { calculateSheetPlayerRange, calculateSheetPlayerRangeDescription, calculateSheetTotalRatePercent, calculateSheetTotalRate, getSheetPlayerRangeColor } from '../Tools';

const RenderField = (props) => {

    const field = props.field;
    const sheet = props.sheet;
    const [sheetState, setSheetState] = useContext(SheetContext);
    const [selectedValue, setSelectedValue] = useState(null);
    const valutaStore = useSelector(state => state.valutaStore);
    const dispatch = useDispatch();
    const customDropDownStyles = {
        container: provided => ({
            ...provided,
            minWidth: 180,
            borderRadius: 30
        })
    };
    const onDateChangeHandle = async (value) => {
        if (field.name === "DataConsulenza") {
            if (!props.readOnly) {
                setSheetState({ ...sheetState, isLoadingConsulentiPresentiList: true });
                await siaAPIget(process.env.REACT_APP_SIAREST_API_GETPRESENTI, { day: moment(value).format("YYYY-MM-DD") }).then(siaUsersList => {
                    setSheetState({ ...sheetState, [field.name]: moment(value).format("YYYY-MM-DD"), isLoadingConsulentiPresentiList: false });
                    
                    siaUsersList.forEach(u => {
                        // Dall'id del manager carico il nome completo del manager per i menu a tendina
                        var manager = siaUsersList.filter(f => f.idUserSF == u.idManager);
                        if(manager.length > 0)
                        {
                            u.managerFullName = `${manager[0].lastName} ${manager[0].firstName}`
                        }
                    });

                    updateSIAUsersLists(dispatch, siaUsersList ?? [], moment(value).format("YYYY-MM-DD"));
                })
                    .catch(e => {
                        console.log(e)
                        setSheetState({ ...sheetState, isLoadingConsulentiPresentiList: false });
                    });
            }
        }
        else {
            setSheetState({ ...sheetState, [field.name]: moment(value).format("YYYY-MM-DD") });
        }
    }
    const handleExtDropDownOnChange = (item) => {
        if (field.name === "Consulente") {
            setSelectedValue(item);
            setSheetState({ ...sheetState, [field.name]: item, TeamManager: { value: item.idAccount, label: item.accountName } })
        }
        else {
            setSelectedValue(item);
            setSheetState({ ...sheetState, [field.name]: item })
        }
    }
    const onBoolChangeHandle = (optionSelected) => {
        if (field.name === "Privacy") {
            if (field.isMandatory) //ed � obbligatorio
            {
                //sheetTotalRatePercent
                var sheetTotalRatePercent = calculateSheetTotalRatePercent(sheet, sheetState.sheetSubjectsAndQuestions, optionSelected.value);
                //sheetPlayerRange
                var sheetPlayerRange = calculateSheetPlayerRange(sheet, sheetTotalRatePercent);
                //sheetTotalRate
                var sheetTotalRate = calculateSheetTotalRate(sheet, sheetState.sheetSubjectsAndQuestions, optionSelected.value);
                //sheetPlayerRangeColor
                var sheetPlayerRangeColor = getSheetPlayerRangeColor(sheet, sheetPlayerRange);
                //sheetPlayerRangeColor
                var sheetPlayerRangeDescription = calculateSheetPlayerRangeDescription(sheet, sheetTotalRatePercent);

                setSheetState({
                    ...sheetState, [field.name]: parseInt(optionSelected.value, 10),
                    sheetTotalRatePercent: sheetTotalRatePercent,
                    sheetTotalRate: sheetTotalRate,
                    sheetPlayerRangeColor: { backgroundColor: sheetPlayerRangeColor },
                    sheetPlayerRange: sheetPlayerRange,
                    sheetPlayerRangeDescription: sheetPlayerRangeDescription
                });
            }
            else {
                setSheetState({ ...sheetState, [field.name]: parseInt(optionSelected.value, 10) });
            }
        }
        else {
            setSheetState({ ...sheetState, [field.name]: parseInt(optionSelected.value, 10) });
        }
    }
    useEffect(() => {
        if (sheetState[field.name] !== undefined && field.name === "DataConsulenza") {
            onDateChangeHandle(sheetState[field.name]);
        }
    }, [props.readOnly]);

    switch (field.fieldType) {

        case 'dateinput':
            return (
                <div className="col-12 col-sm-6 col-md-3 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div>
                            <label htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(props.readOnly)
                            ?
                            <div>{(sheetState[field.name] !== undefined) ? moment(sheetState[field.name]).format("DD/MM/YYYY") : null}</div>
                            :
                            <DatePicker
                                id={field.name}
                                name={field.name}
                                className={"form-control text-center search-input-field " + (((sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") && field.name === "DataConsulenza") ? "highlight-data-consulenza" : "")}
                                dateFormat="dd/MM/yyyy"
                                selected={(sheetState[field.name] !== undefined) ? new Date(sheetState[field.name]) : null}
                                onChange={(value) => onDateChangeHandle(value)}
                                autoComplete="off"
                                disabled={props.readOnly || ((sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") && field.name !== "DataConsulenza") ? true : false}
                                minDate={(field.name === "DataAscolto" && sheetState.DataConsulenza !== undefined && sheetState.DataConsulenza !== null && sheetState.DataConsulenza !== "") ? new Date(sheetState.DataConsulenza) : null}
                            />
                        }
                    </div>
                </div>)

        case 'ext_dropdown':
            var optionsValues = [];
            switch (field.name) {
                case 'Consulente':
                    optionsValues = valutaStore.consulentiPresentiList //solo i presenti
                    break;
                case 'Quality':
                    optionsValues = valutaStore.qualityConContrattoAttivoList
                    break;
                case 'TeamManager':
                    optionsValues = valutaStore.teamManagerConContrattoAttivoList
                    break;
                case 'Valutatore':
                    optionsValues = valutaStore.valutatoreConContrattoAttivoList
                    break;
            }
            return (
                <div className="col-12 col-sm-6 col-md-3 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div className="LabelContainer">
                            <label className="LabelContainer" htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(sheetState.isLoadingConsulentiPresentiList && field.name === "Consulente") ?
                            <Loading small={true} message="Carico presenti..." />
                            :
                            <div className="d-flex flex-row align-items-center justify-content-start">
                                {
                                    (sheetState[field.name] !== undefined) ?
                                    <AsyncImage data={sheetState[field.name].value} className='FieldPic profilePicLittle mr-2' /> : null
                                }
                                {(props.readOnly) ?
                                    <div>{(sheetState[field.name] !== undefined) ? sheetState[field.name].label : null}</div>
                                    :
                                    <Select
                                        id={field.name}
                                        name={field.name}
                                        className="search-input-field"
                                        styles={customDropDownStyles}
                                        value={sheetState[field.name] !== undefined ? sheetState[field.name] : null}
                                        options={optionsValues}
                                        classNamePrefix="select"
                                        onChange={(item) => handleExtDropDownOnChange(item)}
                                        isDisabled={props.readOnly || (sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") ? true : false}
                                    />}
                            </div>
                        }
                    </div>
                </div>)

        case 'dropdown':
            return (
                <div className="col-12 col-sm-6 col-md-3 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div>
                            <label htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(props.readOnly) ?
                            <div>{(sheetState[field.name] !== undefined) ? sheetState[field.name].label : null}</div>
                            :
                            <Select
                                id={field.name}
                                name={field.name}
                                className="search-input-field"
                                styles={customDropDownStyles}
                                value={selectedValue}
                                options={field.values}
                                classNamePrefix="select"
                                onChange={(item) => { setSheetState({ ...sheetState, [field.name]: item }); setSelectedValue(item); }}
                                isDisabled={props.readOnly || (sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") ? true : false}
                                value={sheetState[field.name] !== undefined ? sheetState[field.name] : null}
                            />
                        }
                    </div>
                </div>)

        case 'bool':
            return (
                <div className="col-12 col-sm-6 col-md-3 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div>
                            <label htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(props.readOnly) ?
                            <div>{sheetState[field.name] !== undefined ? (sheetState[field.name] === 1) ? "SI" : "NO" : sheetState[field.name]}</div>
                            :
                            <Select
                                id={field.name}
                                name={field.name}
                                styles={customDropDownStyles}
                                options={[{ "value": "", "label": "-" }, { "value": "1", "label": "SI" }, { "value": "0", "label": "NO" }]}
                                classNamePrefix="select"
                                onChange={(item) => onBoolChangeHandle(item)}
                                isDisabled={props.readOnly || (sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") ? true : false}
                                value={sheetState[field.name] !== undefined ? { label: (sheetState[field.name] === 1) ? "SI" : "NO", value: sheetState[field.name] } : null}
                            />
                        }
                    </div>
                </div>)

        case 'textarea':
            return (
                <div className="col-12 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div>
                            <label htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(props.readOnly) ?
                            <div>{sheetState[field.name] !== undefined && sheetState[field.name] !== "" ? sheetState[field.name] : "-"}</div>
                            :
                            <textarea
                                className="form-control sheet-question-text"
                                id={field.name}
                                name={field.name}
                                rows="3"
                                onChange={(item) => setSheetState({ ...sheetState, [item.target.id]: item.target.value })}
                                disabled={props.readOnly || (sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") ? true : false}
                                value={sheetState[field.name] !== undefined ? sheetState[field.name] : null}
                            ></textarea>
                        }
                    </div>
                </div>)

        default:
            return (
                <div className="col-12 col-sm-6 col-md-3 d-flex flex-row justify-content-start">
                    <div className="form-group w-100 d-flex flex-column flex-wrap align-items-center align-items-sm-start">
                        <div>
                            <label htmlFor={field.name}><b>{field.label}</b></label> {(field.isMandatory === true) ? <span className="mandatoryField">*</span> : ''}
                        </div>
                        {(props.readOnly) ?
                            <div>{(sheetState[field.name] !== undefined) ? sheetState[field.name] : null}</div>
                            :
                            <input
                                type="text"
                                className="form-control sheet-question-text search-input-field"
                                id={field.name}
                                name={field.name}
                                onChange={(item) => setSheetState({ ...sheetState, [item.target.id]: item.target.value })}
                                disabled={props.readOnly || (sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "") ? true : false}
                                value={sheetState[field.name] !== undefined ? sheetState[field.name] : null}
                            />
                        }
                    </div>
                </div>)
    }
}

export default RenderField;