import React from 'react';
import SheetSubjectQuestions from './SheetSubjectQuestions';

const SheetSubjectSubSections = (props) => {

    var subject = props.subject;
    var sheet = props.sheet;
    return sheet.subjects.filter(s => s.idParentSubject === subject.idSheetSubject).map((item, is) =>
        (item.questions !== null && item.questions.length > 0) ?
            <React.Fragment key={is}>
                <div className="ml-2 mt-4 mb-4" style={{fontWeight:'600'}}>{item.subject}</div>
                <SheetSubjectQuestions isEditing={props.isEditing} readOnly={props.readOnly} sheet={sheet} subject={item} setQuestionRate={props.setQuestionRate} idParentSubject={subject.idSheetSubject} setQuestionNote={props.setQuestionNote} />
            </React.Fragment>
            :
            <div>Questa sotto-sezione risulta non avere domande.</div>
    )
}
export default SheetSubjectSubSections;