import React from "react";
import { trackException } from '../AppInsights';
const AccessDenied = () => {

    var adalError = window.localStorage.getItem("adal.error") ?? "Spiacenti, accesso non consentito.";
    var adalLoginError = window.localStorage.getItem("adal.login.error") ?? "Si sono verificati errori durante l'accesso.";
    trackException(adalError + " " + adalLoginError);

    return <div className="d-flex flex-column justify-content-center align-items-center mt-5 p-5">
        <h5 className="text-danger">Accesso Negato</h5>
        <div className="text-danger text-center mt-3" style={{fontSize:'0.9rem'}}>Si sono verificati errori durante l'accesso.</div>
    </div>
}

export default AccessDenied;