import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ReactHtmlParser from 'react-html-parser';
import SheetContext from './SheetContext';
import { getVoteOptions } from '../Tools';
import { PlusCircleFill } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';

const SheetSubjectQuestions = (props) => {

    const customDropDownStyles = {
        container: provided => ({
            ...provided,
            width: 90
        })
    };
    var sheet = props.sheet;
    var voteOptions = getVoteOptions(sheet);
    const [sheetState, setSheetState] = useContext(SheetContext);
    const [questionNotes, setQuestionNotes] = useState([]);
    var idParentSubject = (props.idParentSubject === undefined) ? props.subject.idSheetSubject : props.idParentSubject;
    var qCounter = (sheet.sheetWeightType === "pesoSezione") ? 1 : props.qCounter;
    const [selectedQuestionRates, setSelectedQuestionRates] = useState([]);
    const updateSelectedQuestionRates = (idSubjectQuestion, value) => {
        var tempValues = [...selectedQuestionRates];
        tempValues[idSubjectQuestion] = value;
        setSelectedQuestionRates(tempValues);
    }
    const addNote = (idSubjectQuestion) => {
        var div = document.getElementById("Note_Div_" + idSubjectQuestion);
        if (div.classList.contains('d-none')) {
            document.getElementById("Note_Div_" + idSubjectQuestion).classList.remove("d-none");
            document.getElementById("Note_Div_" + idSubjectQuestion).classList.add("d-flex");
        }
        else {
            document.getElementById("Note_Div_" + idSubjectQuestion).classList.remove("d-flex");
            document.getElementById("Note_Div_" + idSubjectQuestion).classList.add("d-none");
        }
    }
    const setNotesList = (idSheetSubject, q, idParentSubject) => {
        var tmp = questionNotes.filter(n => n.idSubjectQuestion === q.idSubjectQuestion);
        var value = document.getElementById("Note_" + q.idSubjectQuestion).value;
        if (tmp.length > 0) {
            tmp.forEach((note) => {
                note.Note = value;
            });
        }
        else {
            tmp.push({ idSubjectQuestion: q.idSubjectQuestion, Note: value });
        }
        setQuestionNotes(tmp);
        props.setQuestionNote(idSheetSubject, q, value, idParentSubject);
    }
    return (props.subject.questions !== null && props.subject.questions.length > 0 && sheetState.sheetSubjectsAndQuestions) ?
        props.subject.questions.map((q, iq) => {

            var QuestionNote = questionNotes.filter(n => n.idSubjectQuestion === q.idSubjectQuestion)?.Note;
            var selectedValue = null;
            var QuestionSubject = sheetState.sheetSubjectsAndQuestions?.filter(sq => sq.idSheetSubject === props.subject.idSheetSubject);
            var itemWeight = (sheet.sheetWeightType === "pesoItem") ? QuestionSubject[0]?.EvaluationSubjectWeight : null;
            if (props.isEditing) {
                var QuestionRate = QuestionSubject[0]?.Questions?.filter(qst => qst.IdSubjectQuestion === q.idSubjectQuestion)[0]?.QuestionRate;
                QuestionNote = QuestionSubject[0]?.Questions?.filter(qst => qst.IdSubjectQuestion === q.idSubjectQuestion)[0]?.QuestionNote;
                if (sheet.sheetType === "yes-no") {
                    selectedValue = (QuestionRate !== undefined) ? ((QuestionRate === 0) ? { value: QuestionRate, label: 'NO' } : { value: QuestionRate, label: 'SI' }) : selectedQuestionRates[q.idSubjectQuestion];
                }
                else {
                    selectedValue = (QuestionRate !== undefined) ? { value: QuestionRate, label: (QuestionRate === 0) ? "n.d." : QuestionRate } : selectedQuestionRates[q.idSubjectQuestion];
                }
            }


            return <React.Fragment key={iq}>
                <div className="row sheet-question-container d-flex flex-row align-items-center SubjectQuestion">
                    <span className="sheet-question-number">{qCounter++}</span>
                    <div className="col-9 col-xs-10 text-dark sheet-question-text">
                        {ReactHtmlParser(q.question)}
                        {(q.isMandatory) ? <span className="mandatoryField"> *</span> : null}
                        {(sheet.sheetWeightType === "pesoItem" && itemWeight !== null) ? <span className="sheet-item-weight"> ({itemWeight}%)</span> : null}
                        <div id={"Note_Div_" + q.idSubjectQuestion} className={((QuestionNote !== "" && QuestionNote !== undefined && QuestionNote !== null) ? "d-flex" : "d-none") + " flex-row align-items-center mt-1"}>
                            <div className="mr-1 sheet-question-note-label">Nota:</div>
                            {(props.readOnly) ?
                                <div className="sheet-question-note-text">{QuestionNote}</div>
                                :
                                <input
                                    onChange={(e) => setNotesList(props.subject.idSheetSubject, q, idParentSubject)}
                                    className="form-control sheet-question-text sheet-note-input"
                                    type="text"
                                    value={QuestionNote}
                                    id={"Note_" + q.idSubjectQuestion}
                                    name="Note"
                                />
                            }

                        </div>
                    </div>
                    <div className="col-3 col-xs-2 text-dark sheet-question-text d-flex flex-row justify-content-end align-items-center">
                        {(props.readOnly) ?
                            <div className={((selectedValue !== null && selectedValue.label !== undefined && selectedValue.label !== null && selectedValue.label !== "n.d.") ? "sheet-readonly-vote qVote" : "sheet-readonly-vote-nd qVote")}>
                                {(selectedValue !== null && selectedValue.label !== undefined && selectedValue.label !== null) ?
                                    (selectedValue.label === "SI") ?
                                        <span className="text-success qText">{selectedValue.label}</span>
                                        :
                                        (selectedValue.label === "NO") ?
                                            <span className="text-danger qText">{selectedValue.label}</span>
                                            :
                                            <span className="qText">{selectedValue.label}</span>
                                    :
                                    "-"
                                }
                            </div>
                            :
                            <React.Fragment>
                                <Select
                                    id={"voto_question_" + q.idSubjectQuestion}
                                    styles={customDropDownStyles}
                                    name={"voto_question_" + q.idSubjectQuestion}
                                    options={(!q.isMandatory) ? voteOptions : (sheet.sheetType === "yes-no") ? [...voteOptions] : [...voteOptions].splice(1, sheet.maxValue)}
                                    placeholder=""
                                    isSearchable={false}
                                    onChange={(value) => { props.setQuestionRate(props.subject.idSheetSubject, q, value, idParentSubject); updateSelectedQuestionRates(q.idSubjectQuestion, value) }}
                                    isDisabled={sheetState.DataConsulenza === undefined || sheetState.DataConsulenza === null || sheetState.DataConsulenza === "" ? true : false}
                                    value={(selectedValue !== null) ? selectedValue : selectedQuestionRates[q.idSubjectQuestion]}
                                />
                                {(sheetState.DataConsulenza !== undefined && sheetState.DataConsulenza !== null && sheetState.DataConsulenza) ?
                                    <PlusCircleFill data-tip={(QuestionNote === "") ? "Aggiungi nota" : "Nascondi nota"} className="sheet-add-question-note" onClick={() => addNote(q.idSubjectQuestion)} />
                                    : null}
                                <ReactTooltip />
                            </React.Fragment>
                        }
                    </div >
                </div >
            </React.Fragment>
        }
        )
        :
        null;
}
export default SheetSubjectQuestions;