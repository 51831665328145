import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Fragment } from 'react';
const Loading = (props) => {
    
    var message = (props.message !== "" && props.message != null && props.message !== undefined) ? props.message : "Loading...";

    return (
        (!props.small) ?
            <div className="text-center ">
                {
                    !props.icon &&
                    <Fragment>
                        <div className="spinner-border mt-5 text-center" role="status">
                            <span className="sr-only">{message}</span>
                        </div>
                        <div className="mt-3">{message}</div>
                    </Fragment>
                }
                {
                    props.icon &&
                    <Fragment>
                        <div className="mt-5 text-center" role="status">
                            <FontAwesomeIcon icon={faExclamationTriangle} size={'2x'} />
                        </div>
                        <div className="mt-3">{message}</div>
                    </Fragment>
                }
                
            </div>
            :
            <div className="text-left">
                <div style={{ width: '25px', height: '25px', fontSize: '0.7rem', marginTop: '5px' }} className="spinner-border" role="status"></div><span style={{fontSize:'0.75rem', paddingLeft:'5px'}}>{message}</span>
            </div>
    )
}
export default Loading;