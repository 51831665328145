import { Link } from "react-router-dom";
import '../css/Header.css';
import Logo from '../img/logo.png';
import LogoConsulcesi from '../img/logo2023.png';
import { getCookieRole, getCookieUserFullName } from '../AdalConfig';

const Header = (props) => {

    const cookieUserRole = getCookieRole();

    return (
        <div className="d-flex flex-row flex-wrap p-2">
            <div className="d-flex flex-grow-1 justify-content-center justify-content-md-start">
                <a href="/">
                    <img src={Logo} alt="HomePage Valuta" />
                </a>
            </div>
            <div className="d-flex flex-grow-1 justify-content-center">
                <a href="/">
                    <img src={LogoConsulcesi} alt="Logo Consulcesi" className="logoConsulcesi" />
                </a>
            </div>
            <div className="d-flex flex-grow-1 justify-content-center justify-content-md-end align-items-center mt-3 mt-md-0">
                <Link to="/" className="valuta-menu-link">VALUTAZIONI</Link>
                {(cookieUserRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_ADMIN ||
                    cookieUserRole === process.env.REACT_APP_VALUTA_USER_APPLICATION_ROLE_MANAGER)
                    ?
                    <Link to="/manager/sheets" className="valuta-menu-link">MANAGER</Link>
                    :
                    null
                }
                <b className="valuta-menu-link text-info"> - {getCookieUserFullName().toUpperCase()} ({cookieUserRole}) - </b>
            </div>
        </div>
    )
}
export default Header;