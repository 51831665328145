import React, { useState, useContext, useEffect } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import MultiSlider from './Slider/MultiSlider';
import { Switch } from 'pretty-checkbox-react';

export default function SheetGeneralInfoBuilder() {
    const { sheetName, sheetService, sheetType, sheetWeightType, services, sheetStati, setSheetStati, changeSheetName, changeSheetType, changeSheetWeightType, changeSheetService, changeSheetMaxValue, updateSheet, sheetMaxValue, playerEvaluationColors, playerEvaluationLabels, playerEvaluationValues, setPlayerEvaluationLabels, setPlayerEvaluationValues, setPlayerEvaluationColors, setPlayerRangeDescriptions, setPlayerRangeColors, playerRangeDescriptions } = useContext(CreateSheetContext);
    const [sheetLocalService, setSheetLocalService] = useState(sheetService)
    const [newLabel, setNewLabel] = useState('')
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    useEffect(() => {
        // console.log("Updated");
        if (updateSheet.startsWith('reset_')) {
            /**
             * TODO: Handle sheet reset
             */
        }

        return () => {
        }
    }, [updateSheet, playerEvaluationLabels, playerEvaluationValues])

    /**
     * 
     * Cambio dei valori Nome Scheda e Servizio Scheda
     * Da considerare cambio di approccio, scrive troppe volte nel context. Si potrebbero inerire bottoni Salva/Annulla 
     */

    function handleNameChange(newName) {
        // setSheetLocalName(newName)
        changeSheetName(newName)
    }
    function handleTypeChange(newType) {
        // setSheetLocalType(newType)
        changeSheetType(newType)
    }
    function handleWeightTypeChange(newWeightType) {
        // setSheetLocalWeightType(newWeightType)
        changeSheetWeightType(newWeightType)
    }
    function handleServiceChange(newService) {
        // setSheetLocalService(newService)
        changeSheetService(newService)
    }
    function handleMaxValueChange(newMaxValue) {
        // setSheetLocalMaxValue(newMaxValue)
        changeSheetMaxValue(newMaxValue)
    }
    function handleSwitchStatiChange(switchStatiChecked) {
        setSheetStati(switchStatiChecked)
        if (switchStatiChecked) {
            setPlayerEvaluationLabels(["A", "A2", "B1.1", "B1.2", "B2.1", "B2.2", "C1.1", "C1.2", "C2.1", "C2.2"])
            setPlayerEvaluationValues([40, 50, 54, 60, 70, 80, 85, 90, 95])
            setPlayerEvaluationColors(["#FF0000", "#FF5050", "#FF9999", "#D9E1F2", "#D9E1F2", "#E2EFDA", "#C6E0B4", "#A9D08E", "#548235", "#375623"])

            setPlayerRangeDescriptions({ "A": "A", "A2": "A2", "B1.1": "B1.1", "B1.2": "B1.2", "B2.1": "B2.1", "B2.2": "B2.2", "C1.1": "C1.1", "C1.2": "C1.2", "C2.1": "C2.1", "C2.2": "C2.2" })
            setPlayerRangeColors({ "A": "FF0000", "A2": "FF5050", "B1.1": "FF9999", "B1.2": "D9E1F2", "B2.1": "D9E1F2", "B2.2": "E2EFDA", "C1.1": "C6E0B4", "C1.2": "A9D08E", "C2.1": "548235", "C2.2": "375623" })
        }
        else {
            setPlayerEvaluationLabels(["C", "B", "A"])
            setPlayerEvaluationValues([33, 66])
            setPlayerEvaluationColors(["#dc3545", "#ffc107", "#28a745"])

            setPlayerRangeDescriptions({ "A": "Adeguato", "B": "Parzialmente adeguato", "C": "Non adeguato" })
            setPlayerRangeColors({ "A": "61d875", "B": "f8c846", "C": "e85454" })
        }
    }

    return (
        <React.Fragment>
            <h5 className="ml-1 mb-3 mt-4">Sezione 1 - Impostazioni Generali Scheda</h5>
            <div className="p-4 ManagerSectionContainer">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <h6 className="ManagerFieldLabels pl-2">Nome scheda</h6>
                        <input
                            name="sheetName"
                            type="text"
                            placeholder="Nome scheda"
                            className="form-control text-center search-input-field sheetNameInput"
                            onChange={(e) => handleNameChange(capitalize(e.target.value))}
                            value={sheetName} />
                    </div>
                    <div className="col-12 col-sm-6 col-md-2">
                        <h6 className="ManagerFieldLabels pl-2">Servizio</h6>
                        <select name="sheetService" value={JSON.stringify(sheetService)} className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside addNewElement" onChange={(e) => handleServiceChange(JSON.parse(e.target.value))} >
                            <option defaultValue value={{}}>Scegli il servizio...</option>
                            {
                                services.length > 0 && services
                                    .map(_singleService => {
                                        return <option key={_singleService.serviceName} value={JSON.stringify(_singleService)}>{_singleService.serviceName}</option>
                                    })
                            }
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-2">
                        <h6 className="ManagerFieldLabels pl-2">Tipo voto</h6>
                        <select name="sheetType" value={sheetType} className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside addNewElement" onChange={(e) => handleTypeChange(e.target.value)} >
                            <option defaultValue value='classic'>Voto 1-N</option>
                            {(sheetWeightType!=='pesoSezione')  && 
                                < option value='yes-no'>Voto SI-NO</option>
                            }
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-2">
                        <h6 className="ManagerFieldLabels pl-2">Tipo peso</h6>
                        <select name="sheetWeightType" value={sheetWeightType} className="form-control text-center search-input-field react-datepicker-ignore-onclickoutside addNewElement" onChange={(e) => handleWeightTypeChange(e.target.value)} >
                            <option defaultValue value='pesoSezione'>Peso Sezione</option>
                            <option value='pesoItem'>Peso Item</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-2">
                        <h6 className="ManagerFieldLabels pl-2">Punt. Max Domanda</h6>
                        <input className='form-control text-center search-input-field addNewElement' type='number' min='1' max='5' value={sheetMaxValue} onKeyUp={(e) => { if (e.key === 'Enter') { handleMaxValueChange(parseInt(e.target.value)) } }} onChange={(e) => handleMaxValueChange(parseInt(e.target.value))} disabled={sheetType==='yes-no'} />
                    </div>
                </div>
                <div className="row mt-3 p-1">
                    <h6 className="col-12 text-center text-md-left mt-3">Valutazione Player</h6>
                        <div className="col-12">
                                3 stati <Switch className="switchStati" checked={sheetStati} onChange={(e) => handleSwitchStatiChange(e.target.checked)}/> 10 stati
                        </div>
                    <div className="col-12 pl-4 mt-5 mb-4">
                        <MultiSlider
                            labels={playerEvaluationLabels}
                            values={playerEvaluationValues}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <div className="centerContent" style={{ color: 'white' }}>
                            <div className="d-flex flex-row justify-content-between mb-2">
                                {
                                    playerEvaluationLabels.map((_singleEvaluationLabel, index) => {
                                        return (
                                            <div className="d-flex flex-column flex-grow-1  justify-content-center align-items-center" style={{flexBasis:'100%'}}>
                                                <div className="d-flex flex-row justify-content-center flex-grow-1">
                                                    <div
                                                        key={_singleEvaluationLabel}
                                                        className="ManagerRangeBalls"
                                                        style={{ backgroundColor: playerEvaluationColors[index] }}>
                                                        {_singleEvaluationLabel}
                                                    </div>
                                                </div>
                                                <div className="text-dark mt-1"><b>{playerRangeDescriptions[_singleEvaluationLabel]}</b></div>
                                                <div className="text-dark">
                                                    {(index === 0) ?
                                                        <div>0-{playerEvaluationValues[index]}</div>
                                                        :
                                                        (index < (playerEvaluationLabels.length - 1)) ?
                                                            ((playerEvaluationValues[index - 1] + 1) - (playerEvaluationValues[index])) > 0 ? `-` : `${playerEvaluationValues[index - 1] + 1} - ${playerEvaluationValues[index]}`
                                                            :
                                                            <div>{playerEvaluationValues[playerEvaluationValues.length - 1] + 1} - 100</div>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}