import React from 'react';
import RenderField from './RenderField';
const SheetFiledsFooter = (props) => {

    var sheet = props.sheet;
    return (sheet.fields && sheet.fields !== undefined && sheet.fields !== null) ? 
        (sheet.fields.filter(f => f.position === 'footer').length>0) ?
        <div id="SheetFiledsFooter" className="row sheet-footer-container pt-3">
            {sheet.fields.filter(f => f.position === 'footer').map((field, fieldIndex) =>
                <RenderField readOnly={props.readOnly} key={fieldIndex} field={field} sheet={sheet} />
            )}
            </div>
            : null
        :
        <div>Impossibile caricare i campi del footer della scheda.</div>
}
export default SheetFiledsFooter;