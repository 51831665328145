import 'core-js';
import 'isomorphic-fetch';
import { runWithAdal } from 'react-adal';
import { authContext } from './AdalConfig';
//require('es7-object-polyfill');

/*
const DO_NOT_LOGIN = false;
 
runWithAdal(authContext, () => {

    require('./indexApp.js');

}, DO_NOT_LOGIN);
*/


const loginError = authContext.getLoginError();
const loginWasTriedButFailed = loginError !== undefined && loginError !== null && loginError !== "";
const redirectIfNoUser = !loginWasTriedButFailed;

runWithAdal(
    authContext,
    () => {
        if (loginWasTriedButFailed)
        {
            require('./indexDenied.js');
        }
        else
        {
            require('./indexApp.js');
        }
    },
    !redirectIfNoUser
);