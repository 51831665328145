import React, { useState } from 'react';
import '../css/Header.css';
import Logo from '../img/logo.png';
import Loading from './Loading';
import { setCookieRole, getCookieUserFullName } from '../AdalConfig';
const ChooseRole = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const chooseRole = (role) => {
        setIsLoading(true);
        setCookieRole(role);
        window.location.href ="/";
    }

    return (isLoading) ?
        <Loading />
        :
        <React.Fragment>
            <div className="d-flex flex-row justify-content-center mt-3 p-2">
                <a href="/"><img src={Logo} alt="HomePage Valuta" /></a>
            </div>
            <div className="d-flex flex-column align-items-center">
                    <div className="mt-3"><h5>Scegli il ruolo</h5></div>
                    <div className="mb-3">Ciao {getCookieUserFullName()}, per questo applicativo hai {props.roles.length} profili a disposizione, scegli quale utilizzare.</div>
                    {(props.roles.map((role, index) =>
                        <div className="" key={index}><button className="btn btn-info" onClick={()=>chooseRole(role)}>{role}</button></div>
                    ))}
            </div>
        </React.Fragment>


}

export default ChooseRole;