import SheetContext from './SheetContext';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import AvailableSheets from './AvailableSheets';
import Sheet from './Sheet';

const Evaluation = (props) => {

    const { id } = useParams();
    const [sheetState, setSheetState] = useState([]);

    return (id === null || id === undefined || isNaN(id)) ?
        <AvailableSheets />
        :
        <SheetContext.Provider value={[sheetState, setSheetState]}>
            <Sheet id={id} isEditing={false} />
        </SheetContext.Provider>
}

export default Evaluation;