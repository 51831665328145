import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CreateSheetContext } from './CreateSheetContext'
import '../../../css/Index.css';
import { FileEarmarkCheck, FileEarmarkX } from 'react-bootstrap-icons';

export default function SheetActionButtons() {
    const { abortSheet, saveSheet, clearFlashMessage } = useContext(CreateSheetContext)
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    async function handleSaveClose() {
        if (!isLoading) { 
        setIsLoading(true);
            const sheet = await saveSheet();
            if (sheet && sheet.idSheet) {
                setTimeout(() => {
                    clearFlashMessage()
                    history.push(`/manager/sheets`)
                }, 3000);
            }
            else {
                setIsLoading(false);
            }
        }
    }
    function handleAbort() {
        abortSheet()
        /**
         * TODO: Show a flash message to confirm the abort
         */
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <h5 className="text-center text-md-left">Crea nuova scheda</h5>
                    <p className="text-center text-md-left">Ricordati di inserire tutti i campi necessari all'interno delle 4 sezioni.</p>
                </div>

                <div className="col-12 col-md-4 d-flex flex-row align-items-end justify-content-center justify-content-md-end buttonGroupSubject">

                    <a className="sheet-action-buttons d-flex flex-row align-items-center mr-2"
                        href="javascript:void(0)"
                        onClick={() => handleSaveClose()}>
                        <FileEarmarkCheck
                            className="mr-1 sheet-action-icons text-success" /> Salva e chiudi</a>

                    <a className="sheet-action-buttons d-flex flex-row align-items-center"
                        href="javascript:void(0)"
                        disabled={isLoading}
                        onClick={() => handleAbort()}>
                        <FileEarmarkX
                            className="mr-1 sheet-action-icons text-danger" /> Annulla Modifiche</a>

                </div>
            </div>
        </div>
    )
}
