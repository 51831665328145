import React, { useContext, useState, useEffect, Fragment } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import './CommonSheetBuilder.css';
import SheetFieldBuilder from './SheetFieldBuilder';
import CreatableSelect from 'react-select/creatable';
import { Pencil, Check2Circle, CheckAll } from 'react-bootstrap-icons';
import { capitalize, parseBool } from '../../../helpers/helpers'

export default function SheetHeaderBuilder(props) {
    const { headerFields, header, footer, saveHeader, updateSheet } = useContext(CreateSheetContext);

    const [selectedHeaderFields, setSelectedHeaderFields] = useState(header || headerFields.filter(_singleHeaderField => _singleHeaderField.onAllSheets === true))
    const [addingNewField, setAddingNewField] = useState(false)
    const [selectedFieldToAdd, setSelectedFieldToAdd] = useState(JSON.stringify({}))
    const [selectedFieldAvailableValues, setSelectedFieldAvailableValues] = useState([])
    const [selectedFieldValuesToAdd, setSelectedFieldValuesToAdd] = useState(selectedFieldAvailableValues)
    const [customLabel, setCustomLabel] = useState("")
    const [fieldIsMandatory, setFieldIsMandatory] = useState(true)
    const todayDate = new Date();
    useEffect(() => {
        if (updateSheet.startsWith('reset_')) {
            setSelectedHeaderFields([])
        }

        setSelectedHeaderFields(props.isCloning ? header : headerFields.filter(_singleHeaderField => _singleHeaderField.onAllSheets === true))
        if (!props.isCloning) { saveHeader(selectedHeaderFields) }
        return () => {

        }
    }, [headerFields, updateSheet, header])
    function deleteField(_fieldName) {
        if (_fieldName === "TipoRiferimentoCRM" || _fieldName === "RiferimentoCRM") {
            setSelectedHeaderFields(selectedHeaderFields.filter(_singleHeaderField => { return _singleHeaderField.fieldName !== "TipoRiferimentoCRM" && _singleHeaderField.fieldName !== "RiferimentoCRM" }))
        }else if(_fieldName === "DaTsOc" || _fieldName === "RiferimentoTs"){
            setSelectedHeaderFields(selectedHeaderFields.filter(_singleHeaderField => { return _singleHeaderField.fieldName !== "DaTsOc" && _singleHeaderField.fieldName !== "RiferimentoTs" }))
        } else {
            setSelectedHeaderFields(selectedHeaderFields.filter(_singleHeaderField => { return _singleHeaderField.fieldName !== _fieldName }))
        }
    }
    function handleAddToSelectedFields() {
        let abort = false
        if (selectedHeaderFields.length < 1) {
            abort = true
        }
        selectedHeaderFields.forEach(element => {
            if (!selectedFieldToAdd.fieldName || selectedFieldToAdd.fieldName === element.fieldName) {
                abort = true
            }
        });

        if (!abort) {
            const fieldToAdd = selectedFieldToAdd;
            if (customLabel) {
                fieldToAdd.fieldCustomLabel = customLabel
            }
            fieldToAdd.isMandatory = fieldIsMandatory
            const checkIfAll = selectedFieldValuesToAdd.every(_singleSelected => selectedFieldAvailableValues.map(_singleAvailable => _singleAvailable.value).includes(_singleSelected.value)) && selectedFieldValuesToAdd.length === selectedFieldAvailableValues.length
            fieldToAdd.fieldCustomValues = selectedFieldValuesToAdd
            if (!checkIfAll) {
                fieldToAdd.edited = true
            } else {
                fieldToAdd.edited = false
            }
            // console.log(fieldIsMandatory);

            // console.log(...headerFields.filter(_s => _s.fieldName === "TipoRiferimentoCRM"))
            // console.log(fieldToAdd);

            if (fieldToAdd.fieldName === "TipoRiferimentoCRM") {
                setSelectedHeaderFields([...selectedHeaderFields, fieldToAdd, ...headerFields.filter(_s => _s.fieldName === "RiferimentoCRM")])
            } else if (fieldToAdd.fieldName === "RiferimentoCRM") {
                setSelectedHeaderFields([...selectedHeaderFields, ...headerFields.filter(_s => _s.fieldName === "TipoRiferimentoCRM"), fieldToAdd])
            } else if (fieldToAdd.fieldName === "DaTsOc") {
                setSelectedHeaderFields([...selectedHeaderFields, fieldToAdd, ...headerFields.filter(_s => _s.fieldName === "RiferimentoTs")])
            } else if (fieldToAdd.fieldName === "RiferimentoTs") {
                setSelectedHeaderFields([...selectedHeaderFields, ...headerFields.filter(_s => _s.fieldName === "DaTsOc"), fieldToAdd])
            } else {
                setSelectedHeaderFields([...selectedHeaderFields, fieldToAdd])
            }
            setSelectedFieldToAdd(JSON.stringify({}))
            setCustomLabel("")
            setSelectedFieldValuesToAdd([])

        }


    }
    const getDropDownValues = (selectedField) => {

        const availableDropDownsValues = selectedField.dropDownValue.map(_singleDropDown => {
            return { value: _singleDropDown.dropDownVal, label: _singleDropDown.dropDownLabel }
        })

        setSelectedFieldAvailableValues(availableDropDownsValues)
        setSelectedFieldValuesToAdd(availableDropDownsValues)
    }


    return (
        <React.Fragment>
            <h5 className="ml-1 mb-3 mt-4">Sezione 2 - Campi dell'header della scheda</h5>
            <div className="p-4 ManagerSectionContainer">
                <div className="row">
                    {
                        selectedHeaderFields.map(_singleHeaderField => {

                            return <SheetFieldBuilder
                                deleteField={deleteField}
                                addingNewField={addingNewField}
                                key={_singleHeaderField.fieldName}
                                field={_singleHeaderField}
                                startDate={todayDate}
                                maxDate={todayDate}
                            />
                        })
                    }
                    <div className='col-12 headerField text-center'>
                        {
                            !addingNewField &&
                            <div className="d-flex flex-row justify-content-end">
                                <a className="ManagerBtns" onClick={() => setAddingNewField(true)}><Pencil /> Modifica campi header scheda </a>
                            </div>
                        }
                        {
                            addingNewField &&
                            <Fragment>
                                <div className="row ManagerSubSectionContainer addNewField">
                                    <h6 className="col-12 text-center mb-4 mt-2"><b>Aggiungi nuovo campo</b></h6>


                                    <div className="col-12 col-md-4">
                                        <h6 className="ManagerFieldLabels">Tipologia Campo</h6>
                                        <select value={JSON.stringify(selectedFieldToAdd)}
                                            className="col-4 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector"
                                            onChange={(e) => { setSelectedFieldValuesToAdd([]); getDropDownValues(JSON.parse(e.target.value)); setSelectedFieldToAdd(JSON.parse(e.target.value)) }} >
                                            <option defaultValue value={{}}>Scegli il campo da aggiungere...</option>
                                            {
                                                headerFields
                                                    .filter(_singleHeaderField => _singleHeaderField.onAllSheets === false)
                                                    .filter(_singleHeaderField => !(selectedHeaderFields.map(_singleSelected => _singleSelected.fieldName).includes(_singleHeaderField.fieldName)) && !(footer.map(_singleSelected => _singleSelected.fieldName).includes(_singleHeaderField.fieldName)))
                                                    .map(_singleHeaderField => {
                                                        return <option key={_singleHeaderField.idField} value={JSON.stringify(_singleHeaderField)}>{_singleHeaderField.fieldLabel}</option>
                                                    })
                                            }
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 col-md-4">
                                        <h6 className="ManagerFieldLabels">Risp. obbligatoria</h6>
                                        <select className="col-4 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector" name='fieldIsMandatory' onChange={(e) => { setFieldIsMandatory(parseBool(e.target.value)) }} value={fieldIsMandatory}>
                                            <option value={true}>SI</option>
                                            <option value={false}>NO</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-8 col-md-4">
                                        <h6 className="ManagerFieldLabels">Etichetta personalizzata</h6>
                                        <input
                                            type="text"
                                            placeholder="(opzionale)"
                                            className="col-4 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector"
                                            onChange={(e) => setCustomLabel(capitalize(e.target.value))}
                                            value={customLabel} />
                                    </div>
                                    {
                                        // selectedFieldAvailableValues.length > 0 && selectedFieldToAdd.fieldName !== "TipoRiferimentoCRM" &&
                                        selectedFieldToAdd.fieldType === "dropdown" && selectedFieldToAdd.fieldName !== "TipoRiferimentoCRM" && selectedFieldToAdd.fieldName !== "DaTsOc"  &&
                                        <div className="col-12 mt-4">
                                            <h6 className="ManagerFieldLabels mb-3">Scelta Elementi Lista</h6>
                                            <CreatableSelect
                                                isMulti
                                                menuPlacement={'top'}
                                                onChange={setSelectedFieldValuesToAdd}
                                                options={selectedFieldAvailableValues}
                                                value={selectedFieldValuesToAdd}
                                                className={'serviceMultiple'}
                                                classNamePrefix={'serviceMultiple'}
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                    }
                                    {(selectedFieldToAdd !== undefined && selectedFieldToAdd?.fieldLabel !== undefined) ?
                                        <div className="col-12 mt-4 text-center">
                                            <div className="buttonGroup addNewFiledButtons">
                                                <a className={"ManagerBtns"} onClick={() => handleAddToSelectedFields()}>
                                                    <Check2Circle className="ManagerBtnIcons" /> Conferma aggiunta campo <b>{selectedFieldToAdd.fieldLabel}</b></a>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <a className="ManagerBtns stopEditing"
                                        onClick={() => { saveHeader(selectedHeaderFields); setAddingNewField(false) }}>
                                        <CheckAll className="ManagerBtnIcons" /> Conferma modifiche header scheda</a>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
