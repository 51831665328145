import { combineReducers } from 'redux';
import { filterUserList, filterEvaluationsUserList } from '../Tools';

const defaultStore = {
    appConfig: {},
    userData: {},
    consulenteList: [],
    qualityList: [],
    teamManagerList: [],
    valutatoreList: [],
    consulentiPresentiList: [],
    qualityConContrattoAttivoList: [],
    teamManagerConContrattoAttivoList: [],
    valutatoreConContrattoAttivoList: [],
    flashMessage: {}
};

//Actions
export const updateAppConfig = async (dispatch, newData) => dispatch({
    type: 'UPDATE_APPCONFIG',
    newData
});

export const updateUserData = async (dispatch, newData) => dispatch({
    type: 'UPDATE_USERDATA',
    newData
});

export const updateEvaluationsUserList = async (dispatch, newData) => dispatch({
    type: 'UPDATE_EVALUATIONSUSERLIST',
    newData
});

export const updateSIAUsersLists = async (dispatch, newData, date) => dispatch({
    type: 'UPDATE_SIAUSERSLISTS',
    newData,
    date
});

export const updateFlashMessage = async (dispatch, newData) => dispatch({
    type: 'UPDATE_FLASHMESSAGE',
    newData
})

//Reducer
function valutaStore(state = defaultStore, action) {
    var newState = { ...state };
    switch (action.type) {
        case 'UPDATE_USERDATA':
            newState.userData = action.newData;
            return newState;
        case 'UPDATE_APPCONFIG':
            newState.appConfig = action.newData;
            return newState;
        case 'UPDATE_SIAUSERSLISTS':
            var arrayFiledsToFilter = ["consulentiPresenti", "quality", "valutatore", "teamManager"];
            arrayFiledsToFilter.forEach((item) => {
                var optionsValues = filterUserList(action.newData, item, action.date);
                if (item === "consulentiPresenti") {
                    newState.consulentiPresentiList = optionsValues;
                }
                else {
                    newState[item + 'ConContrattoAttivoList'] = optionsValues;
                }
            })
            return newState;
        case 'UPDATE_EVALUATIONSUSERLIST':
            var arrayFiledsToFilter = ["consulente", "quality", "valutatore", "teamManager"];
            sessionStorage.setItem('evaluationsUserList', JSON.stringify(action.newData));
            newState.evaluationsUserList = action.newData;
            arrayFiledsToFilter.forEach((item) => {
                var optionsValues = filterEvaluationsUserList(action.newData, item);
                newState[item + 'List'] = optionsValues;
            })
            return newState;
        case 'UPDATE_FLASHMESSAGE':
            newState.flashMessage = action.newData;
            return newState;
        default:
            return state;
    }
}

//Combine
const valutaAppStore = combineReducers({
    valutaStore
});

export default valutaAppStore;