import React from 'react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';

const Error = (props) => {
    var errors = (props.errors !== "" && props.errors != null && props.errors !== undefined && props.errors.length > 0) ? props.errors : [];
    if (errors.length > 0) {
        return (
            <div className="text-center text-danger mt-4">
                <h1 className="text-danger"><ExclamationCircleFill /></h1>
                {errors.map((e,i) => <div className="mt-3" key={i}>{e}</div>)}
            </div>
        )
    }  
}
export default Error;