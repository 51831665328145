import React, { useEffect, useState } from 'react';
import EvaluationPreview from './EvaluationPreview';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { valutaAPIget } from '../FetchApi';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { Fragment } from 'react';
import Loading from './Loading';
import { getDivToggleStatus, toggleDiv } from '../Tools';

const RecentEvaluations = (props) => {
    const [recentsEvaluations, setRecentsEvaluations] = useState([]);
    const [recentToggleStatus, setRecentToggleStatus] = useState(getDivToggleStatus("recent-evaluations-container"));
    const limit = 24;
    const toggleRecent = () => {
        var toggleResult = toggleDiv("recent-evaluations-container");
        setRecentToggleStatus(toggleResult);
    }

    const responsive = {
        xxxxlarge: {
            breakpoint: { max: 2500, min: 1900 },
            items: 10,
            slidesToSlide: 8
        },
        xxxlarge: {
            breakpoint: { max: 1900, min: 1600 },
            items: 7,
            slidesToSlide: 8
        },
        xxlarge: {
            breakpoint: { max: 1600, min: 1400 },
            items: 6,
            slidesToSlide: 8
        },
        xlarge: {
            breakpoint: { max: 1400, min: 1200 },
            items: 5,
            slidesToSlide: 8
        },
        large: {
          breakpoint: { max: 1200, min: 992 },
          items: 4,
          slidesToSlide: 8
        },
        medium: {
          breakpoint: { max: 992, min: 768 },
          items: 3,
          slidesToSlide: 3
        },
        small: {
          breakpoint: { max: 768, min: 576 },
          items: 2,
          slidesToSlide: 2
        },
        xsmall: {
          breakpoint: { max: 576, min: 0 },
          items: 1
        }
      };
    useEffect(async () => {
        setRecentsEvaluations(await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_EVALUATIONS_RECENTS, { limit }))
        return () => {
            setRecentsEvaluations([])
        }
    }, [])

    return (
        <div>
            <div className="d-flex flex-row justify-content-start align-items-start">
                <h5>Valutazioni Recenti</h5>
                <div className="ml-2">
                    {(recentToggleStatus === "d-block") ?
                        <EyeSlash className="valuta-btn-hide" data-tip="Nascondi" onClick={() => toggleRecent()} />
                        :
                        <Eye className="valuta-btn-hide" data-tip="Mostra" onClick={() => toggleRecent()} />
                    }

                </div>
            </div>
            <div id="recent-evaluations-container" className={"mb-3 "+recentToggleStatus}>
                <p>Elenco delle ultime schede compilate</p>
                {
                    recentsEvaluations === undefined || recentsEvaluations?.length < 1
                        ? <p>Non ci sono valutazioni recenti.</p>
                        : <Fragment>
                            <Carousel
                                responsive={responsive}
                                itemClass="carousel-item-padding"
                            >
                                {
                                    recentsEvaluations.length > 0 &&
                                    recentsEvaluations.map((_singleRecentEvaluation,index) => {
                                        return <EvaluationPreview key={index} evaluation={_singleRecentEvaluation} />
                                    })
                                }

                            </Carousel>
                        </Fragment>
                }
            </div>


        </div>
    )
}
export default RecentEvaluations;