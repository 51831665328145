import React, { useContext, useState, useEffect, Fragment } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import './CommonSheetBuilder.css';
import SheetFieldBuilder from './SheetFieldBuilder';
import CreatableSelect from 'react-select/creatable';
import { Pencil, Check2Circle, CheckAll } from 'react-bootstrap-icons';
import { capitalize, parseBool, compareOrder } from '../../../helpers/helpers'

export default function SheetFooterBuilder(props) {
    const { footerFields, saveFooter, updateSheet, header, footer } = useContext(CreateSheetContext);
    const [selectedFooterFields, setSelectedFooterFields] = useState(footer || [])
    const [addingNewField, setAddingNewField] = useState(false)
    const [selectedFieldToAdd, setSelectedFieldToAdd] = useState({})
    const [selectedFieldValuesToAdd, setSelectedFieldValuesToAdd] = useState([])
    const [selectedFieldAvailableValues, setSelectedFieldAvailableValues] = useState([])
    const [customLabel, setCustomLabel] = useState("")
    const [fieldIsMandatory, setFieldIsMandatory] = useState(true)
    const todayDate = new Date();
    useEffect(() => {
        if (updateSheet.startsWith('reset_')) {
            setSelectedFooterFields([])
        }
        setSelectedFooterFields(props.isCloning ? footer : [])
        if (!props.isCloning) { saveFooter(selectedFooterFields) }

        return () => {

        }
    }, [updateSheet, footer])
    function deleteField(_fieldName) {
        if (_fieldName === "TipoRiferimentoCRM" || _fieldName === "RiferimentoCRM") {
            setSelectedFooterFields(selectedFooterFields.filter(_singleFooterField => { return _singleFooterField.fieldName !== "TipoRiferimentoCRM" && _singleFooterField.fieldName !== "RiferimentoCRM" }))
        }else if(_fieldName === "DaTsOc" || _fieldName === "RiferimentoTs"){
            setSelectedFooterFields(selectedFooterFields.filter(_singleFooterField => { return _singleFooterField.fieldName !== "DaTsOc" && _singleFooterField.fieldName !== "RiferimentoTs" }))
        } else {
            setSelectedFooterFields(selectedFooterFields.filter(_singleFooterField => { return _singleFooterField.fieldName !== _fieldName }))
        }    }
    function handleAddToSelectedFields() {
        let abort = false
        selectedFooterFields.forEach(element => {
            if (!selectedFieldToAdd.fieldName || selectedFieldToAdd.fieldName === element.fieldName) {
                abort = true
            }
        });
        if (!abort) {
            const fieldToAdd = selectedFieldToAdd;
            if (customLabel) {
                fieldToAdd.fieldCustomLabel = customLabel
            }
            fieldToAdd.isMandatory = fieldIsMandatory
            // fieldToAdd.selectedValues
            const checkIfAll = selectedFieldValuesToAdd.every(_singleSelected => selectedFieldAvailableValues.map(_singleAvailable => _singleAvailable.value).includes(_singleSelected.value)) && selectedFieldValuesToAdd.length === selectedFieldAvailableValues.length
            fieldToAdd.fieldCustomValues = selectedFieldValuesToAdd
            if (!checkIfAll) {
                fieldToAdd.edited = true
            } else {
                fieldToAdd.edited = false

            }
            if (fieldToAdd.fieldName === "TipoRiferimentoCRM") {
                setSelectedFooterFields([...selectedFooterFields, fieldToAdd, ...footerFields.filter(_s => _s.fieldName === "RiferimentoCRM")])
            } else if (fieldToAdd.fieldName === "RiferimentoCRM") {
                setSelectedFooterFields([...selectedFooterFields, ...footerFields.filter(_s => _s.fieldName === "TipoRiferimentoCRM"), fieldToAdd])
            } else if (fieldToAdd.fieldName === "DaTsOc") {
                setSelectedFooterFields([...selectedFooterFields, fieldToAdd, ...footerFields.filter(_s => _s.fieldName === "RiferimentoTs")])
            } else if (fieldToAdd.fieldName === "RiferimentoTs") {
                setSelectedFooterFields([...selectedFooterFields, ...footerFields.filter(_s => _s.fieldName === "DaTsOc"), fieldToAdd])
            } else {
                setSelectedFooterFields([...selectedFooterFields, fieldToAdd])
            }
            setSelectedFieldToAdd(JSON.stringify({}))
            setCustomLabel("")
            setSelectedFieldValuesToAdd([])
        }


    }
    const getDropDownValues = (selectedField) => {
        const availableDropDownsValues = selectedField.dropDownValue.map(_singleDropDown => {
            return { value: _singleDropDown.dropDownVal, label: _singleDropDown.dropDownLabel }
        })
        setSelectedFieldAvailableValues(availableDropDownsValues)
        setSelectedFieldValuesToAdd(availableDropDownsValues)
    }

    return (
        <div className="mb-5">
            <h5 className="ml-1 mb-3 mt-4">Sezione 4 - Campi del footer della scheda</h5>
            <div className="p-4 ManagerSectionContainer">
                <div className="row">
                    {
                        selectedFooterFields.sort(compareOrder).map(_singleFooterField => {
                            return <SheetFieldBuilder
                                deleteField={deleteField}
                                addingNewField={addingNewField}
                                key={_singleFooterField.fieldName}
                                field={_singleFooterField}
                                startDate={todayDate}
                                maxDate={todayDate} />
                        })
                    }
                    <div className='col-12 footerField addMoreContainer'>
                        {
                            !addingNewField &&
                            <div className="d-flex flex-row justify-content-end">
                                <a className="ManagerBtns" onClick={() => setAddingNewField(true)}><Pencil /> Modifica campi footer scheda </a>
                            </div>
                        }
                        {
                            addingNewField &&
                            <Fragment>
                                <div className="row ManagerSubSectionContainer addNewField">
                                    <h6 className="col-12 text-center mb-4 mt-2"><b>Aggiungi nuovo campo</b></h6>


                                    <div className="col-12 col-md-4">
                                        <h6 className="ManagerFieldLabels">Tipologia Campo</h6>
                                        <select value={JSON.stringify(selectedFieldToAdd)} className="col-3 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector" onChange={(e) => { setSelectedFieldValuesToAdd([]); getDropDownValues(JSON.parse(e.target.value)); setSelectedFieldToAdd(JSON.parse(e.target.value)) }} >
                                            <option defaultValue value={{}}>Scegli il campo da aggiungere...</option>
                                            {
                                                footerFields
                                                    .filter(_singleFooterField => _singleFooterField.onAllSheets === false)
                                                    .filter(_singleFooterField => !(selectedFooterFields.map(_singleSelected => _singleSelected.fieldName).includes(_singleFooterField.fieldName)) && !(header.map(_singleSelected => _singleSelected.fieldName).includes(_singleFooterField.fieldName)))
                                                    .map(_singleFooterField => {
                                                        return <option key={_singleFooterField.idField} value={JSON.stringify(_singleFooterField)}>{_singleFooterField.fieldLabel}</option>
                                                    })
                                            }
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 col-md-4">
                                        <h6 className="ManagerFieldLabels">Risp. obbligatoria</h6>
                                        <select className="col-4 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector" name='fieldIsMandatory' onChange={(e) => { setFieldIsMandatory(parseBool(e.target.value)) }} value={fieldIsMandatory}>
                                            <option value={true}>SI</option>
                                            <option value={false}>NO</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-8 col-md-4">
                                        <h6 className="ManagerFieldLabels">Etichetta personalizzata</h6>
                                        <input
                                            type="text"
                                            placeholder="(opzionale)"
                                            className="col-4 form-control text-center search-input-field react-datepicker-ignore-onclickoutside serviceSelector"
                                            onChange={(e) => setCustomLabel(capitalize(e.target.value))}
                                            value={customLabel} />
                                    </div>
                                    {
                                        selectedFieldToAdd.fieldType === "dropdown" && selectedFieldToAdd.fieldName !== "TipoRiferimentoCRM" && selectedFieldToAdd.fieldName !== "DaTsOc"  &&
                                        <div className="col-12 mt-4">
                                            <h6 className="ManagerFieldLabels">Scelta Elementi Lista</h6>

                                            <CreatableSelect
                                                isMulti
                                                menuPlacement={'top'}
                                                onChange={setSelectedFieldValuesToAdd}
                                                options={selectedFieldAvailableValues}
                                                value={selectedFieldValuesToAdd}
                                                className={'serviceMultiple'}
                                                classNamePrefix={'serviceMultiple'}
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                    }

                                    {(selectedFieldToAdd !== undefined && selectedFieldToAdd?.fieldLabel !== undefined) ?
                                        <div className="col-12 mt-4  text-center">
                                            <div className="buttonGroup addNewFiledButtons">
                                                <a className={"ManagerBtns"} onClick={() => handleAddToSelectedFields()}>
                                                    <Check2Circle className="ManagerBtnIcons" /> Conferma aggiunta campo <b>{selectedFieldToAdd.fieldLabel}</b></a>
                                            </div>
                                        </div>
                                        : null}

                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <a className="ManagerBtns stopEditing"
                                        onClick={() => { saveFooter(selectedFooterFields); setAddingNewField(false) }}>
                                        <CheckAll className="ManagerBtnIcons" /> Conferma modifiche footer scheda</a>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}