export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const parseBool = (boolInput)=>{
  return boolInput==='false'?false:true
}

export function compareOrder( a, b ) {
  if ( a.Order < b.Order ){
    return -1;
  }
  if ( a.Order > b.Order ){
    return 1;
  }
  return 0;
}

export function compareSubsectionsOrder( a, b ) {
  if ( a.subsectionId < b.subsectionId ){
    return -1;
  }
  if ( a.subsectionId > b.subsectionId ){
    return 1;
  }
  return 0;
}

export function compareDescentRangeOrder( a, b ) {
  if ( a.idPlayerRange > b.idPlayerRange ){
    return -1;
  }
  if ( a.idPlayerRange < b.idPlayerRange ){
    return 1;
  }
  return 0;
}

export function compareRangeOrderByLabelReverse(a, b) {
    if (a.rangeMinValue > b.rangeMinValue) {
        return 1;
    }
    if (a.rangeMinValue < b.rangeMinValue) {
        return -1;
    }
    return 0;
}

export function compareRangeOrderByLabel( a, b ) {
    if (a.rangeMinValue < b.rangeMinValue ){
    return 1;
  }
    if (a.rangeMinValue > b.rangeMinValue ){
    return -1;
  }
  return 0;
}

export function compareFieldsOrderByFieldName( a, b ) {
  if ( a.fieldName < b.fieldName ){
    return -1;
  }
  if ( a.fieldName > b.fieldName ){
    return 1;
  }
  return 0;
}

export function orderByCustomKey(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];
    let comparison = 0;
    if(!varA && !varB || varA === varB) {
      return comparison
    } else if (!varB || varA > varB) {
      comparison = 1;
    } else if (!varA || varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}