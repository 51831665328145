import React, { useContext } from 'react';
import SheetContext from './SheetContext';
import { calculateSubjectPlayerRange, getCriticitaColor, getSheetPlayerRangeColor } from '../Tools';

const SheetSubjectHeader = (props) => {

    var sheet = props.sheet;
    var subject = props.subject;
    const [sheetState, setSheetState] = useContext(SheetContext);

    var EvaluationSubjectRatePercent = (sheetState.sheetSubjectsAndQuestions !== null && sheetState.sheetSubjectsAndQuestions !== undefined && sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0] !== undefined) ? sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0].EvaluationSubjectRatePercent : null;
    var EvaluationSubjectMaxRate = (sheetState.sheetSubjectsAndQuestions !== null && sheetState.sheetSubjectsAndQuestions !== undefined && sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0] !== undefined) ? sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0].EvaluationSubjectMaxRate : null;
    var EvaluationSubjectRate = (sheetState.sheetSubjectsAndQuestions !== null && sheetState.sheetSubjectsAndQuestions !== undefined && sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0] !== undefined) ? sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0].EvaluationSubjectRate : null;
    var SubjectPlayerRange = calculateSubjectPlayerRange(sheet, EvaluationSubjectRatePercent);
    var SubjectRangeColor = sheet.idSheet === 7 ? { backgroundColor: getCriticitaColor((EvaluationSubjectRatePercent !== null && EvaluationSubjectRatePercent !== "" && EvaluationSubjectRatePercent !== undefined) ? EvaluationSubjectRatePercent.toFixed(2) : 0)} : { backgroundColor: getSheetPlayerRangeColor(sheet, SubjectPlayerRange) };
    //var RangeColor = sheet.playerRanges.filter(r => r.rangeLabel === SubjectPlayerRange)?.rangeColor;
    // console.log(sheetState)
    return (
        <div className="row">
            <div className={"col-12 d-flex flex-row flex-wrap justify-content-center pb-1 " + ((sheet.sheetWeightType === "pesoSezione") ? "justify-content-md-between" : "justify-content-md-end")}>
                {(sheet.sheetWeightType === "pesoSezione") ?

                    <div className="d-flex flex-row justify-content-emd align-items-center mr-1">
                        <h6 style={{ fontWeight: '900' }} className="subjectHeader">
                            {subject.subject}
                            <span className="sheet-subject-weight ml-1 subjectWeight">({subject.weight}%)</span>
                        </h6>
                    </div>
                    :
                    null}

                <div className="d-flex flex-row justify-content-end pb-1">
                    {
                        (sheetState.sheetSubjectsAndQuestions !== undefined && sheetState.sheetSubjectsAndQuestions.filter(ssq => ssq.idSheetSubject === subject.idSheetSubject)[0] !== undefined) ?
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                {(EvaluationSubjectMaxRate !== null && EvaluationSubjectMaxRate !== "" && EvaluationSubjectMaxRate !== undefined) ?
                                    <React.Fragment>
                                        <div className="sheet-evaluation-subject-rate mr-1">
                                            <b>P.ti: </b> <span className="badge bagde-pti mr-1">{(EvaluationSubjectRate !== null && EvaluationSubjectRate !== "" && EvaluationSubjectRate !== undefined) ? EvaluationSubjectRate.toFixed(2) + "/" : ""}
                                                <span className="text-warning">{(EvaluationSubjectMaxRate !== null && EvaluationSubjectMaxRate !== "" && EvaluationSubjectMaxRate !== undefined) ? EvaluationSubjectMaxRate.toFixed(2) + " " : ""}</span></span>
                                            <b>Criticità: </b> <span className="badge bagde-criticita" style={SubjectRangeColor}>{(EvaluationSubjectRatePercent !== null && EvaluationSubjectRatePercent !== "" && EvaluationSubjectRatePercent !== undefined) ? EvaluationSubjectRatePercent.toFixed(2) + "%" : ""}</span>
                                        </div>
                                        {(sheet.idSheet !== 7) ?
                                            <div className="PlayerRangeCircleLittle" style={SubjectRangeColor}>
                                                {SubjectPlayerRange}
                                            </div>
                                            : null}
                                    </React.Fragment>
                                    : null}
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>)

}
export default SheetSubjectHeader;