import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { valutaAPIget } from '../FetchApi';
import Loading from './Loading';
import moment from 'moment';

const AvailableSheets = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [sheetsList, setSheetsList] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        (async function doAsync() {
            await valutaAPIget(process.env.REACT_APP_VALUTA_API_CLEAR_CACHE_SHEET, null)
            await setSheetsList(await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEETS_LIST, { active: true }));
            setIsLoading(false);
        })()

    }, []);

    return (!isLoading) ?
        <div className="container-fluid">
            <h5 className="mt-4 mb-3">Seleziona una scheda</h5>
            <div className="row mb-2 header-sheet-list">
                <div className="d-none d-md-flex col-md-1"><b>ID</b></div>
                <div className="col-12 col-sm-6 col-md-5"><b>Nome</b></div>
                <div className="d-none d-sm-flex col-6 col-md-4"><b>Servizio</b></div>
                <div className="d-none d-md-flex col-md-2"><b>Creazione</b></div>
            </div>
            {(sheetsList !== undefined && sheetsList.length > 0) ?
                sheetsList.map((item, index) =>
                    <div className="row mt-1 mb-1 header-sheet-list-row" key={index}>
                        <div className="d-none d-md-flex col-md-1">#{item.idSheet}</div>
                        <div className="col-12 col-sm-6 col-md-5"><Link to={"/evaluation/" + item.idSheet}> {item.sheetName}</Link></div>
                        <div className="d-none d-sm-flex col-6 col-md-4">{item.serviceName}</div>
                        <div className="d-none d-md-flex col-md-2">{moment(item.created).format("DD/MM/YYYY")}</div>
                    </div>
                )
                :
                <div className="text-danger">Non sono presenti schede da compilare.</div>
            }
        </div>
        : <Loading message="Carico le schede..." />;
}
export default AvailableSheets;