import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFlashMessage } from '../store/store';
import '../css/FlashMessages.css';


export default function FlashMessageContainer() {
  const dispatch = useDispatch();
  const flashMessage = useSelector(state => state.valutaStore.flashMessage);
  const clearFlashMessage = () => {
    updateFlashMessage(dispatch,{})
  }
  useEffect(() => {
    return () => {
    }
  }, [flashMessage])
  return (
    <Fragment>
    {
      flashMessage && flashMessage.flashType &&
      <div className="flashMessageContainer">
        {/* <FlashMessage duration={15000}> */}
          <div className={`alert alert-${flashMessage.flashType} alert-dismissible fade show`} role="alert">
            <strong>{flashMessage.title}</strong> {flashMessage.content}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>{clearFlashMessage()}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        {/* </FlashMessage>   */}
      </div>
    }
    </Fragment>
    
    
  )
}
