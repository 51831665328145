import React, { useContext } from 'react';
import SheetContext from './SheetContext';
import SheetSubjectQuestions from './SheetSubjectQuestions';
import SheetSubjectSubSections from './SheetSubjectSubSections';
import SheetSubjectHeader from './SheetSubjectHeader';

import {
    calculateSheetPlayerRange,
    calculateSheetTotalRatePercent,
    calculateSubjectPlayerRange,
    calculateSheetPlayerRangeDescription,
    calculateEvaluationSubjectMaxRate,
    calculateSheetMaxRate,
    calculateSheetTotalRate,
    calculateSheetSubjectsVotesAvg,
    getSheetPlayerRangeColor
} from '../Tools';

const SheetSubjects = (props) => {

    var sheet = props.sheet;
    const [sheetState, setSheetState] = useContext(SheetContext);
    const setQuestionNote = async (idSheetSubject, q, value, idParentSubject) => {

        var sheetSubjectsAndQuestions = [...sheetState.sheetSubjectsAndQuestions];
        var subjectToUpdate = (idSheetSubject === idParentSubject) ? idSheetSubject : idParentSubject;
        var subject = sheetSubjectsAndQuestions.filter(s => (s?.idSheetSubject || s?.IdSheetSubject) === subjectToUpdate)[0];
        var allQuestions = [];

        if (idSheetSubject === idParentSubject) {
            //QuestionNote memorizzo la nota alla domanda nella sezione
            subject.Questions.filter(qs => (qs?.idSubjectQuestion || qs?.IdSubjectQuestion) === q.idSubjectQuestion)[0].QuestionNote = value;
            //Creo un elenco di tutte le domande presenti nella sezione
            allQuestions = subject.Questions;

        }
        else {
            var sottosezioni = sheetSubjectsAndQuestions.filter(s => s.idParentSubject === idParentSubject);
            //console.log(sheetSubjectsAndQuestions)
            sottosezioni.forEach((subject) => {
                var QuestionToUpdate = subject.Questions.filter(qs => (qs?.idSubjectQuestion || qs?.IdSubjectQuestion) === q.idSubjectQuestion);
                if (QuestionToUpdate.length > 0) {
                    QuestionToUpdate[0].QuestionNote = value;
                }
                //Creo un elenco di tutte le domande presenti nelle sottosezioni
                subject.Questions.forEach(q => allQuestions.push(q));
            })
        }
        setSheetState({
            ...sheetState,
            sheetSubjectsAndQuestions: sheetSubjectsAndQuestions
        });
    }
    const setQuestionRate = async (idSheetSubject, q, optionSelected, idParentSubject) => {

        var sheetSubjectsAndQuestions = [...sheetState.sheetSubjectsAndQuestions];
        var subjectToUpdate = (idSheetSubject === idParentSubject) ? idSheetSubject : idParentSubject;
        var subject = sheetSubjectsAndQuestions.filter(s => (s?.idSheetSubject || s?.IdSheetSubject) === subjectToUpdate)[0];
        var allQuestions = [];

        if (idSheetSubject === idParentSubject) {
            //QuestionRate memorizzo il voto alla domanda nella sezione
            subject.Questions.filter(qs => (qs?.idSubjectQuestion || qs?.IdSubjectQuestion) === q.idSubjectQuestion)[0].QuestionRate = optionSelected.value;
            //Creo un elenco di tutte le domande presenti nella sezione
            allQuestions = subject.Questions;

        }
        else {
            var sottosezioni = sheetSubjectsAndQuestions.filter(s => s.idParentSubject === idParentSubject);
            //console.log(sheetSubjectsAndQuestions)
            sottosezioni.forEach((subject) => {
                var QuestionToUpdate = subject.Questions.filter(qs => (qs?.idSubjectQuestion || qs?.IdSubjectQuestion) === q.idSubjectQuestion);
                if (QuestionToUpdate.length > 0) {
                    QuestionToUpdate[0].QuestionRate = optionSelected.value;
                }
                //Creo un elenco di tutte le domande presenti nelle sottosezioni
                subject.Questions.forEach(q => allQuestions.push(q));
            })
        }
        //EvaluationSubjectMaxRate
        subject.EvaluationSubjectMaxRate = calculateEvaluationSubjectMaxRate(sheet, allQuestions);
        //console.log("EvaluationSubjectMaxRate="+subject.EvaluationSubjectMaxRate)

        //EvaluationSubjectRate & EvaluationSubjectAvg & EvaluationSubjectRatePercent
        subject.EvaluationSubjectRate = 0;
        subject.EvaluationSubjectRatePercent = 0;
        subject.EvaluationSubjectAvg = 0;
        var sumSubjectQuestionRates = 0;
        var numQuestionRates = 0;
        allQuestions.forEach(qItem => {
            if (qItem.QuestionRate !== null && qItem.QuestionRate !== "") {

                if (sheet.sheetType === "yes-no") {
                    sumSubjectQuestionRates += qItem.QuestionRate;
                    numQuestionRates++;
                }
                else {
                    if (qItem.QuestionRate > 0) {
                        sumSubjectQuestionRates += qItem.QuestionRate;
                        numQuestionRates++;
                    }
                }

            }
        })
        subject.EvaluationSubjectAvg = (sumSubjectQuestionRates === 0 && numQuestionRates === 0) ? 0 : sumSubjectQuestionRates / numQuestionRates;

        //TODO: RICALCOLARE
        var RealEvaluationSubjectRate = (sumSubjectQuestionRates * subject.EvaluationSubjectWeight) / 100;
        subject.EvaluationSubjectRate = Math.round((RealEvaluationSubjectRate + Number.EPSILON) * 10) / 10 //rounded 2 decimals

        var RealEvaluationSubjectRatePercent = ((subject.EvaluationSubjectAvg) / sheet.maxValue) * 100;
        subject.EvaluationSubjectRatePercent = Math.round((RealEvaluationSubjectRatePercent + Number.EPSILON) * 10) / 10 //rounded 2 decimals

        //console.log("EvaluationSubjectAvg=" + subject.EvaluationSubjectAvg)
        //console.log("EvaluationSubjectRate=" + subject.EvaluationSubjectRate)
        //console.log("EvaluationSubjectRatePercent=" + subject.EvaluationSubjectRatePercent)


        //PlayerRange
        subject.PlayerRange = calculateSubjectPlayerRange(sheet, subject.EvaluationSubjectRatePercent);
        //console.log("PlayerRange=" + subject.PlayerRange)


        //sheetTotalRatePercent
        var privacy = (sheetState.Privacy === null || sheetState.Privacy === undefined || sheetState.Privacy === "" || isNaN(sheetState.Privacy)) ? 0 : sheetState.Privacy
        var sheetTotalRatePercent = calculateSheetTotalRatePercent(sheet, sheetSubjectsAndQuestions, privacy);
        //console.log("sheetTotalRatePercent=" + sheetTotalRatePercent)


        //sheetPlayerRange
        var sheetPlayerRange = calculateSheetPlayerRange(sheet, sheetTotalRatePercent);
        //console.log("sheetPlayerRange=" + sheetPlayerRange)

        //sheetPlayerRangeColor
        var sheetPlayerRangeColor = getSheetPlayerRangeColor(sheet, sheetPlayerRange);
        //console.log("sheetPlayerRangeColor=" + sheetPlayerRangeColor)


        //sheetPlayerRangeDescription
        var sheetPlayerRangeDescription = calculateSheetPlayerRangeDescription(sheet, sheetTotalRatePercent);
        //console.log("sheetPlayerRangeDescription=" + sheetPlayerRangeDescription)


        // sheetMaxRate
        var sheetMaxRate = calculateSheetMaxRate(sheet, sheetSubjectsAndQuestions);
        //console.log("sheetMaxRate=" + sheetMaxRate)

        //sheetTotalRate
        var sheetTotalRate = calculateSheetTotalRate(sheet, sheetSubjectsAndQuestions, privacy);
        //console.log("sheetTotalRate=" + sheetTotalRate)

        //sheetSubjectsVotesAvg
        var sheetSubjectsVotesAvg = calculateSheetSubjectsVotesAvg(sheet, sheetSubjectsAndQuestions);
        //console.log("sheetSubjectsVotesAvg=" + sheetSubjectsVotesAvg)

        //aggiorno lo sheetState
        setSheetState({
            ...sheetState,
            sheetSubjectsAndQuestions: sheetSubjectsAndQuestions,
            sheetTotalRatePercent: sheetTotalRatePercent,
            sheetPlayerRange: sheetPlayerRange,
            sheetPlayerRangeDescription: sheetPlayerRangeDescription,
            sheetMaxRate: sheetMaxRate,
            sheetSubjectsVotesAvg: sheetSubjectsVotesAvg,
            sheetPlayerRangeColor: { backgroundColor: sheetPlayerRangeColor },
            sheetTotalRate: sheetTotalRate
        });

    }
    var qCounter = 1;

    return (sheet.subjects !== null && sheet.subjects !== undefined && sheet.subjects.length > 0) ?
        //sheet.subjects.filter(s => s.idParentSubject === null || s.idParentSubject === 0).map((item, is) => {
        sheet.subjects.filter(s => s.idParentSubject === null || s.idParentSubject === 0).sort((a, b) => { return a.ordering < b.ordering ? -1 : 1 }).map((item, is) => {        
            return (
                <div className={"row sheet-subject-container SheetSubjects"} key={is} style={{ paddingTop: (sheet.sheetWeightType === "pesoSezione") ? '1.1rem' : '0.3rem' }}>
                    <div className="col-12">
                        <SheetSubjectHeader sheet={sheet} subject={item} />
                        {(sheet.sheetWeightType === "pesoSezione") ? <hr /> : null}
                        {(item.questions !== null && item.questions.length > 0) ?
                            <SheetSubjectQuestions readOnly={props.readOnly} isEditing={props.isEditing} sheet={sheet} subject={item} setQuestionRate={setQuestionRate} setQuestionNote={setQuestionNote} qCounter={(sheet.sheetWeightType === "pesoItem") ? qCounter++ : null} />
                            :
                            (sheet.subjects.filter(sub => sub.idSheetSubject === item.idSheetSubject).length > 0) ?
                                <SheetSubjectSubSections readOnly={props.readOnly} isEditing={props.isEditing} sheet={sheet} subject={item} setQuestionRate={setQuestionRate} setQuestionNote={setQuestionNote} />
                                :
                                <div>Questa sezione risulta non avere domande o sottosezioni.</div>
                        }
                    </div>
                </div>
            )
        }
        )
        :
        <div>Questa scheda risulta non avere sezioni/domande.</div>
}
export default SheetSubjects;