import React from 'react'
import moment from 'moment';
import { getSheetPlayerRangeColor, upperFirstLetter } from '../Tools';
import AsyncImage from './AsyncImage';
import DEFAULTPIC from '../img/pic.png';



export default function EvaluationPreview(props) {

    const dateFormat = 'DD/MM/YYYY';
    const addDefaultSrc = (ev) => {
        ev.target.src = DEFAULTPIC;
    }

    var allPlayerRanges = JSON.parse(window.sessionStorage.getItem("valuta.allPlayerRanges"));
    var SheetPlayerRangeColor = getSheetPlayerRangeColor({ playerRanges: (allPlayerRanges !== null && allPlayerRanges !== undefined) ? allPlayerRanges : [] }, props.evaluation.sheetPlayerRange);

    return (

        <div className="previewGeneralContainer" >

            <div className="d-flex flex-column align-items-center">
                <AsyncImage data={props.evaluation.consulente.value} className='previewProfilePic' alt={props.evaluation.consulente.label} />
                <div className="recent-ev-assistant-name">{props.evaluation.consulente.label}</div>
                <div className="recent-ev-activity">{(props.evaluation.attivita.label !== undefined && props.evaluation.attivita.label !== null) ? upperFirstLetter(props.evaluation.attivita.label) : upperFirstLetter(props.evaluation.sheetName)}</div>
            </div>

            <div className="previewButtonContainer mb-2">
                <span type="button" className="recentEvaluationButton" onClick={() => { window.open(`/evaluation/view/${props.evaluation.idEvaluation}`, '_blank').focus() }} >Apri</span>
            </div>

            <div className="previewDetailsContainer">
                <div className="row col-12">
                    {(props.evaluation.quality.label !== null && props.evaluation.quality.label !== undefined && props.evaluation.quality.label !== "") ?
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <AsyncImage data={props.evaluation.quality.value} className='ml-3 previewDetailProfilePic' alt='' />
                            <div className="d-flex flex-column align-items-start justify-items-center ml-2">
                                <div className="evaluationDetailsRole">Quality</div>
                                <div className="evaluationDetailsName">{props.evaluation.quality.label}</div>
                            </div>
                        </div>
                        :
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <AsyncImage data={props.evaluation.teamManager.value} className='ml-3 previewDetailProfilePic' alt='' />
                            <div className="d-flex flex-column align-items-start justify-items-center ml-2">
                                <div className="evaluationDetailsRole">Team Manager</div>
                                <div className="evaluationDetailsName">{props.evaluation.teamManager.label}</div>
                            </div>
                        </div>
                    }

                </div>
                <div className="row col-12">
                    {(props.evaluation.valutatore.label !== null && props.evaluation.valutatore.label !== undefined && props.evaluation.valutatore.label !== "") ?
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <AsyncImage data={props.evaluation.valutatore.value} className='ml-3 previewDetailProfilePic' alt='' />
                            <div className="d-flex flex-column align-items-start justify-items-center ml-2">
                                <div className="evaluationDetailsRole">Valutatore</div>
                                <div className="evaluationDetailsName">{props.evaluation.valutatore.label}</div>
                            </div>
                        </div>
                        :
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div style={{ height: '70px' }}></div>
                            <div style={{ height: '70px' }}></div>
                        </div>
                    }
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center p-2">
                    <div
                        className={"d-flex flex-row justify-content-center align-items-center ml-1 " + ((props.evaluation.idSheet === 7) ? "RecentPlayerRectangleCircleLittle" : "RecentPlayerRangeCircleLittle")}
                        style={{ backgroundColor: SheetPlayerRangeColor }}
                    >
                        {props.evaluation.sheetPlayerRange || ''}
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-end mr-1">
                        <div className="evaluationDetailsPercent">{props.evaluation.sheetTotalRatePercent}%</div>
                        <div className="evaluationDetailsDate">{moment(props.evaluation.dataAscolto).format(dateFormat)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
