import React, { useState, useEffect, useContext } from 'react';
import HomeContext from './HomeContext';
import { updateEvaluationsUserList } from '../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { valutaAPIget, valutaAPIpost } from '../FetchApi';
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import Select from 'react-select';
import '../css/SearchForm.css';
import "react-datepicker/dist/react-datepicker.css";
import { getFirstDayOfMonth, customDropDownStyles, getDivToggleStatus, toggleDiv } from '../Tools';
import { PlusCircleFill, Search, X, ExclamationCircleFill, Eye, EyeSlash } from 'react-bootstrap-icons';

const SearchForm = (props) => {
    const valutaStore = useSelector(state => state.valutaStore);
    const dispatch = useDispatch();
    const [homeState, setHomeState] = useContext(HomeContext);
    const [searchToggleStatus, setSearchToggleStatus] = useState(getDivToggleStatus("search-form-fields-container"));
    const fieldsRicerca = [
        { name: "Assistant", label: "Consulente", type: "ext_dropdown", data: valutaStore.consulenteList },
        { name: "Account", label: "Team Manager", type: "ext_dropdown", data: valutaStore.teamManagerList },
        { name: "Evaluator", label: "Valutatore", type: "ext_dropdown", data: valutaStore.valutatoreList },
        { name: "Quality", label: "Quality", type: "ext_dropdown", data: valutaStore.qualityList },
        { name: "Bu", label: "Bu", type: "dropdown" },
        { name: "LB", label: "LB", type: "dropdown" },
        { name: "Attivita", label: "Attività", type: "dropdown" },
        { name: "TipologiaChiamata", label: "Tipologia Chiamata", type: "dropdown" },
        { name: "CanaleIngresso", label: "Canale d'ingresso", type: "dropdown" },
        { name: "LbOrigine", label: "LB origine", type: "dropdown" },
        { name: "ConsulenzaDateFrom", label: "Consulenza dal", type: "datetime", dataPreset: getFirstDayOfMonth(), minDate: null, maxDate: "ConsulenzaDateTo" },
        { name: "ConsulenzaDateTo", label: "Consulenza al", type: "datetime", dataPreset: new Date, minDate: "ConsulenzaDateFrom", maxDate: null },
        { name: "AscoltoDateFrom", label: "Ascolto dal", type: "datetime", dataPreset: null, minDate: null, maxDate: "AscoltoDateTo" },
        { name: "AscoltoDateTo", label: "Ascolto al", type: "datetime", dataPreset: null, minDate: "AscoltoDateFrom", maxDate: null },
        { name: "CodiceCliente", label: "Cod. Cliente", type: "textinput" },
        { name: "NumeroRuolo", label: "Num. Ruolo", type: "textinput" },
        { name: "RiferimentoCRM", label: "Riferimento CRM", type: "textinput" },
        { name: "RiferimentoTs", label: "Tiferimento TS", type: "textinput" },
        {
            name: "SheetPlayerRange", label: "Valutazione", type: "checkbox",
            options:
                [
                    { label: "A", description: "Adeguato", value: "A" },
                    { label: "B", description: "Parzialmente adeguato", value: "B" },
                    { label: "C", description: "Non adeguato", value: "C" }
                ]
        },
        {
            name: "Privacy", label: "Privacy", type: "checkbox",
            options:
                [
                    { label: "SI", description: "SI", value: "1" },
                    { label: "NO", description: "NO", value: "0" }
                ]
        }
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [loadingListsError, setLoadingListsError] = useState(false);
    const [dropDownData, setDropDownData] = useState({});
    const [dataToSearch, setDataToSearch] = useState((sessionStorage.getItem('dataToSearch')) ? JSON.parse(sessionStorage.getItem('dataToSearch')) : {});
    const toggleSearch = () => {
        var toggleResult = toggleDiv("search-form-fields-container");
        setSearchToggleStatus(toggleResult);
    }
    const setEvaluationsUserList = async () => {

        //var evaluationsUserList = sessionStorage.getItem('evaluationsUserList');
        //if (evaluationsUserList === undefined || evaluationsUserList === "" || evaluationsUserList === null || JSON.parse(evaluationsUserList)?.length <= 0) {
            setIsLoading(true);
            await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_EVALUATIONS_USERLIST, {}).then(siaUsersList => {
                if (siaUsersList === undefined || siaUsersList === null || siaUsersList === "" || siaUsersList?.length <= 0) {
                    setLoadingListsError(true);
                }
                updateEvaluationsUserList(dispatch, siaUsersList);
                setIsLoading(false);
            })
                .catch(e => { console.log(e); }
                );
        /*}
        else {
            await updateEvaluationsUserList(dispatch, JSON.parse(sessionStorage.getItem('evaluationsUserList')));
        }*/

    }
    const setDropDownsInitialValues = async () => {
        var tempDropDownData = { ...dropDownData };
        var dropdowns = fieldsRicerca.filter(f => f.type === "dropdown");
        for (var i in dropdowns) {
            var field = dropdowns[i];
            //if (tempDropDownData[fieldName] === null || tempDropDownData[fieldName] === undefined ) {
            await valutaAPIget(process.env.REACT_APP_VALUTA_API_GETDROPDOWN, { dropDownName: field.name, fromEvaluations: true }).then((res) => {
                if (res === undefined || res === null || res === "" || res?.length <= 0) {
                    setLoadingListsError(true);
                }
                else if(res === "204") {
                    res = undefined;
                }
                tempDropDownData[field.name] = res;
            });
            //}
        }
        setDropDownData(tempDropDownData);
    }
    const setDateFieldsInitialValues = async () => {
        var tempDataToSearch = { ...dataToSearch };
        var datetimes = fieldsRicerca.filter(f => f.type === "datetime");
        for (var i in datetimes) {
            var fieldName = datetimes[i].name;
            if ((tempDataToSearch[fieldName] === null || tempDataToSearch[fieldName] === undefined) && datetimes[i].dataPreset !== null) {
                tempDataToSearch[fieldName] = moment(datetimes[i].dataPreset).format("YYYY-MM-DD");
            }
        }
        setDataToSearch(tempDataToSearch);
    }
    const resetForm = () => {
        setIsLoading(true);
        setDataToSearch({});
        sessionStorage.removeItem('dataToSearch');
        window.location.href = "/";
    }
    const updateDataToSearch = (field, value) => {

        var tempDataToSearch = { ...dataToSearch };
        if (field.type === "checkbox") {
            if (tempDataToSearch[field.name] === null || tempDataToSearch[field.name] === undefined || tempDataToSearch[field.name] === "") {
                tempDataToSearch[field.name] = [];
            }
            if (value === "" || value === null) {
                delete tempDataToSearch[field.name];
            }
            else {
                if (tempDataToSearch[field.name].includes(value)) {
                    //rimuovi
                    tempDataToSearch[field.name] = tempDataToSearch[field.name].filter(v => v !== value);
                }
                else {
                    //aggiungi
                    tempDataToSearch[field.name].push(value);
                }
                if (tempDataToSearch[field.name].length <= 0) {
                    delete tempDataToSearch[field.name];
                }
            }
        }
        else {
            tempDataToSearch[field.name] = value;
            if (tempDataToSearch[field.name] === "" || tempDataToSearch[field.name] === null) {
                delete tempDataToSearch[field.name];
            }
        }
        setDataToSearch(tempDataToSearch);
    }
    const doSearch = async () => {
        var cookie_dataToSearch = JSON.parse(sessionStorage.getItem('dataToSearch'));
        setIsLoading(true);
        dataToSearch.Limit = process.env.REACT_APP_DEFAULT_DATATOSEARCH_LIMIT;
        dataToSearch.Offset = 0;
        dataToSearch.OrderBy = cookie_dataToSearch?.OrderBy ?? process.env.REACT_APP_DEFAULT_DATATOSEARCH_ORDERBY;
        dataToSearch.OrderDirection = cookie_dataToSearch?.OrderDirection ?? process.env.REACT_APP_DEFAULT_DATATOSEARCH_ORDERDIRECTION;
        sessionStorage.setItem('dataToSearch', JSON.stringify(dataToSearch));
        //console.log(dataToSearch);
        //console.log(JSON.parse(sessionStorage.getItem('dataToSearch')));
        await valutaAPIpost(process.env.REACT_APP_VALUTA_API_GET_EVALUATIONS_LIST, dataToSearch).then(evaluationsList => {
            var evaluationsListTotalPages = Math.ceil(evaluationsList.resultsCount / process.env.REACT_APP_DEFAULT_DATATOSEARCH_LIMIT);
            var evaluationsListCurrentPage = 1;
            //console.log("evaluationsListTotalPages=" + evaluationsListTotalPages)
            setHomeState({
                ...homeState,
                evaluationsList: evaluationsList.evaluations,
                evaluationsListTotalCount: evaluationsList.resultsCount,
                evaluationsListTotalPages,
                evaluationsListCurrentPage,
                evaluationsListTableStart:0,
                evaluationsListTableEnd: process.env.REACT_APP_DEFAULT_DATATOSEARCH_LIMIT,
                evaluationsListOrderBy: dataToSearch.OrderBy,
                evaluationsListOrderDirection: dataToSearch.OrderDirection
            })
            setIsLoading(false);
            //console.log(evaluationsList);
            window.scrollTo({
                top: homeState.scrollTo?.current.offsetTop,
                behavior: 'smooth',
              })
        })
            .catch(e => { console.log(e); }
            );
    }
    useEffect(() => {
        (async function doAsync() {
            await setEvaluationsUserList();
            await setDropDownsInitialValues();
            await setDateFieldsInitialValues();
            registerLocale("it", it);
        })();
    }, []);

    return (
        <React.Fragment>
            {(loadingListsError) ? <div className="alert alert-danger text-center"><ExclamationCircleFill /> Attenzione si sono verificati errori nel caricamento dei dati</div> : null}
            <div className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-between align-items-end">
                <div className="d-flex flex-row justify-content-start align-items-start">
                    <h5>Ricerca</h5>
                    <div className="ml-2">
                        {(searchToggleStatus === "d-block") ?
                            <EyeSlash className="valuta-btn-hide" data-tip="Nascondi" onClick={() => toggleSearch()} />
                            :
                            <Eye className="valuta-btn-hide" data-tip="Mostra" onClick={() => toggleSearch()} />
                        }

                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end align-items-start ml-2 mb-2">
                    <Link to="/evaluation" className="btn btn-primary btn-valuta d-flex flex-row align-items-center"><PlusCircleFill className="mr-2" /> NUOVA VALUTAZIONE</Link>
                </div>
            </div>
            <div id="search-form-fields-container" className={"mb-3 search-form-fields-container " + searchToggleStatus}>
                <div className="row">
                    {(fieldsRicerca.filter(f => f.type === "datetime").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div>{field.label}</div>
                                <DatePicker
                                    id={field.name}
                                    name={field.name}
                                    className="form-control text-center search-input-field"
                                    selected={(dataToSearch[field.name] !== null && dataToSearch[field.name] !== undefined && dataToSearch[field.name] !== "") ? new Date(dataToSearch[field.name]) : null}
                                    onChange={(value) => updateDataToSearch(field, (value !== "" && value !== null) ? moment(value).format("YYYY-MM-DD") : value)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={(dataToSearch[field.maxDate] !== null && dataToSearch[field.maxDate] !== undefined && dataToSearch[field.maxDate] !== "") ? new Date(dataToSearch[field.maxDate]) : null}
                                    minDate={(dataToSearch[field.minDate] !== null && dataToSearch[field.minDate] !== undefined && dataToSearch[field.minDate] !== "") ? new Date(dataToSearch[field.minDate]) : null}
                                    locale="it"
                                    disabled={isLoading}
                                />
                            </div>
                        )
                    }))}
                </div>
                <div className="row">
                    {(fieldsRicerca.filter(f => f.type === "textinput").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div>{field.label}</div>
                                <input
                                    type="text"
                                    id={field.name}
                                    name={field.name}
                                    className="form-control text-center search-input-field"
                                    value={dataToSearch[field.name]}
                                    onChange={(event) => updateDataToSearch(field, event.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                        )
                    }))}
                </div>
                <div className="row">
                    {(fieldsRicerca.filter(f => f.type === "ext_dropdown").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div>{field.label}</div>
                                <Select
                                    id={field.name}
                                    name={field.name}
                                    styles={customDropDownStyles}
                                    isMulti
                                    options={field.data}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    onChange={(value) => updateDataToSearch(field, value)}
                                    defaultValue={(dataToSearch[field.name] !== null && dataToSearch[field.name] !== undefined && dataToSearch[field.name] !== "") ? dataToSearch[field.name] : null}
                                    isDisabled={isLoading}
                                />
                            </div>
                        )
                    }))}
                </div>
                <div className="row">
                    {(fieldsRicerca.filter(f => f.type === "dropdown").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div>{field.label}</div>
                                <Select
                                    id={field.name}
                                    name={field.name}
                                    styles={customDropDownStyles}
                                    isMulti
                                    options={dropDownData[field.name]}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    onChange={(value) => updateDataToSearch(field, value)}
                                    defaultValue={(dataToSearch[field.name] !== null && dataToSearch[field.name] !== undefined && dataToSearch[field.name] !== "") ? dataToSearch[field.name] : null}
                                    isDisabled={isLoading}
                                />
                            </div>
                        )
                    }))}
                </div>
                <div className="row">
                    {(fieldsRicerca.filter(f => f.type === "checkbox").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div className="mb-2">{field.label}</div>
                                <div className="inline-fields-group">
                                    {field.options.map((opt, index) => {
                                        var checked = false;
                                        if (dataToSearch[field.name] !== undefined && dataToSearch[field.name] !== null && dataToSearch[field.name].length > 0) {
                                            var founded = dataToSearch[field.name].includes(opt.value);
                                            if (founded)
                                                checked = true;
                                        }
                                        return (
                                            <div className="form-check form-check-inline" key={index}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={field.name + "1"}
                                                    name={field.name}
                                                    value={opt.value}
                                                    onChange={(event) => updateDataToSearch(field, event.target.value)}
                                                    defaultChecked={checked}
                                                    disabled={isLoading}
                                                />
                                                <label className="form-check-label" htmlFor={field.name + "1"}>{opt.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }))}
                    {(fieldsRicerca.filter(f => f.type === "radio").map((field, index) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-3 search-form-item" key={index}>
                                <div className="mb-2">{field.label}</div>
                                <div className="inline-fields-group">
                                    {field.options.map((opt, index) => {
                                        return (
                                            <div className="form-check form-check-inline" key={index}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={field.name + "1"}
                                                    name={field.name}
                                                    value={opt.value}
                                                    onChange={(event) => updateDataToSearch(field, event.target.value)}
                                                    defaultChecked={(dataToSearch[field.name] !== undefined && dataToSearch[field.name] === opt.value) ? true : false}
                                                    disabled={isLoading}
                                                />
                                                <label className="form-check-label" htmlFor={field.name + "1"}>{opt.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }))}
                    <div className="col-12 col-sm-6 col-md-3 search-form-item">

                    </div>
                    <div className="col-12 col-sm-6 col-md-3 search-form-item d-flex flex-row justify-content-center">
                        <button alt="Reset form" title="Reset form" className="btn btn-secondary btn-valuta-grey d-flex flex-row align-items-center mr-1" onClick={() => resetForm()} disabled={isLoading}><X /></button>
                        <button alt="Cerca" title="Cerca" className="btn btn-primary btn-valuta d-flex flex-row align-items-center" onClick={() => doSearch()} disabled={isLoading}><Search /></button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default SearchForm;