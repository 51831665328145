import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { updateUserData } from './store/store';
import jwt_decode from "jwt-decode";
import './css/App.css';
import Layout from './Layout';
import Loading from './components/Loading';
import Error from './components/Error';
import {
    getToken,
    getUserApplicationRole,
    verifyTokenRoles,
    setCookieRole,
    getCookieRole,
    setCookieUserFullName,
    setCookieEnabledCountries,
    getCountryFromTokenRole,
    getCookieEnabledCountries
} from './AdalConfig';
import ChooseRole from './components/ChooseRole';

const App = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);
    const [userHasToChooseRole, setUserHasToChooseRole] = useState(false);
    const [userApplicationRolesFromAllowedTokenRoles, setUserApplicationRolesFromAllowedTokenRoles] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        (async function doAsync() {

            setIsLoading(true);
            var cookieToken = window.localStorage.getItem("adal.idtoken");
            var token = (cookieToken && cookieToken !== null && cookieToken !== undefined && cookieToken !== "" && cookieToken.length > 0) ? cookieToken : getToken();
            if (token !== undefined && token !== null && token !== "" && token.length > 0) {
                var decodedToken = jwt_decode(token);
                setCookieUserFullName(decodedToken.name);
                await verifyTokenRoles(decodedToken).then((response) => {
                    if (response.success) {
                        if (response.allowedUserRoles !== null && response.allowedUserRoles !== undefined) {
                            //response.allowedUserRoles = ["AccountTirana"]; //Forza il ruolo (per i test)
                            //Converto i ruoli token in ruoli applicazione
                            var appRolesList = [];
                            response.allowedUserRoles.forEach((allowedRole => {
                                var appRole = getUserApplicationRole(allowedRole);
                                if (!appRolesList.includes(appRole)) {
                                    appRolesList.push(appRole);
                                }
                                setUserApplicationRolesFromAllowedTokenRoles(appRolesList);
                            }))
                            //Se ha un solo ruolo "application" (ADMIN, MANAGER, EVALUATOR) proseguo altrimenti deve scegliere
                            //In questo modo se ha due ruoli ad esempio QUALITY TIRANA e QUALITY BALERNA
                            //Che sono entrambi QUALITY, entra come quality, ma se ha due ruoli applicazione diversi
                            //(Ad esempio ACCOUNT TIRANA e QUALITY TIRANA) deve scegliere con quale ruolo entrare
                            var setEnabledCountries = false;
                            if (appRolesList.length <= 0) {
                                setErrorMessages(["Il ruolo del token non corrisponde a nessun ruolo dell'applicazione."]);
                                setIsLoading(false);
                            }
                            else if (appRolesList.length === 1) {
                                var userApplicationRole = appRolesList[0];
                                updateUserData(dispatch, { userFullName: decodedToken.name, userApplicationRole: userApplicationRole });
                                setCookieRole(userApplicationRole);
                                setEnabledCountries = (getCookieEnabledCountries()===null) ? true : false;
                                setIsLoading(false);
                            } else {
                                //Scegliere il ruolo utente
                                //Non dovrebbe capitare che ci siano utenti con doppi ruoli diversi ma cmq lo gestiamo
                                //Se aveva gi� scelto il ruolo, lo troviamo nel cookie valuta.role
                                var userApplicationChoosenRole = getCookieRole();
                                if (userApplicationChoosenRole !== false) {
                                    updateUserData(dispatch, { userFullName: decodedToken.name, userApplicationRole: userApplicationChoosenRole });
                                    setIsLoading(false);
                                    setEnabledCountries = (getCookieEnabledCountries() === null) ? true : false;;
                                }
                                else {
                                    setUserHasToChooseRole(true);
                                    setIsLoading(false);
                                }
                            }

                            //IMPOSTO LE ENABLED COUNTRIES NEL COOKIE
                            if (setEnabledCountries) {
                                var enabledCountriesList = [];
                                response.allowedUserRoles.forEach((allowedRole => {
                                    if (allowedRole === "Admin") {
                                        var sites = process.env.REACT_APP_VALUTA_USER_APPLICATION_SITES.split(",");
                                        sites.forEach((site) => {
                                            var country = site.split(":")[1];
                                            enabledCountriesList.push(country);
                                        });
                                    }
                                    else {
                                        var country = getCountryFromTokenRole(allowedRole);
                                        if (!enabledCountriesList.includes(country)) {
                                            enabledCountriesList.push(country);
                                        }
                                    }
                                }))
                                setCookieEnabledCountries(enabledCountriesList);
                            }
                        }
                        else {
                            //caso quasi impossibile, se � response.success===true la lista dei ruoli dovrebbe esserci
                            //gestiamo comunque il caso.
                            setErrorMessages(["L'utente non ha nessun ruolo valido per applicazione."]);
                            setIsLoading(false);
                        }
                    }
                    else {
                        setErrorMessages([response.message]);
                        setIsLoading(false);
                    }
                });
            }
            else {
                setErrorMessages(["Token di accesso vuoto, impossibile verificare l'utente."]);
                setIsLoading(false);
            }
        })()
    }, [dispatch]);
    return (!isLoading) ?
        (errorMessages.length <= 0) ?
            (userHasToChooseRole) ? <ChooseRole roles={userApplicationRolesFromAllowedTokenRoles} />
                :
                <Layout />
            :
            <Error errors={errorMessages} />
        :
        <Loading />;
}
export default connect((state) => { return state })(App);