import 'core-js';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import valutaAppStore from './store/store';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './css/bootstrap/themes/minty/bootstrap.min.css'; /* https://bootswatch.com/minty */
import './css/Index.css';
import App from './App';
//require('es7-object-polyfill');

const store = createStore(valutaAppStore, applyMiddleware(thunk));
ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <App />
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);