import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
const trackEvent = (message, properties = {}) => {
    appInsights.trackEvent({ name: message, properties: properties });
}
const trackException = (message, properties = {}) => {
    appInsights.trackException({
        exception: { message: message },
        severityLevel: SeverityLevel.Error,
        properties: properties
    });
}
export { reactPlugin, appInsights, trackEvent, trackException };