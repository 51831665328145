import React from 'react';
import RenderField from './RenderField';
const SheetFiledsHeader = (props) => {

    var sheet = props.sheet;

    return (sheet.fields && sheet.fields !== undefined && sheet.fields!==null) ? 
        <div id="SheetFiledsHeader" className="row sheet-subject-container pt-3 text-center text-sm-left">
            {sheet.fields.filter(f => f.position === 'header').map((field, fieldIndex) =>
                <RenderField readOnly={props.readOnly} key={fieldIndex} field={field} sheet={sheet} />)}
        </div>
        :
        <div>Non è possibile caricare i campi dell'header della scheda.</div>
}
export default SheetFiledsHeader;