import React, { useEffect, useState } from 'react';
import HomeContext from './HomeContext';
import SearchForm from './SearchForm';
import EvaluationsList from './EvaluationsList';
import RecentEvaluations from './RecentEvaluations';
import Loading from './Loading';
import { valutaAPIget } from '../FetchApi';

const Home = () => {

    const [homeState, setHomeState] = useState({ evaluationsList: [], allPlayerRanges:[] });
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carico la scheda...")

    useEffect(() => {
        setIsLoading(true);
        (async function doAsync() {
            var allPlayerRanges = window.sessionStorage.getItem("valuta.allPlayerRanges");
            if (allPlayerRanges === null || allPlayerRanges === undefined || allPlayerRanges === "") {
                await valutaAPIget(process.env.REACT_APP_VALUTA_API_GET_SHEET_PLAYER_RANGES, {}).then(res => {
                    if (res !== null && res !== undefined) {
                        setIsLoading(false);
                        setHomeState({ ...homeState, allPlayerRanges: res });
                        window.sessionStorage.setItem("valuta.allPlayerRanges", JSON.stringify(res));
                    }
                    else {
                        var exception = (res.Error !== undefined) ? "\n\r" + res.Error : "";
                        if (exception !== "") {
                            setLoadingMessage(exception);
                        }
                        else {
                            setLoadingMessage("Si sono verificati errori nel caricamento della scheda, prova a ricaricare la pagina o contatta il supporto.");
                        }
                    }
                })
            }
            else {
                setHomeState({ ...homeState, allPlayerRanges: JSON.parse(allPlayerRanges) });
                setIsLoading(false);
            }
        })()
    }, []);

    return isLoading ?
        <Loading message={loadingMessage} />
        :
        <HomeContext.Provider value={[homeState, setHomeState]}>
            <React.Fragment>
                <SearchForm />
                <EvaluationsList />
                <RecentEvaluations />
            </React.Fragment>
        </HomeContext.Provider>

}
export default Home;