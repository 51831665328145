import React, { useContext, useState, Fragment } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import { Pencil, XCircle, Check2Circle, CheckAll } from 'react-bootstrap-icons';
import '../../../css/Sheet.css'
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';


export default function SingleQuestionBuilder(props) {
    const { deleteQuestion, deleteSubsectionQuestion, editQuestion, editSubsectionQuestion, sheetMaxValue } = useContext(CreateSheetContext)
    const [editingQuestion, setEditingQuestion] = useState(false)
    const [newEditedQuestionName, setNewEditedQuestionName] = useState(props.question.name)
    const [questionIsMandatory, setQuestionIsMandatory] = useState(props.question.isMandatory)
    const questionScoreValues = [];
    questionScoreValues.push({ value: 'n.d.', label: 'n.d.' })
    for (let index = 1; index <= sheetMaxValue; index++) {
        questionScoreValues.push({ value: index, label: index })
    }
    function askToEditQuestion() {
        editQuestion(props.question.id, props.question.subjectId, newEditedQuestionName, questionIsMandatory)
        setEditingQuestion(false)
    }
    function askToEditSubsectionQuestion() {
        // console.log("Sub");
        editSubsectionQuestion(props.question.subjectId, props.subsectionId, props.question.id, newEditedQuestionName, questionIsMandatory)
        setEditingQuestion(false)
    }
    const customDropDownStyles = {
        container: provided => ({
            ...provided,
            width: 90
        })
    };
    const parseBool = (boolInput) => {
        return boolInput === 'false' ? false : true
    }
    return (

        <div className="row ManagerQuestionContainer align-items-center" key={props.question.id}>
            <div className="col-12 col-md-9 text-dark sheet-question-text mt-3 ">
                {
                    !editingQuestion &&
                    <p>{props.question.name} {props.question.isMandatory ? <span className="text-danger">*</span> : ''}</p>
                }
                {
                    editingQuestion && !props.subsectionId &&
                    <input className='editingInput textInput cleanInput' type='text' value={newEditedQuestionName} onChange={(e) => setNewEditedQuestionName(e.target.value)} onKeyUp={(e) => { if (e.key === 'Enter') { askToEditQuestion() } }} />

                }
                {
                    editingQuestion && props.subsectionId &&
                    <input className='editingInput textInput cleanInput'
                        type='text'
                        value={newEditedQuestionName}
                        onChange={(e) => setNewEditedQuestionName(e.target.value)}
                        onKeyUp={(e) => { if (e.key === 'Enter') { askToEditSubsectionQuestion() } }} />
                }
            </div>
            <div className="col-12 col-md-3 mt-3 mt-md-0 d-flex flex-row align-items-center justify-content-center justify-content-md-end">
                {
                    !editingQuestion &&
                    <Select
                        styles={customDropDownStyles}
                        name={"voto_question_" + props.question.id}
                        options={questionScoreValues}
                        placeholder={sheetMaxValue}
                        isSearchable={false}
                    />
                }
                {
                    editingQuestion &&
                    <div className="d-flex flex-row justify-content-end align-items-center pt-3">
                        <h6 className="ManagerFieldLabels pl-1">Obbligatoria</h6>
                        <select
                            className="ml-2"
                            name='questionIsMandatory'
                            onChange={(e) => { setQuestionIsMandatory(parseBool(e.target.value)) }}
                            value={questionIsMandatory}>
                            <option value={true}>SI</option>
                            <option value={false}>NO</option>
                        </select>
                    </div>
                }
                <div className="ml-2 text-dark sheet-question-text d-flex flex-row justify-content-end">
                    {
                        editingQuestion && !props.subsectionId &&
                        <a className="mr-2  pt-3" onClick={() => askToEditQuestion()} data-tip="Salva">
                            <Check2Circle className="ManagerBtnIcons" /></a>
                    }
                    {
                        editingQuestion && props.subsectionId &&
                        <a className="mr-2  pt-3" onClick={() => askToEditSubsectionQuestion()} data-tip="Salva">
                            <Check2Circle className="ManagerBtnIcons" /></a>
                    }
                    {
                        !editingQuestion && !props.subsectionId &&
                        <Fragment>
                            <a className="mr-2" onClick={() => setEditingQuestion(true)} data-tip="Modifica">
                                <Pencil className="ManagerBtnIcons" /></a>
                            <a onClick={() => (deleteQuestion(props.question.id, props.question.subjectId))} data-tip="Elimina">
                                <XCircle className="ManagerBtnIcons text-danger" /></a>
                        </Fragment>
                    }
                    {
                        !editingQuestion && props.subsectionId &&
                        <Fragment>
                            <a className="mr-2" onClick={() => setEditingQuestion(true)} data-tip="Modifica">
                                <Pencil className="ManagerBtnIcons" /></a>
                            <a onClick={() => (deleteSubsectionQuestion(props.question.subjectId, props.subsectionId, props.question.id))} data-tip="Elimina">
                                <XCircle className="ManagerBtnIcons text-danger" /></a>
                        </Fragment>
                    }
                </div>
            </div>
            <ReactTooltip />
        </div>
    )
}
