import React, { useContext, useState, Fragment, useEffect } from 'react'
import { CreateSheetContext } from './CreateSheetContext';
import '../../../css/Sheet.css'
import SingleQuestionBuilder from './SingleQuestionBuilder';
import CreatableSelect from 'react-select/creatable';
import { Pencil, XCircle, Check2Circle, ArrowReturnRight, ArrowReturnLeft } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';
export default function SingleSubjectBuilder(props) {

    const { subjects, deleteSubject, editSubject, addQuestion, addSubsection, editSubsection, deleteSubsection, addQuestionToSubsection, premadeSubjects, totalWeight, subsectionCounter } = useContext(CreateSheetContext)
    const [questionName, setQuestionName] = useState('');
    const [questionIsMandatory, setQuestionIsMandatory] = useState(true)
    const [editingSubjectName, setEditingSubjectName] = useState(false)
    const [subjectName, setSubjectName] = useState({ label: props.subject.name, value: props.subject.premadeId })
    const [subjectItemName, setSubjectItemName] = useState(props.subject.name)
    const [subjectWeight, _setSubjectWeight] = useState(props.subject.weight)
    const [questionItemIsMandatory, _setQuestionItemIsMandatory] = useState(props.subject.isMandatory ? true : false)
    const [subsectionCreation, setSubsectionCreation] = useState([]);
    const [subsectionName, _setSubsectionName] = useState("")
    const [subsectionEditedName, _setSubsectionEditedName] = useState("")
    const [subsectionWeight, setSubsectionWeight] = useState(0)
    const [subsectionQuestionIsMandatory, setSubsectionQuestionIsMandatory] = useState(true)
    const [subsectionQuestionName, _setSubsectionQuestionName] = useState('')
    const [editingSubsectionName, _setEditingSubsectionName] = useState({})


    function setQuestionItemIsMandatory(isManda) {
        console.log(isManda);
        _setQuestionItemIsMandatory(isManda)
    }

    function setSubsectionName(newName) {
        //console.log(newName);
        _setSubsectionName(newName)
    }
    function setSubsectionEditedName(newName) {
        //console.log(newName);
        _setSubsectionEditedName(newName)
    }
    function setSubsectionQuestionName(newQuestionName, subsectionId) {
        //console.log(newQuestionName);
        //console.log(subsectionId);
        _setSubsectionQuestionName({ ...subsectionQuestionName, [subsectionId]: newQuestionName })
        //console.log(subsectionQuestionName);
    }
    function setEditingSubsectionName(subsectionId) {
        //console.log("Toggling editing for subsection " + subsectionId);
        _setEditingSubsectionName({ ...editingSubsectionName, [subsectionId]: !editingSubsectionName[subsectionId] })
    }
    function setSubjectWeight(_subjectWeight) {
        if (_subjectWeight > 100) {
            _setSubjectWeight(100)
        } else if (_subjectWeight < 0) {
            _setSubjectWeight(0)
        } else {
            _setSubjectWeight(_subjectWeight)
        }
    }
    function askToEditSubject() {
        editSubject(props.subject.id, { ...subjectName, weight: subjectWeight })
        setEditingSubjectName(false)
    }
    function askToEditItemSubject() {
        editSubject(props.subject.id, { name: subjectItemName, isMandatory: questionItemIsMandatory, weight: subjectWeight })
        setEditingSubjectName(false)
    }
    const toggleSubsection = () => {
        !subsectionCreation.includes(props.subject.id) ? setSubsectionCreation([...subsectionCreation, props.subject.id]) : setSubsectionCreation([...subsectionCreation.filter(_singleSS => _singleSS !== props.subject.id)])

    }
    const askToDeleteSubsection = (subsectionId) => {
        deleteSubsection(subsectionId, props.subject.id)
    }
    const askToEditSubsection = (subsectionId) => {

        //console.log(subsectionId)
        //console.log(subsectionEditedName);
        if (subsectionEditedName.length === 0) {

            return
        }
        editSubsection(props.subject.id, subsectionId, subsectionEditedName)
        setEditingSubsectionName(subsectionId)

    }
    function askToAddSubsection() {
        //console.log(subsectionName);
        //console.log(subsectionWeight);
        //console.log(subjects);
        if (subsectionName.length === 0) {

            return
        }
        const subsectionId = subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).subsections.slice(-1).length > 0 ? subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).subsections.slice(-1)[0].id + 1 : 1

        const newSubsection = {
            id: subsectionId,
            name: subsectionName,
            premadeId: Number.isInteger(subsectionName.value) ? subsectionName.value : 0,
            weight: subsectionWeight,
            subjectId: props.subject.id,
            questions: []
        }
        //console.log(newSubsection);
        addSubsection(newSubsection)
        setSubsectionName("")
        setSubsectionWeight(0)
    }
    async function askToAddQuestion() {
        if (questionName.length === 0) {
            /**
             * TODO: Mostrare un flash message per evidenziare il problema
             */
            return
        }
        const questionId = subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).questions.slice(-1).length > 0 ? subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).questions.slice(-1)[0].id + 1 : 1

        const newQuestion = {
            id: questionId,
            name: questionName,
            isMandatory: questionIsMandatory,
            subjectId: props.subject.id
        }
        addQuestion(newQuestion, props.subject.id)
        setQuestionName('')
        setQuestionIsMandatory(true)
    }
    async function askToAddQuestionToSubsection(subsectionId) {
        //console.log(subsectionId);
        //console.log(subsectionQuestionName[subsectionId]);
        if (!subsectionQuestionName || !subsectionQuestionName[subsectionId] || subsectionQuestionName[subsectionId].length === 0) {
            /**
             * TODO: Mostrare un flash message per evidenziare il problema
             */
            return
        }
        const questionId = subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).subsections.find(_filteredSubsection => _filteredSubsection.id === subsectionId).questions.slice(-1).length > 0 ? subjects.find(_filteredSubject => _filteredSubject.id === props.subject.id).subsections.find(_filteredSubsection => _filteredSubsection.id === subsectionId).questions.slice(-1)[0].id + 1 : 1

        const newQuestion = {
            id: questionId,
            name: subsectionQuestionName[subsectionId],
            isMandatory: subsectionQuestionIsMandatory,
            subjectId: props.subject.id,
            subsectionId: subsectionId
        }
        //console.log(newQuestion);
        addQuestionToSubsection(newQuestion, subsectionId, props.subject.id)
        setSubsectionQuestionName('', subsectionId)
        setSubsectionQuestionIsMandatory(true)
    }
    const parseBool = (boolInput) => {
        return boolInput === 'false' ? false : true
    }

    return (
        <div className='ManagerSubjectContainer sheetSubjectAreaDelimiter container-fluid'>
            <div className="row">
                {
                    !editingSubjectName &&
                    <div className="col-12 d-flex flex-row ManagerQuestionContainer">
                        <div className="row d-flex flex-row flex-grow-1 align-items-start">
                            <div className="col-12 col-md-10 d-flex flex-row  align-items-start">
                                <div className="badge badge-success mr-2 ManagerBadgeWeight">{props.subject.weight}%</div>
                                <div>{props.subject.name}{props.subject.isMandatory ? <span className="text-danger">*</span> : ''}</div>
                            </div>
                            <div className="col-12 col-md-2 d-flex flex-row  align-items-start justify-content-end">
                                <a className="mr-2" onClick={() => (setEditingSubjectName(true))}>
                                    <Pencil className="ManagerBtnIcons" /></a>

                                <a onClick={() => (deleteSubject(props.subject.id))}>
                                    <XCircle className="ManagerBtnIcons text-danger" /></a>
                            </div>
                        </div>
                    </div>
                }
                {
                    editingSubjectName && props.weightType === 'pesoSezione' &&
                    <Fragment>

                        <div className="col-12 col-md-8 text-center text-md-left">
                            <h6 className="ManagerFieldLabels">Nome nuova sezione</h6>
                            <CreatableSelect
                                menuPlacement={'top'}
                                options={premadeSubjects}
                                closeMenuOnSelect={true}
                                value={subjectName}
                                onKeyUp={(e) => { if (e.key === 'Enter') { askToEditSubject() } }}
                                onChange={setSubjectName}
                            />
                        </div>

                        <div className="col-12 col-md-4">
                            <h6 className="ManagerFieldLabels">Peso sezione</h6>
                            <div className="input-group inputWeight d-flex flex-row justify-content-start">
                                <input className='textInput form-control' value={subjectWeight} type='number' min={0} max={100} name='subjectWeight' onKeyUp={(e) => { if (e.key === 'Enter') { askToEditSubject() } }} onChange={(e) => setSubjectWeight(parseInt(e.target.value))} />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">%</span>
                                </div>
                                <a className="ManagerBtnIcons ml-2" data-tip="Salva"
                                    onClick={() => askToEditSubject()}>
                                    <Check2Circle /></a>
                            </div>
                        </div>

                    </Fragment>
                }
                {
                    editingSubjectName && props.weightType === 'pesoItem' &&
                    <Fragment>
                        <div className="row col-12">
                            <div className="col-lg-7 col-sm-12">
                                <h6 className="ManagerFieldLabels">Nome domanda</h6>
                                <input className='textInput cleanInput form-control' value={subjectItemName} type='text' name='subjectItemName' onKeyUp={(e) => { if (e.key === 'Enter') { askToEditItemSubject() } }} onChange={(e) => setSubjectItemName(e.target.value)} />
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <h6 className="ManagerFieldLabels">Peso domanda</h6>
                                <div className="input-group inputWeight">
                                    <input className='textInput form-control' value={subjectWeight} type='number' min={0} max={100} name='subjectWeight' onKeyUp={(e) => { if (e.key === 'Enter') { askToEditItemSubject() } }} onChange={(e) => setSubjectWeight(parseInt(e.target.value))} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 offset-lg-1 col-sm-12">
                                <a className="ManagerBtnIcons ml-2" data-tip="Salva"
                                    onClick={() => askToEditItemSubject()}>
                                    <Check2Circle /></a>
                            </div>
                            {
                                props.sheetType !== 'yes-no' ?
                                    <div className="col-12">
                                        <label htmlFor='questionItemIsMandatory' className="ManagerFieldLabels mr-2">Risposta obbligatoria</label>
                                        <select value={questionItemIsMandatory} name='questionItemIsMandatory' onChange={(e) => { setQuestionItemIsMandatory(parseBool(e.target.value)) }}>
                                            <option value={true} defaultValue>SI</option>
                                            <option value={false}>NO</option>
                                        </select>
                                    </div>
                                    :
                                    null}

                        </div>
                    </Fragment>
                }


            </div>

            {
                subjects.find(e => e.id === props.subject.id).questions.map(_singleQuestion => {
                    return <SingleQuestionBuilder key={_singleQuestion.id} question={_singleQuestion} />
                })
            }
            {
                props.weightType !== 'pesoItem' &&
                <Fragment>
                    {
                        props.subject.subsections.map(_singleSubsection => {
                            if (!editingSubsectionName[_singleSubsection.id]) {
                                return <Fragment>
                                    <div key={`subsection_${_singleSubsection.id}`} className=' sheetSubjectAreaDelimiter p-3'>
                                        <div className="row align-items-center">
                                            <h6 className="col-12 col-md-10 text-center text-md-left">
                                                <b>{_singleSubsection.name.label}</b>
                                            </h6>

                                            <div className="col-12 col-md-2  text-center text-md-right mb-2 mb-md-0">
                                                <a className="mr-2" onClick={() => (setEditingSubsectionName(_singleSubsection.id))} data-tip="Modifica">
                                                    <Pencil className="ManagerBtnIcons" /></a>
                                                {
                                                    !editingSubsectionName[_singleSubsection.id] &&
                                                <a onClick={() => (askToDeleteSubsection(_singleSubsection.id))} data-tip="Elimina">
                                                        <XCircle className="ManagerBtnIcons text-danger" /></a>
                                                }
                                            </div>
                                        </div>
                                        {
                                            _singleSubsection.questions.map(_singleSubsectionQuestion => {
                                                return <SingleQuestionBuilder key={`subsectionQuestion_${_singleSubsectionQuestion.id}`} subsectionId={_singleSubsection.id} question={_singleSubsectionQuestion} />
                                            })
                                        }
                                        <div className='ManagerCreateQuestionContainer mt-2'>
                                            <h6 className="mb-3">Crea nuova domanda sottosezione</h6>
                                            <div className="mt-2">
                                            <label htmlFor='questionName' className="questionLabel">Nuova domanda</label>
                                            <input className='textInput cleanInput' value={subsectionQuestionName[_singleSubsection.id] || ''} type='text' name='questionName' onKeyUp={(e) => { if (e.key === 'Enter') { askToAddQuestionToSubsection(_singleSubsection.id) } }} onChange={(e) => setSubsectionQuestionName(e.target.value, _singleSubsection.id)} /><br></br>
                                            </div>
                                            {
                                                props.sheetType !== 'yes-no' &&
                                                <div className="mt-2">
                                                    <label htmlFor='questionIsMandatory' className="questionLabel">Risposta obbligatoria</label>
                                                    <select value={subsectionQuestionIsMandatory} name='questionIsMandatory' onChange={(e) => { setSubsectionQuestionIsMandatory(parseBool(e.target.value)) }}>
                                                    <option value={true} defaultValue>SI</option>
                                                    <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            }
                                            
                                            <div className="mt-3">
                                            <a className="mb-1 ManagerBtns" onClick={() => askToAddQuestionToSubsection(_singleSubsection.id)}>
                                                    <Check2Circle className="ManagerBtnIcons" /> Aggiungi domanda</a>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            } else {
                                return <Fragment>
                                    <div key={`subsection_${_singleSubsection.id}`} className="row mt-2 rowsheetSubjectAreaDelimiter p-2">
                                        <div className="col-12 col-md-9">
                                            <h6 className="ManagerFieldLabels">Nome sottosezione</h6>
                                            <CreatableSelect
                                                menuPlacement={'top'}
                                                options={premadeSubjects}
                                                closeMenuOnSelect={true}
                                                value={subsectionEditedName || _singleSubsection.name}
                                                onKeyUp={(e) => { if (e.key === 'Enter') { askToEditSubsection() } }}
                                                onChange={setSubsectionEditedName}
                                            />
                                        </div>
                                        <div className="col-12 col-md-3 d-flex flex-row align-items-end mb-1">
                                            <a className="mb-1" onClick={() => askToEditSubsection(_singleSubsection.id)}>
                                                <Check2Circle className="ManagerBtnIcons" /></a>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        })
                    }
                    {
                        subsectionCreation.includes(props.subject.id) && props.subject.questions.length<1 && 
                        <div className="row offset-1 mt-2 sheetSubjectAreaDelimiter ManagerSubSubSectionContainer p-2">
                            <div className="col-12 col-md-10">
                                <h6 className="ManagerFieldLabels">Nome nuova sottosezione</h6>
                                <CreatableSelect
                                    menuPlacement={'top'}
                                    options={premadeSubjects}
                                    closeMenuOnSelect={true}
                                    value={subsectionName}
                                    onKeyUp={(e) => { if (e.key === 'Enter') { askToAddSubsection() } }}
                                    onChange={setSubsectionName}
                                />
                            </div>
                            <div className="col-12 col-md-2 d-flex flex-row align-items-end">
                                <a className="mb-1 ManagerBtns" onClick={() => askToAddSubsection()}>
                                    <Check2Circle className="ManagerBtnIcons" /> Salva</a>
                            </div>
                        </div>
                    }
                    {
                        !props.isSubsection && !subsectionCreation.includes(props.subject.id) && props.subject.questions.length < 1 &&
                        <div className="mt-3 mb-3">
                            <a onClick={() => toggleSubsection()} className="ManagerBtns">
                                <ArrowReturnRight className="ManagerBtnIcons" /> Mostra crea sottosezione</a>
                        </div>
                    }
                    {
                        !props.isSubsection && subsectionCreation.includes(props.subject.id) && props.subject.questions.length < 1 && 
                        <div className="mt-3 mb-3">
                            <a onClick={() => toggleSubsection()} className="ManagerBtns">
                                <ArrowReturnLeft className="ManagerBtnIcons" /> Nascondi crea sottosezione</a>
                        </div>
                    }

                    {
                        (subsectionCounter < 1 || subsectionCounter !== props.isSubsection) && props.subject.subsections.length<1 &&
                        <div className="ManagerCreateQuestionContainer">
                            <h6>Crea nuova domanda</h6>
                            <div className="mt-2">
                                <label htmlFor='questionName' className="questionLabel">Nuova domanda</label>
                                <input className='textInput cleanInput' value={questionName} type='text' name='questionName' onKeyUp={(e) => { if (e.key === 'Enter') { askToAddQuestion() } }} onChange={(e) => setQuestionName(e.target.value)} /><br></br>
                            </div>
                            {
                                props.weightType === "pesoSezione" &&
                                <div className="mt-2">
                                    <label htmlFor='questionIsMandatory' className="questionLabel">Risp. obbligatoria</label>
                                    <select value={questionIsMandatory} name='questionIsMandatory' onChange={(e) => { setQuestionIsMandatory(parseBool(e.target.value)) }}>
                                        <option value={true} defaultValue>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>
                            }
                            {
                                props.weightType === "pesoItem" && props.sheetType !== 'yes-no' &&
                                <div className="mt-2">
                                    <label htmlFor='questionItemIsMandatory' className="questionLabel">Risp. obbligatoria</label>
                                    <select value={questionItemIsMandatory} name='questionItemIsMandatory' onChange={(e) => { setQuestionItemIsMandatory(parseBool(e.target.value)) }}>
                                        <option value={true} defaultValue>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>

                            }
                            
                            <div className="mt-3">
                                <a className="mb-1 ManagerBtns" onClick={() => askToAddQuestion()}>
                                    <Check2Circle className="ManagerBtnIcons" /> Aggiungi domanda</a>
                            </div>
                        </div>
                    }
                    
                </Fragment>

            }

               
        </div>
    )
}
