import React from 'react';
const SheetContext = React.createContext({
    IdSheet: null,
    sheetSubjectsAvgWeight: 0,
    sheetMaxRate: 0,
    sheetTotalRatePercent: 0,
    sheetTotalRate: 0,
    sheetPlayerRange: null,
    sheetPlayerRangeDescription: "",
    sheetSubjectsVotesAvg: null,
    consulentiPresenti: null,
    isLoadingConsulentiPresentiList: false,
    sheetPlayerRangeColor: { backgroundColor:null},
    sheetSubjectsAndQuestions: []
});
export default SheetContext;