import React, { useEffect, useState, useContext, useRef } from 'react';
import HomeContext from './HomeContext';
import moment from 'moment';
import { ArrowDownCircle, ArrowUpCircle, ArrowUpCircleFill, ArrowDownCircleFill, ArrowLeftCircle, ArrowRightCircle } from 'react-bootstrap-icons';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { getRangeColor } from '../Tools';
import { valutaAPIpost } from '../FetchApi';
import AsyncImage from './AsyncImage';
import DEFAULTPIC from '../img/pic.png';

const EvaluationsList = (props) => {
    const evaluationsListRef = useRef(null)
    const resultPerPage = process.env.REACT_APP_DEFAULT_DATATOSEARCH_LIMIT;
    const [homeState, setHomeState] = useContext(HomeContext);
    const [showLoadingList, setShowLoadingList] = useState(false);
    const doAsyncSearch = async (_limit, _offset, _orderBy = null, _orderDirection = null) => {
        const dataToSearch = JSON.parse(sessionStorage.getItem('dataToSearch'));
        dataToSearch.Limit = _limit;
        dataToSearch.Offset = _offset;
        dataToSearch.OrderBy = _orderBy ?? homeState.evaluationsListOrderBy;
        dataToSearch.OrderDirection = _orderDirection ?? homeState.evaluationsListOrderDirection;
        sessionStorage.setItem('dataToSearch', JSON.stringify(dataToSearch));
        //console.log(dataToSearch);
        return valutaAPIpost(process.env.REACT_APP_VALUTA_API_GET_EVALUATIONS_LIST, dataToSearch).then(evaluationsList => {
            return evaluationsList;
        })
            .catch(e => { console.log(e); }
            );
    }
    const orderDataBy = async (customParameter, order = 'asc') => {
        await doAsyncSearch(resultPerPage, 0, customParameter, order).then(newResults => {
            var evaluationsListTotalPages = Math.ceil(newResults.resultsCount / resultPerPage);
            setHomeState({
                ...homeState,
                evaluationsList: newResults.evaluations,
                evaluationsListTotalCount: newResults.resultsCount,
                evaluationsListTotalPages,
                evaluationsListCurrentPage: 1,
                evaluationsListTableStart: 0,
                evaluationsListTableEnd: resultPerPage,
                evaluationsListOrderBy: customParameter,
                evaluationsListOrderDirection: order
            })

        })
            .catch(e => { console.log(e); }
            );
    }
    const nextPage = async () => {
        
        if (!showLoadingList && homeState.evaluationsListCurrentPage < homeState.evaluationsListTotalCount) {
            var curPage = homeState.evaluationsListCurrentPage + 1;
            var offSet = resultPerPage * homeState.evaluationsListCurrentPage;
            var to = parseInt(offSet, 10) + parseInt(resultPerPage, 10);
            var tableEnd = (to > homeState.evaluationsListTotalCount ? homeState.evaluationsListTotalCount : to);
            //RISULTATI GIA' CARICATI (SE FAI INDIETRO E POI DI NUOVO AVANTI NON DEVE RICARICARLI)
            if (homeState.evaluationsList.slice(offSet, tableEnd).length > 0) {
                setHomeState({
                    ...homeState,
                    evaluationsListCurrentPage: curPage,
                    evaluationsListTableStart: offSet,
                    evaluationsListTableEnd: tableEnd
                });
            }
            //NUOVI RISULTATI DA CARICARE
            else {
                setShowLoadingList(true);
                const newResults = await doAsyncSearch(resultPerPage, offSet);
                setHomeState({
                    ...homeState, evaluationsList: homeState.evaluationsList.concat(newResults.evaluations),
                    evaluationsListCurrentPage: curPage,
                    evaluationsListTableStart: offSet,
                    evaluationsListTableEnd: tableEnd
                });
                setShowLoadingList(false);
            }
        }
    }
    const previousPage = async () => {
        if (homeState.evaluationsListCurrentPage > 1) {
            var offSet = resultPerPage * (homeState.evaluationsListCurrentPage - 1);
            var curPage = homeState.evaluationsListCurrentPage - 1;
            var tableStart = parseInt(offSet, 10) - parseInt(resultPerPage, 10);
            var tableEnd = parseInt(offSet, 10);
            setHomeState({
                ...homeState,
                evaluationsListCurrentPage: curPage,
                evaluationsListTableStart: tableStart,
                evaluationsListTableEnd: tableEnd
            })
        }
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = DEFAULTPIC;
    }

    useEffect(() => {
        setHomeState({
            ...homeState,
            scrollTo: evaluationsListRef,
            evaluationsListOrderBy: process.env.REACT_APP_DEFAULT_DATATOSEARCH_ORDERBY,
            evaluationsListOrderDirection: process.env.REACT_APP_DEFAULT_DATATOSEARCH_ORDERDIRECTION
        })
    }, []);
    return (
        <React.Fragment>
            <div ref={evaluationsListRef}>
                <h5>Elenco Valutazioni</h5>
            </div>
            {(homeState.evaluationsList && homeState.evaluationsList.length < 1) ?
                <div className="mb-2">Nessuna valutazione.</div>
                :
                <div className="container-fluid">
                    <div className="row mb-1 tableMin">
                        <div className="col-2 d-flex flex-row  justify-content-between align-items-center evaluation-list-table-header">
                            <div className="d-flex flex-column justify-content-center align-items-center customTableHeader-small tableHeader">
                                {(homeState.evaluationsListOrderBy === 'sheetTotalRatePercent' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('sheetTotalRatePercent', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow" onClick={() => { orderDataBy('sheetTotalRatePercent', 'asc') }} />
                                }
                                <div>Voto</div>
                                {(homeState.evaluationsListOrderBy === 'sheetTotalRatePercent' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('sheetTotalRatePercent', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('sheetTotalRatePercent', 'desc') }} />
                                }
                            </div>
                            <div className="customTableHeader tableHeader">Valutazione</div>
                            <div className="d-flex flex-column justify-content-center align-items-center customTableHeader-small tableHeader">
                                {(homeState.evaluationsListOrderBy === 'listenDate' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('listenDate', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow" onClick={() => { orderDataBy('listenDate', 'asc') }} />
                                }
                                <div>Data</div>
                                {(homeState.evaluationsListOrderBy === 'listenDate' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('listenDate', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('listenDate', 'desc') }} />
                                }
                            </div>
                        </div>

                        <div className="col-2 d-flex flex-row  justify-content-between align-items-center evaluation-list-table-header">
                            <div className="d-flex flex-column justify-content-center align-items-center customTableHeader-small tableHeader">
                                {(homeState.evaluationsListOrderBy === 'assistantName' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('assistantName', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow" onClick={() => { orderDataBy('assistantName', 'asc') }} />
                                }
                                <div>Nome</div>
                                {(homeState.evaluationsListOrderBy === 'assistantName' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('assistantName', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('assistantName', 'desc') }} />
                                }
                            </div>
                            <div className="customTableHeader tableHeader">Consulenza</div>
                            <div className="d-flex flex-column justify-content-center align-items-center customTableHeader-small tableHeader">
                                {(homeState.evaluationsListOrderBy === 'evaluationDate' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('evaluationDate', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow" onClick={() => { orderDataBy('evaluationDate', 'asc') }} />
                                }
                                <div>Data</div>
                                {(homeState.evaluationsListOrderBy === 'evaluationDate' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('evaluationDate', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('evaluationDate', 'desc') }} />
                                }
                            </div>
                        </div>

                        <div className="col-2 d-none d-sm-flex flex-row  justify-content-between align-items-center evaluation-list-table-header customTableHeader tableHeader col-1-extended">
                            <div>Business</div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {(homeState.evaluationsListOrderBy === 'lb' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow mb-3" onClick={() => { orderDataBy('lb', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow mb-3" onClick={() => { orderDataBy('lb', 'asc') }} />
                                }
                                {(homeState.evaluationsListOrderBy === 'lb' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('lb', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('lb', 'desc') }} />
                                }
                            </div>
                        </div>

                        <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-table-header customTableHeader tableHeader col-1-extended">
                            <div>Quality</div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {(homeState.evaluationsListOrderBy === 'qualityName' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow mb-3" onClick={() => { orderDataBy('qualityName', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow mb-3" onClick={() => { orderDataBy('qualityName', 'asc') }} />
                                }
                                {(homeState.evaluationsListOrderBy === 'qualityName' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('qualityName', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('qualityName', 'desc') }} />
                                }
                            </div>
                        </div>

                        <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-table-header customTableHeader tableHeader col-1-extended">
                            <div>Valutatore</div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {(homeState.evaluationsListOrderBy === 'evaluatorName' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow mb-3" onClick={() => { orderDataBy('evaluatorName', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow mb-3" onClick={() => { orderDataBy('evaluatorName', 'asc') }} />
                                }
                                {(homeState.evaluationsListOrderBy === 'evaluatorName' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('evaluatorName', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('evaluatorName', 'desc') }} />
                                }
                            </div>
                        </div>

                        <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-table-header customTableHeader tableHeader col-1-extended">
                            <div>Account</div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {(homeState.evaluationsListOrderBy === 'accountName' && homeState.evaluationsListOrderDirection === 'asc') ?
                                    <ArrowUpCircleFill className=" ordering-arrow mb-3" onClick={() => { orderDataBy('accountName', 'asc') }} />
                                    :
                                    <ArrowUpCircle className=" ordering-arrow mb-3" onClick={() => { orderDataBy('accountName', 'asc') }} />
                                }
                                {(homeState.evaluationsListOrderBy === 'accountName' && homeState.evaluationsListOrderDirection === 'desc') ?
                                    <ArrowDownCircleFill className=" ordering-arrow" onClick={() => { orderDataBy('accountName', 'desc') }} />
                                    :
                                    <ArrowDownCircle className=" ordering-arrow" onClick={() => { orderDataBy('accountName', 'desc') }} />
                                }
                            </div>
                        </div>

                    </div>
                    {(showLoadingList) ?
                        <div className="loadingListOverlay">
                            <div className="loadingListOverlayMessage">Carico lista valutazioni...</div>
                            <div className="loadingListOverlaySpinner spinner-border" role="status">
                                <span className="sr-only">Carico lista valutazioni...</span>
                            </div>
                        </div>
                        :
                        null
                    }
                    {(homeState.evaluationsList.slice((homeState.evaluationsListTableStart ?? 0), (homeState.evaluationsListTableEnd ?? resultPerPage)).map((row) => {
                        var SheetPlayerRangeColor = getRangeColor(row.idSheet, row.sheetPlayerRange);
                        return (
                            <div className="row mb-1 tableMin clickableTableRow" onClick={() => { window.open(`/evaluation/view/${row.idEvaluation}`, '_blank').focus() }}>

                                <div className="col-2 d-flex flex-row  justify-content-between align-items-center evaluation-list-cell">
                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                        <div className={"PlayerRangeCircleVeryLittle PlayerRangeColor-" + row.sheetPlayerRange} style={{ backgroundColor: SheetPlayerRangeColor }}>{row.sheetPlayerRange || '?'}</div>
                                        <div className="ml-2 pt-1">{row.sheetTotalRatePercent}%</div>
                                    </div>
                                    <div>{moment(row.listenDate).format("DD/MM/YY")}</div>
                                </div>

                                <div className="col-2 d-flex flex-row  justify-content-between align-items-center evaluation-list-cell">
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <AsyncImage data={row.assistantId} className='evaluation-user-pic-little responsiveHide' alt='' />
                                        <span className="fullNameText">{row.assistantName}</span>
                                    </div>
                                    <div>{moment(row.evaluationDate).format("DD/MM/YY")}</div>
                                </div>

                                <div className="col-2 d-none d-sm-flex flex-row  flex-row  justify-content-between align-items-center evaluation-list-cell">
                                    <div>{row.lb}</div>
                                </div>

                                <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-cell col-1-extended">
                                    {(row.qualityId !== null && row.qualityId !== "" && row.qualityId !== undefined) ?
                                        <React.Fragment>
                                            <div>
                                                <AsyncImage data={row.qualityId} className='evaluation-user-pic-little responsiveHide' alt='' />
                                                <span className="fullNameText">{row.qualityName}</span>
                                            </div>
                                            <div></div>
                                        </React.Fragment>
                                        : null}
                                </div>

                                <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-cell col-1-extended">
                                    {(row.evaluatorId !== null && row.evaluatorId !== "" && row.evaluatorId !== undefined) ?
                                        <React.Fragment>
                                            <div>
                                                <AsyncImage data={row.evaluatorId} className='evaluation-user-pic-little responsiveHide' alt='' />
                                                <span className="fullNameText">{row.evaluatorName}</span>
                                            </div>
                                            <div></div>
                                        </React.Fragment>
                                        : null}
                                </div>

                                <div className="col-1 d-none d-md-flex flex-row  justify-content-between align-items-center evaluation-list-cell col-1-extended">
                                    <div>
                                        <AsyncImage data={row.accountId} className='evaluation-user-pic-little responsiveHide' alt='' />
                                        <span className="fullNameText">{row.accountName}</span>
                                    </div>
                                    <div></div>
                                </div>

                            </div>
                        )
                    }
                    ))}
                    <div className="col-12 d-flex flex-row  justify-content-between align-items-center tableFooter mb-2">
                        <div></div>
                        {(homeState.evaluationsListCurrentPage > 1) ?
                            <div className="customTableHeader" onClick={() => { previousPage() }}><ArrowLeftCircle /> Precedenti</div>
                            :
                            <div className="customTableHeaderGray"><ArrowLeftCircle /> Precedenti</div>
                        }

                        <div className="customTableHeader d-flex flex-row">
                            <div>Pagina {homeState.evaluationsListCurrentPage}/{homeState.evaluationsListTotalPages} - </div>
                            <div className="ml-2">Risultati {Math.min((homeState.evaluationsListTableStart ?? 0) + 1, homeState.evaluationsListTotalCount)}-{Math.min(((homeState.evaluationsListTableEnd ?? resultPerPage)), homeState.evaluationsListTotalCount)} di {homeState.evaluationsListTotalCount}</div>
                        </div>

                        {(homeState.evaluationsListCurrentPage < homeState.evaluationsListTotalPages) ?
                            <div className="customTableHeader" onClick={() => { nextPage() }}>
                                {(showLoadingList)
                                    ?
                                    <div className="spinner-border" role="status" style={{ width: '20px', height:'20px'}}>
                                        <span className="sr-only">Attendi...</span>
                                    </div>
                                    :
                                    <span>Prossimi <ArrowRightCircle /></span>
                                } </div>
                            :
                            <div className="customTableHeaderGray">Prossimi <ArrowRightCircle /></div>

                        }
                        <div></div>
                    </div>
                </div>
            }
        </React.Fragment>
    )

}
export default EvaluationsList;